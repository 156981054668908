<div class="modal-header bg-light">
    <b>Fatturazione elettronica</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="row mb-3" *ngIf="message.length>0">
        <div class="col-12">
            <i class="float-left h-100 pr-2 text-warning fas fa-exclamation-triangle fa-2x"></i>
            <div class="float-left">
                <small>
                    <b>ATTENZIONE:</b><br>
                    Il Sistema ha evidenziati i seguenti errori:
                    <div *ngFor="let m of message"><span class="text-red">&#10008;</span> {{m.message.substring(36)}}</div>
                </small>
            </div>
        </div>
    </div>
    <iframe id="frame" [frameBorder]="0" width="100%" height="500" [src]="sanitizer.bypassSecurityTrustResourceUrl(url)"></iframe>
</div>
<div class="modal-footer">
    <a href="{{url_xml}}" target="_blank" [class.btn-warning]="message.length>0" class="btn btn-success" (click)="close()">
        <i class="fas fa-file-download mr-2"></i>Scarica file XML
        <span *ngIf="message.length>0"> comunque</span></a>
        <button type="button"  class="btn btn-primary" (click)="downloadPreview()" ><i class="fas fa-print mr-2"></i>Anteprima di stampa</button>
    <button type="button" (click)="close()" class="btn btn-secondary">Chiudi</button>
</div>
