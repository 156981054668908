import { Type,Input,Output,Component,OnInit, EventEmitter,ViewChild,ViewChildren ,QueryList,ElementRef} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { WSService } from './service'
import { Filter, FilterAdvanced, FilterMode,Ordering, OrderingMode,CustomField,Template,ToastMode, User } from './structures'
import { TemplatesService } from '../lib/templates.service';
import { Extension } from './parameters';
import { Modal } from './modal';
import { SelecttemplateComponent } from '../common/selecttemplate/selecttemplate.component'
import { Model } from './model';
import { EditTableHeaderComponent } from '../common/edit-table-header/edit-table-header.component';
import { Helper } from '../helper';
import { Globals } from '../globals';

import * as math from 'mathjs';
import { FormsValueService } from 'projects/c1-backend/src/app/services/formsvalue.service';
import { TemplateSearchComponent } from '../common/template-search/template-search.component';
import { ActionsService } from '../lib/actions.service';
// import { isReady } from 'jquery';



export class TableColumn{
  label:string;
  field:string;

  constructor(label,field){
    this.label=label;
    this.field=field;
  }

  public getValue(record){
    return Helper.getFieldbyString(this.field,record);
  }
}

export const OpenMode={
  MODAL:1,
  SPLITVIEW:2

}

export interface Page{
  label:string;
  class:string;
  page:number;
  mode:number;
}

@Component({
  template:''
})


export class ModelList<T> extends Modal  {

  
  @ViewChildren("checkrecord") checkboxes: QueryList<ElementRef>;
  @ViewChildren("checkboxselectall") checkboxSelectAll: QueryList<ElementRef>;
  @ViewChild('detail') detailView:Model<T>;
  @ViewChild('table_search') table_search: ElementRef;


  @Input()
  canEdit:boolean=true;

  tableColumns:TableColumn[]=[];
  modulename:string=""; //name of current module
  module:Extension=null; //parameters of window
  _title:string="";
  openModeDetail:number=OpenMode.MODAL;
  detailViewModal:Type<any>;

  messageStatus="Nessun elemento soddisfa i parametri di ricerca impostati!"

  sectionname:string=""; //Nome della sezione (indica la tab corrente)

  get title():string{
    return this._title;
    //return Globals.title;
  }
  set title(value){
    
    if(this.mode!="embedded" && this.mode!="embedded2" && this.mode!="modal"){
      setTimeout(()=>{
        Globals.navigation.addHistory(value);
      },500);
      
    }
    
    this._title=value;
    

   
  };


  get user():User{
    return Globals.user;
  }

  constructor(
    private recordService: WSService<T>,
    private fb: UntypedFormBuilder={} as UntypedFormBuilder,
    
  ) { super()

    this.form =this.fb.group({
        id: this.fb.array([])
      });
      this.ordering.field="";
      this.advancedFilter=[];
      this.groupby="";
      this.ordering.mode=OrderingMode.ascendent;

      this.orderingList.push(this.ordering);
      

      
  }


  instance:string="";
   today = new Date();
  
   
    form: UntypedFormGroup;

    service:WSService<T>;
    list:T[] = [];
    filter:Filter<any>[]=[];
    advancedFilter:FilterAdvanced[]=[];
    orderingList:Ordering[]=[];
    ordering:Ordering={} as Ordering;
    groupby:string="";
    aux_parameter:string="";
    other_params=[];
    paging_start:number=0;
    @Input()
    paging_count:number=Globals.parameters.get("general")?Globals.parameters.get("general").getParam("paging_count",12):12;
    recordcount:number=0;
    pages:Page[]=[]
    page:Page={} as Page;

    checkedAll:boolean=false;
    modalsOpened=[];
    /* MODALITA SPLIT */
    @Input()
    selectedRecord:T;

    

    @Input()
    modalWindow:any; // finestra corrente in modalità modal
    isEditing=false;


    public beforeGetItems:Function;
    public afterGetItems:Function;

    filter_status:Filter=new Filter();

    
    
    ngOnInit(): void {

      if(this.modulename!="")
        this.module=Globals.parameters.get(this.modulename);

      if(this.module!=null){
        //prendi i parametri dal modulo
        this.tableColumns=this.module.getStored("tableColumns",[]);
      }

      
      

    }

    ngAfterViewInit(){
      let nameComponent=this.constructor.name;
      if(this.titleModal=="")
        this.titleModal=this.title;
      
      if(Globals.modal.currentModal==nameComponent){
        this.mode="modal";
        Globals.modal.currentModal="";
      }

      if(this.mode!="embedded" && this.mode!="embedded2" && this.mode!="modal"){
        Globals.currentModel=this;
      }
    }

    onChange(id: number, isChecked: boolean) {
        const items = (this.form.controls.id as UntypedFormArray);

        const index = items.controls.findIndex(x => x.value === id);

        if (isChecked) {
          if(index==-1)
            items.push(new UntypedFormControl(id));
        } else {
          
          items.removeAt(index);
        }
      }

      onChangeAll(isChecked: boolean) {
        this.checkboxes.forEach((element)=>{
          this.onChange(element.nativeElement.id_record,isChecked);
          element.nativeElement.checked=isChecked;
        });
   
      }

      onDeselectAll(){
        if(this.checkboxes!=null)
          this.checkboxes.forEach((element)=>{
            if(element.nativeElement!=null)
              element.nativeElement.checked=false;
          });

        const items = (this.form.controls.id as UntypedFormArray);
        items.clear();

        

        if(this.checkboxSelectAll!=null)
          if(this.checkboxSelectAll.length>0){
            this.checkboxSelectAll.forEach((element)=>{
              if(element.nativeElement!=null)
                element.nativeElement.checked=false;
            });
          }
      }

      onSelect(id_component) {
        const selected = id_component;
      }




      public getItems(oncomplete: ((i: T[]) => any)=null,attribToList:boolean=true,storeIds:boolean=false,savingOffline:boolean=false,startPage=0):void{

        this.messageStatus="Ricerca in corso. Attendere ...";
        
        if(this.beforeGetItems)
          this.beforeGetItems();

        Globals.setLoading(true);

        //disabilita la selezione di tutti gli elementi
        this.onDeselectAll();

         //posiziona sulla pagina iniziale richiesta
        this.paging_start=startPage;      

        Globals.navigation.addStateFilter(this.filter,this.constructor.name+this.instance,this.advancedFilter);
        this.recordService.getItems(this.filter,this.orderingList,this.paging_start,this.paging_count,this.aux_parameter,this.advancedFilter,this.groupby,this.other_params).subscribe((items)=>{
          Globals.setLoading(false);
          if(attribToList){
            if(items.value==null)
              this.list=[];
            else{              
              this.list=items.value;            
            }
            if(savingOffline)
              localStorage.setItem(this.recordService.table+"Items",JSON.stringify(this.list));            
          }
          if(storeIds)
            Globals.lastSearchIDS=items.ids;
          
          if(this.list==null || this.list.length==0)
            this.messageStatus="Nessun elemento soddisfa i parametri di ricerca impostati!";

          this.calculatePaging(items);
          
          if(oncomplete!=null)
             oncomplete(items.value);
          
          if(this.afterGetItems)
            this.afterGetItems();

            if(this.table_search){
              setTimeout(()=>{ // this will make the execution after the above boolean has changed
                this.table_search.nativeElement.focus();
                this.table_search.nativeElement.select();
              },0);  
            }

        });
      }

      calculatePaging(items:any){
          //this.list=items.value;
          this.paging_count=items.paging_count;
          this.paging_start=items.paging_start;
          this.recordcount=items.count;
          this.page.page=Math.round(this.paging_start/this.paging_count)+1;
          //calcola il paging
          let totalpages=10;

          //trova la decina da cui iniziare
          let start=Math.floor(this.page.page/10)*10 ;
          let last=0;
          this.pages=[];
          if(start>0){
            let p:Page={} as Page;
            p.mode=0;
            p.page=1;
            p.label="<<";
            this.pages.push(p);
            
            p={} as Page;
            p.mode=1;
            p.page=-totalpages;
            p.label="<";
            this.pages.push(p);

            
            

          }


          for (var i=start, j=0;i<Math.ceil(this.recordcount/this.paging_count) && j<totalpages ;i++,j++){
            let p:Page={} as Page;
            p.page=i+1;
            p.mode=0;
            p.label=(i+1).toString();
            if (p.page == this.page.page){
              p.class='active';
            } else {
              p.class='';
            }
             

            this.pages.push(p);
            last=p.page;
          }
          //se la paginazione continua, metti il pulsante per scorrere di altri 10 
          if(Math.ceil(this.recordcount/this.paging_count)>last){
            let p:Page={} as Page;
            p.mode=1;
            p.page=totalpages;
            p.label=">";
            this.pages.push(p);

            p={} as Page;
            p.mode=0;
            p.page=Math.ceil(this.recordcount/this.paging_count);
            p.label=">>";
            this.pages.push(p);
          }
      }

      getOfflineItems(){
        this.list= this.recordService.getOfflineItems();
      }

      goToPage(page:Page):void{
        if(page.mode==1)
          page.page=this.page.page+page.page;
          
        this.paging_start=(page.page-1)*this.paging_count;
        this.getItems(null,true,false,false,this.paging_start);
      }

      verifyDate(d):boolean{
        if(Date.parse(d)>this.today.getTime())
          return true;
        else
        return false;
      }

      isToday(d):boolean{
        let dc:Date=new Date(Date.parse(d));
        if(dc.getDate()==this.today.getDate() && dc.getMonth()==this.today.getMonth() && dc.getFullYear()==this.today.getFullYear())
          return true;
        else
        return false;
      }

      isTomorrow(d):boolean{
        let dc:Date=new Date(Date.parse(d));
        if(dc.getDate()==(this.today.getDate() + 1) && dc.getMonth()==this.today.getMonth() && dc.getFullYear()==this.today.getFullYear())
          return true;
        else
        return false;
      }

      addRecord():void{
        let r:T={} as T;
        r['id']=0;
        this.list.push(r);
      }

      NewRecord():void{
        let r:T={} as T;
        r['id']=0;
        this.openDetail(r);

      }

      
      openDetail(record:T=null,aux={},oncomplete=null,size='xl'){
        
        if(!this.canEdit)
          return;
        if(record==null){
          record={} as T;
          record['id']=0;
        }
        this.selectedRecord=record;
        this.detailViewModal['record']=record;
        if(record['id'])
          this.detailViewModal['id']=record['id'];
        //esegue un'azione (se presente)

        let actionsService:ActionsService=new ActionsService();
        actionsService.getRule(this.recordService.table,record,(actions_response)=>{
          
       


          //Apre la scheda secondo la modalità selezionata
          if(this.openModeDetail==OpenMode.MODAL){

            let params=[];
            params=[{"name":"mode","value":"modal"},{"name":"id","value":record['id']},{"name":"actions_response","value":actions_response}]

            if(aux){
              if(Array.isArray(aux)){
                params=params.concat(aux);
              }else{
                params.push(aux);
              }
            }

            //verifica che la modal non sia già aperta
            if(this.modalsOpened.find(x=>x==this.detailViewModal.name)){
              Globals.message.showToaster("Non posso aprire la finestra in quanto già aperta",ToastMode.WARNING);
              return;
            }
            this.modalsOpened.push(this.detailViewModal.name);
            Globals.modal.showModal(this.detailViewModal,params,(instance)=>{
              //elimina la modale dall'elenco di quelle già aperte
              for(let i=0;i<this.modalsOpened.length;i++){
                if(this.modalsOpened[i]==this.detailViewModal.name){
                  this.modalsOpened.splice(i,1);
                  continue;
                }
              }
              if(instance!=null){
                this.selectRecord(instance['record']);
                
              }
              this.getItems(null,true,false,false,this.paging_start);
                
              if(oncomplete)
                oncomplete(instance);
                

            },size);
          }
          if(this.openModeDetail==OpenMode.SPLITVIEW){
            if(this.detailView!=null){
              this.detailView.loadRecord(record['id']);
              
              if(record['id']==0) //nel caso di un nuovo record
                this.enableEditing();
            }
          }

        },"EDIT");

      }

      save(record:T=null):void{

        if(record==null){
          for(let r of this.list){
            this.recordService.save(r,(id)=>{});
          }
        }else{
          this.recordService.save(record,(id)=>{this.getItems(null,true,false,false,this.paging_start);});
        }
      }

      ///Sposta nel cestino
      trash(id=[],request_confirm=false):void{


          if(id.length==0){

          
            if(this.form.value.id.length==0){           
              Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);           
              return;
            }

            id=this.form.value.id;
          }
          var item={} as Object;
          item['status']=2;

          if(request_confirm){
            Globals.modal.showConfirm("","Confermi di voler eliminare gli elementi selezionati?",()=>{
              this.recordService.trash(item,id,(complete)=>{
                this.getItems();    
              });     
            },"Sì. Elimina","No. Annulla","danger");

          }else{
  
            this.recordService.trash(item,id,(complete)=>{
              this.getItems();  
            });
          }

      }

      //sposta negli archiviati
      archive():void{

        if(this.form.value.id.length==0){
          Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
          return;
        }

        var item={} as Object;
        item['status']=3;

        this.recordService.updateAny(item,this.form.value.id,(complete)=>{
          this.getItems();

        });
        
    }
      

      ///elimina il record
      delete(id:number=0,request_confirm=false):void{

        if(Globals.user.role!="admin" && Globals.user.role!="superuser"){
          Globals.message.showToaster("Non hai i permessi per poter cancellare l'elemento selezionato",ToastMode.WARNING);
          return;
        }
        let id_to_delete:string[];
        if(id>0){
          id_to_delete=[id.toString()];
        }else{
          if(this.form.value.id.length==0){
            Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
            return;
          }
          id_to_delete=this.form.value.id;
        }

        if(request_confirm){
          Globals.modal.showConfirm("","Confermi di voler eliminare definitivamente gli elementi selezionati?",()=>{
            this.recordService.delete(id_to_delete).subscribe((item)=>{
              this.getItems();  
            });     
          },"Sì. Elimina","No. Annulla","danger");

        }else{

          this.recordService.delete(id_to_delete).subscribe((item)=>{
            this.getItems();
          });
        }
      }

      duplicate(item:any){
        item['id']=0;
        this.save(item);
      }

      //ripristina il record cancellato
      undo(id:number=0):void{
        if(this.form.value.id.length==0){
          Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
          return;
        }

        var item={} as Object;
        item['status']=1;

        this.recordService.restore(item,this.form.value.id,(complete)=>{
          this.getItems();

        });
      }



      switchOrdering(field:string):void{

        if(this.ordering.field==field){
          if(this.ordering.mode==OrderingMode.ascendent)
            this.ordering.mode=OrderingMode.discendent;
          else
            this.ordering.mode=OrderingMode.ascendent;
        
        }else{
          this.ordering.field=field;
          this.ordering.mode==OrderingMode.ascendent
        }

          
        this.getItems();
      }

      classOrdering(field:string):string{
        if(this.ordering.field==field){
          if(this.ordering.mode==OrderingMode.ascendent)
            return "fa-sort-up";
          else
            return "fa-sort-down";
          

        }
         return "";
      }

      //esporta lista CSV
      export():void{

        let where=this.recordService.lastSQLWhere;
        //definisci il numero di record selezionati
        let recordSelected = (this.form.controls.id as UntypedFormArray).length;
        if(recordSelected==0)
          recordSelected=this.recordcount;
        else{
          
          where=(where!=""?where+" AND ":"")+"idkey IN ("+(this.form.value.id.join(","))+")";
        }
        Globals.modal.showModal(SelecttemplateComponent,[
          {"name":"table","value":this.recordService.table},
          {"name":"section","value":this.sectionname},
          {"name":"detail","value":false},
          {"name":"arguments","value":[where,this.recordService.lastSQLOrder]}
        ],(instance)=>{

          let template=instance['selectedTemplate'] as Template;

          if(template==null){
            return;
          }

            if(recordSelected>1000){
              if(!confirm("Il numero di elementi selezionati è superiore a 1000, l'elaborazione potrebbe richiedere molto tempo. Continuare con l'operazione?"))
                return;
            }
          
          
            
            let templatesService:TemplatesService=new TemplatesService();

            if(template.id<1){
              return;
            }



            let m={};

            

            //verifica che non ci siano elementi selezionati
            const items = (this.form.controls.id as UntypedFormArray);
            
            if(template.detail){
              m['where']=this.form.value.id.join(",");
              let origins=JSON.parse(template.origins)[0];
              let args=origins.args.split("|");
              for(let a of args){
                let arg=a.replace("{{","").replace("}}","");
                for(let p of Object.getOwnPropertyNames(this)){
                  if(p==arg){
                    if(Array.isArray(this[p])){
                      m[arg]=this[p].join(",");
                    }else{
                      m[arg]=this[p];
                    }
                    
                  }
                }
              }

              //aggiungi i parametri richiesti
              if(template.requestparamsArray){
                for(let r of template.requestparamsArray){
                  m[r.name]=r.value;
                }
              }

              
            }else{
              if(this.form.value.id.length>0)
                m['where']=where;
              else{
                m['where']=this.recordService.lastSQLWhere;
              }
            }
            
            //m['where']=where;
            m['order']=this.recordService.lastSQLOrder;
            let a:Filter;
            for(let p of Object.getOwnPropertyNames(this)){
              if(p.includes("filter_"))
              
              //if(typeof(this[p])=="string" || typeof(this[p])=="number" || typeof(this[p])=="boolean" || p.includes("filter_"))
                m[p]=this[p];
            }

            Globals.setLoadingStatusBar(true,"Creazione esportazione '"+template.name+"' in corso ...");
          
            templatesService.openTemplate(template.id,JSON.stringify(m),"true",template.requestparams).subscribe((url)=>{
              Globals.setLoadingStatusBar(false);
              window.open(url,"_blank");
            });
         },"md");




      }


      exportInRow(record):void{
        //trova i template disponibili
        let templatesService:TemplatesService=new TemplatesService();
        templatesService.getTemplates(this.recordService.table,true,true).subscribe((templates)=>{
          let results=[];
          //verifica se i templates rispettano i  vincoli
          for(let t of templates){
            if(t.condition!=""){
              let c=Helper.replaceKeyword(t.condition,record);
              if(math.evaluate(c)){
                results.push(t);
              }
            }else{
              results.push(t);
            }
            
          }
          
          if(results.length>0){
            let m={};
            m['where']=record.id;
            if(results.length>1){
              Globals.modal.showModal(SelecttemplateComponent,[{"name":"table","value":this.recordService.table},{"name":"templates","value":results}],(instance)=>{
                let template=instance['selectedTemplate'] as Template;
                Globals.setLoading(true);
                templatesService.openTemplate(template.id,JSON.stringify(m)).subscribe((url)=>{
                  Globals.setLoading(false);
                  window.open(url,"_blank");
                });
              },"md");
            }else{
              
              Globals.setLoading(true);
              templatesService.openTemplate(results[0].id,JSON.stringify(m)).subscribe((url)=>{
                Globals.setLoading(false);
                window.open(url,"_blank");
              });
            }
          }else{
            Globals.message.showToaster("Nessun modello di stampa è associato al record selezionato",ToastMode.DANGER);
          }


        });


      }
      /*
       ///Apre un componente nella modalità modale
      openModalFromList(comp:string,title:string,id_key:string,id_record:number,field_key:string=""):void{
      
      //prendi il componente
      let component:Modal=AppComponent.app.getComponentByName(comp);
      AppComponent.app.titleModal=title;
      AppComponent.app.selectedRecordFromModal={} as Function;
      AppComponent.app.selectedRecordFromModal=((r)=>{

        let record=this.list.filter(rr=>rr["id"]==id_record)?this.list.filter(rr=>rr["id"]==id_record)[0]:null;
        if(record!=null){
          record[id_key]=r.id;
          if(field_key!="")
            record[field_key]=r;
        } 
      });

      AppComponent.app.openModal(component);
      


    }*/

    onBack():void{
        Globals.navigation.onBackLink(null);
      
    }

    isArray(variable){
      if(Array.isArray(variable))
        return true;
      
        return false;
    }

    cancelClick:boolean=false;

    enableEditing(){
        if(!this.cancelClick)
          this.isEditing=true;
        else
          this.cancelClick=false;

    }

    disableEditing(){
      this.cancelClick=true;
      this.isEditing=false;
    }


    public editTableHeader(){


      Globals.modal.showModal(EditTableHeaderComponent,[{"name":"table","value":this.recordService.table},{"name":"tableColumns","value":this.tableColumns}],(instance)=>{
        this.tableColumns=[];
        let list:CustomField[]=instance['list'];
        for(let c of list){
          if(c.selected){
            this.tableColumns.push(new TableColumn(c.label,"customfields['"+c.label+"']"));
          }
        }
        this.getItems();

        //salva il template nel modulo
        this.module.addStored("tableColumns",this.tableColumns);


      },"md");
    }

    onNavigate(link){
      Globals.navigation.onNavigate([link]);
    }

    update(item:any,oncomplete=null){
      this.recordService.updateAny(item,[item['id']],(complete)=>{
        if(oncomplete)
          oncomplete();
      });
    }

    setFilterTypeValue(filter,value,property="value"){
      let values=[];
      
      if(this.isArray(this[filter][property])){
        values=this[filter][property];
      }else{
        if(this[filter][property]!='')
          values=this[filter][property].split(",");
      }
      
      

      if(typeof(value)=="string")
        value="'"+value+"'";
    
      
      let find=false;
      for(let i=0;i<values.length;i++){
        if(values[i]==value){
          values.splice(i,1);
          find=true;
          break;
        }
      }
    
      if(!find){
          values.push(value);
      }
      if(this.isArray(this[filter][property])){
        this[filter][property]=values;
      }else{
        this[filter][property]=values.join(",");
      }
      
      this.getItems();
    }

    checkFilterTypeValue(filter,value,property="value"){
      let values=[];
      
      if(this.isArray(this[filter][property])){
        values=this[filter][property];
      }else{
        if(this[filter][property] && this[filter][property]!='')
          values=this[filter][property].split(",");
      }

      for(let i=0;i<values.length;i++){
        if(values[i]==value){
          return true;
        }
      }

      return false;
      
    }


    newform(record){
      let formsService:FormsValueService=new FormsValueService();

      formsService.showNewForm(record,this.recordService.table,()=>{
        
      });
    }


    isModuleEnabled(modulename){
      
      return Globals.parameters.get(modulename)?true:false;

    }


    openTemplateSearch(){
      Globals.modal.showModal(TemplateSearchComponent,[
        {"name":"mode","value":"modal"},
        {"name":"modelList","value":this},
        
      ],(instance)=>{

      },"md")
    }

    loadTemplatesSearch(){
      let templates:any=localStorage.getItem(this.modulename+"_template_search");

      if(templates=="" || templates==null){
        templates=[];
        
      }else{
        templates=JSON.parse(templates);
        
      }

      return templates;
    }

    loadTemplateSearch(template){
      this.filter=[];
      for(let filter of template.values){
        this[filter['name']]=filter['value'];
        this.filter.push(this[filter['name']]);
        
      }
      this.advancedFilter=template['advancedFilter'];
      this.getItems();
    }

    deleteTemplateSearch(template,templates){
      for(let i=0;i<templates.length;i++){
        if(templates[i]==template){
          templates.splice(i,1);
          localStorage.setItem(this.modulename+"_template_search",JSON.stringify(templates));
          return templates;
        }
      }
      return templates;
    }


    saveTemplateSearch(name:string=""){
      if (name=="") name=this.modulename;
      //colleziona tutti i filtri di ricerca
      let variablesName=Object.keys(this);
      let filters_name=variablesName.filter((x)=>{return x.includes("filter_")});
      let filters=[];
      for(let f of filters_name){
        let filter={};
        filter['name']=f;
        filter['value']=this[f];
        filters.push(filter);
      }

      let templates=this.loadTemplatesSearch();

      let template={};
      template['name']=name;
      template['values']=filters;
      template['advancedFilter']=this.advancedFilter;
      templates.push(template);

      

      localStorage.setItem(this.modulename+"_template_search",JSON.stringify(templates));
    }


}