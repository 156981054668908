<div class="row" *ngIf="isConfig">
    <div class="col-12" >
        <div class="form-group form-group-sm mb-1">
            <label>Tipologia di contatto</label>
            <div class="input-group input-group-sm" >
                <select class="custom-select custom-select-sm"  #type_contact [(ngModel)]="gateway.id_type"  title="{{gateway.id_type}}" >
                    <option *ngFor="let t of type_contacts" value="{{t.id}}" >{{t.name}}</option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!isConfig">
    <div class="col-2" >
        <div class="form-group form-group-sm mb-1">
            <b>Abilita</b>
            <div class="input-group input-group-sm" >
                <select class="custom-select custom-select-sm" name="enable" [(ngModel)]="gateway['enable']" >
                    <option value="0">No</option>
                    <option value="1">Si</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-10">
        <div class="form-group form-group-sm mb-1">
            <b>Contatto</b>
            <div class="input-group input-group-sm" >
                <select class="custom-select custom-select-sm" name="contact" [(ngModel)]="gateway['contact']" (change)="onSelectContact($event)">
                    <option *ngFor="let c of gateway['contacts']" [ngValue]="c.value" >{{c.value}} <span *ngIf="c.note!=''">({{c.note}})</span></option>
                    <option value="">Altro...</option>
                </select>
                <input type="text" placeholder="inserisci qui il contatto" class="form-control  form-control-smml-2" name="contact" [(ngModel)]="gateway['contact']" *ngIf="other_contact" />
                <input type="text" placeholder="inserisci il nome di contatto" class="form-control form-control-sm" name="contact_name" [(ngModel)]="gateway['contact_name']" *ngIf="other_contact" />
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="gateway.gateway=='email'">
    <div class="col-sm-6">
        <div class="form-group form-group-sm mb-1">
            <b>Da</b>
            <input type="email" class="form-control form-control-sm" placeholder="..." name="email_from" [(ngModel)]="email_from" (keyup)="ParseFields()" >
        </div>
    </div>
    <div class="col-sm-6">
        <div class="form-group form-group-sm mb-1">
            <b>CC</b>
            <input type="email" class="form-control form-control-sm" placeholder="..." name="email_cc" [(ngModel)]="email_cc" (keyup)="ParseFields()">

        </div>
    </div>
    <div class="col-sm-12">
        <div class="form-group form-group-sm mb-4">
            <b>Oggetto</b>
            <input type="email" class=" form-control form-control-sm" placeholder="..." [(ngModel)]="email_subject" (keyup)="ParseFields()">
        </div>
    </div>
    <div class="col-sm-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link active" id="h-tabNotification1" data-toggle="tab" href="#tabNotification1" role="tab" aria-controls="tabNotification1" aria-selected="false">
                    <i class="fas fa-envelope-open-text"></i><span class="d-none d-sm-inline-block ml-2">Anteprima</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" id="h-tabNotification2" data-toggle="tab" href="#tabNotification2" role="tab" aria-controls="tabNotification2" aria-selected="false">
                    <i class="fas fa-code"></i><span class="d-none d-sm-inline-block ml-2">Modifica</span></a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tabNotification1" role="tabpanel" aria-labelledby="tabNotification1">
                <div class="border" [innerHTML]="email_body"></div>
            </div>
            <div class="tab-pane fade" id="tabNotification2" role="tabpanel" aria-labelledby="tabNotification2">
                <textarea class="form-control" rows="10" cols="300" name="email_body" [(ngModel)]="email_body" (keyup)="ParseFields()"></textarea>
            </div>
        </div>
    </div>
    <div class="col-sm-12 mt-2">
        <div class="form-group form-group-sm">
            <b>Allegati</b>
            <div class="input-group">
                <input type="text" class="form-control form-control-sm" placeholder="url del file da allegare" [(ngModel)]="email_attachment" (keyup)="ParseFields()">
                <div class="input-group-append">
                    <button class="btn btn-default" (click)="downloadAttach(email_attachment)">Vedi</button>
                </div>    
            </div>
            <!-- <small>
                usare le keywords per specificare un collegamento dinamico. <br/>
                ex template[18,id] 18 = id template di esportazione
            </small> -->
        </div>
    </div>
     <!--<ckeditor [editor]="Editor" [(ngModel)]="email_body" (keyup)="ParseFields()"></ckeditor>-->
</div>
<div class="row" *ngIf="gateway.gateway=='sms'">
    <div class="col-sm-12">
        <div class="form-group form-group-sm mb-1">
            <b>Messaggio</b>
            <textarea rows="10" cols="300" class="form-control form-control-sm" placeholder="..." name="sms_body" [(ngModel)]="sms_body" (keyup)="ParseFields()" ></textarea>
        </div>
    </div>
</div>
<div class="row" *ngIf="gateway.gateway=='whatsapp'">
    <div class="col-sm-12">
        <div class="form-group  form-group-sm mb-1">
            <b>Messaggio</b>
            <textarea rows="10" cols="300" class="form-control form-control-sm" placeholder="..." name="whatsapp_body" [(ngModel)]="whatsapp_body" (keyup)="ParseFields()" ></textarea>
        </div>
    </div>
</div>


