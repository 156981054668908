<app-windowlist  [model]="this">
    
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filter_box">
            <app-filtercontainer>
                
                <app-filterdate [date_type]="default_filterdate" [future]="true" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList($event)"></app-filterdate>
                
                <ul class="card small-box list-group p-1 my-2" *ngIf="methodpayments.length>0" >
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value==''" (click)="filter_method_payment.value='';getItems()">Tutte le modalità di pagamento</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value && filter_method_payment.value.indexOf(m.name)>-1" *ngFor="let m of methodpayments"  (click)="setFilterTypeValue('filter_method_payment',m.name);">{{m.name}}</li>
                    <div class="icon"><i class="fas fa-dollar-sign"></i></div>
                </ul>
                
            </app-filtercontainer>
        </div>
        <div [class.col-lg-10]="filter_box" [class.col-lg-12]="!filter_box" >
            <div class="card mb-0">
                <div class="row p-1" *ngIf="filter_box">
                    <div class="col-10" [class.col-8]="mode=='embedded'">
                        <div class="input-group input-group-sm" >
                            <div class="input-group-prepend">
                                <input type="text"  class="form-control form-control-sm float-right"  size="4" placeholder="ID..." [(ngModel)]="filter_id.value" (keyup.enter)="onSearchEnter()">
                            </div>
                            <input type="text" name="table_search" class="form-control float-right" placeholder="nome cliente o descrizione attività..." [(ngModel)]="filter_search.value" (keyup.enter)="onSearchEnter()">
                            <div class="input-group-append">
                                <input type="text"  class="form-control form-control-sm float-right" placeholder="comune..." [(ngModel)]="filter_search_city.value" (keyup.enter)="onSearchEnter()">
                            </div>
                            <div class="input-group-append">
                                <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="onSearchEnter()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!='' || filter_search_city.value!=''" (click)="filter_search.value='';filter_search_city.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2" *ngIf="mode=='embedded'">
                        <select class="form-control form-control-sm" [(ngModel)]="modeShow" [class.alert-warning]="modeShow==1">
                            <option [value]="0">Mostra tutte</option>
                            <option [value]="1">Mostra solo quelle dell'anno in corso</option>
                            <option [value]="2">Mostra gli ultimi 365 giorni</option>
                        </select>
                    </div>

                    <div class="col-2 py-1" *ngIf="mode!='modal'">
                        <div class="dropdown dropleft show"  *ngIf="user.isAdmin()">
                            <a class="btn btn-default btn-xs float-right ml-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" title="Altre operazioni" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu pt-0" aria-labelledby="dropdownMenuLink">
                                <small>
                                    <b class="dropdown-item border-bottom bg-light text-center text-primary">AZIONI SU ATTIVITA'</b>
                                    <button type="button" class="btn btn-xs dropdown-item"  title="Avvia il processo di rischedulazione delle attività" (click)="openRescheduleMultiple()">Rischedula le attività selezionate</button>
                                    <b class="dropdown-item border-bottom bg-light text-center text-primary">NUOVO DOCUMENTO</b>
                                    <button type="button" class="btn btn-xs dropdown-item"  title="Crea un nuovo documento con le attività selezionate" (click)="addDocument()">Crea documento dalle attività selezionate</button>
                                </small>
                            </div>
                        </div>
                        <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                        <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                        <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                        <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                        <button (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}])" class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
                <div class="row" *ngIf="enableFilterProduct">
                    <div class="col-6">
                        <div class="input-group input-group-sm" >
                            <input type="text" class="form-control float-right" placeholder="numero seriale o nome prodotto..." [(ngModel)]="filter_product.value" (keyup.enter)="onSearchEnter()">
                            
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="modeView==1">
                    <form  [formGroup]="form">
                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm" >
                                <thead class="bg-light">
                                    <th><div #checkboxselectall class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" title="Seleziona tutti"
                                            (change)="onChangeAll($event.target.checked)" />
                                        </div>
                                    </th>
                                    <th>Data

                                        <button type="button" class="btn btn-tool" (click)="switchOrdering('a.date')">
                                            <i class="fas fa-sort {{classOrdering('a.date')}}"  ></i>
                                        </button>
                                    </th>
                                    <th>Orario
                                        <button type="button" class="btn btn-tool" (click)="switchOrdering('a.time')">
                                            <i class="fas fa-sort {{classOrdering('a.time')}}"  ></i>
                                        </button>
                                    </th>
                                    <th *ngIf="mode!='embedded'">Anagrafica</th>
                                    <th *ngIf="mode!='embedded'">Ubicazione</th>
                                    <th>
                                        <select class="form-control form-control-sm" *ngIf="type_activities.length>0" [(ngModel)]="filter_id_type.value" (ngModelChange)="updateList()">
                                            <option class="list-group-item pointer" [class.active]="filter_id_type.value==''" value="" (click)="setType(t);">Tipologie</option>
                                            <option class="list-group-item pointer" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_activities" [value]="t.id" (click)="setType(t);">{{t.name}}</option>
                                        </select>
                                    </th>
                                    <th class="text-center">
                                        <select class="form-control form-control-sm" [(ngModel)]="filter_id_user.value" (ngModelChange)="updateList()" [class.alert-info]="filter_id_user.value">
                                            <option value="">Operatori</option>
                                            <ng-container *ngFor="let u of users">
                                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                            </ng-container>
                                        </select>
                                    </th>
                                    <th class="text-center">
                                        <select class="form-control form-control-sm" [(ngModel)]="filter_phase.value" (ngModelChange)="updateList()"
                                        [class.alert-warning]="filter_phase.value==1"
                                        [class.alert-success]="filter_phase.value==2"
                                        [class.alert-success]="filter_phase.value==3"
                                        [class.alert-warning]="filter_phase.value==4" >
                                        <option value="">Stato</option>
                                        <option value="1">In corso</option>
                                        <option value="2">Completate</option>
                                        <option value="3">Confermate</option>
                                        <option value="4">Rischedulate</option>
                                    </select>
                                    </th>
                                    <th class="text-right">Importo</th>
                                    <th class="text-right">Versato

                                        <button type="button" class="btn btn-tool" (click)="switchOrdering('a.paid')">
                                            <i class="fas fa-sort {{classOrdering('a.paid')}}"  ></i>
                                        </button>
                                    </th>
                                    <th></th>
                                </thead>
                                <thead *ngIf="list && list.length>0">
                                    <tr >
                                        <td ></td>
                                        <td colspan="5" class="text-left"><b>TOTALE <span *ngIf="modeShow==1" title="Possono esserci alcune attività nascoste">*</span></b></td>
                                        <td colspan="2" *ngIf="mode!='embedded'"></td>
                                        <td class="text-right" [class.text-red]="total_amount < 0">
                                            <b>{{total_amount | currency:'EUR':true }}</b>
                                        </td>
                                        <td class="text-right" [class.text-red]="total_paid < 0">
                                            <b>{{total_paid | currency:'EUR':true }}</b>
                                        </td>
                                        <td ></td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="list && list.length>0">
                                    <ng-container *ngFor="let activities of list">
                                        <tr *ngIf="isShowed(activities)"  [class.bg-redline]="statusPaid(activities)==3" [class.bg-yellowline]="statusPaid(activities)==2" [class.bg-greenline]="statusPaid(activities)==1">
                                            <td>
                                                <div class="icheck-primary d-inline mr-3">
                                                    <input type="checkbox" #checkrecord id_record="{{activities.id}}" title="{{activities.id}}"
                                                    (change)="onChange(activities.id, $event.target.checked)" [checked]="checkedAll"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <span (click)="openDetail(activities)" title="Dettaglio attività" class="pointer">
                                                    {{activities.date | date:'dd/MM/yyyy'}}<br/>
                                                    <small>
                                                        <i>ID: {{activities.id}}</i>
                                                    </small>
                                                </span>
                                                <small>
                                                    <div class="alert alert-info p-1 mb-0 mt-1" *ngIf="activities.phase==4">
                                                        <i class="fas fa-thumbtack mr-2"></i>
                                                        da rischedulare a {{activities.reschedule | date:'dd/MM/yyyy'}}<br/>
                                                        <i>{{activities.note_reschedule}}</i>
                                                    </div>
                                                </small>
                                            </td>
                                            <td>{{activities.time}}</td>
                                            <td *ngIf="mode!='embedded'">
                                                <small>
                                                    <!--<app-reference [reference]="activities.reference" [table]="activities.table"></app-reference>-->
                                                    
                                                </small>
                                                <b><i class="fa fa-user" (click)="openAddress(activities.id_address)"></i>&nbsp;{{activities.name}}</b>
                                            </td>
                                            <td *ngIf="mode!='embedded'">
                                                {{activities.address}}<br/>
                                                <b>{{activities.city}}</b> {{activities.country}}
                                                <span *ngIf="activities.latitude==0"><div class="badge border">NO GPS</div></span>
                                            </td>
                                            <td>
                                                <i *ngIf="activities.priority==2" title="Priorità 2" class="text-warning fas fa-exclamation"></i> 
                                                <i *ngIf="activities.priority==3" title="Priorità 3" class="text-danger fas fa-exclamation"></i>
                                                {{activities.type}}                                                
                                                <span *ngIf="activities.documents && activities.documents.length>0"
                                                        [class.text-danger]="activities.documents[0].cashflow.length==0" 
                                                        [class.text-success]="activities.documents[0].cashflow.length>0"
                                                        [class.pointer]="activities.documents[0].cashflow.length>0" 
                                                        class="ml-1">
                                                        <i class="fas fa-file-invoice-dollar" (click)="openCashflow(activities.documents[0].cashflow[0].id)" title="Movimenti associati ai documenti: {{activities.documents[0].cashflow.length}}"></i>
                                                </span>
                                                <span *ngIf="(activities.amount>0 || activities.paid>0) && activities.enable_accounting"  
                                                    [class.text-danger]="activities.id_cashflow==0" 
                                                    [class.text-warning]="activities.id_cashflow>0 && !hasCashflowPaid(activities)" 
                                                    [class.text-success]="activities.id_cashflow>0 && hasCashflowPaid(activities)"
                                                    [class.pointer]="activities.id_cashflow>0" 
                                                    class="ml-1">
                                                    <i class="fas fa-dollar-sign" (click)="openCashflow(activities.id_cashflow)" title="Movimenti associati"></i>
                                                </span>
                                                <i *ngIf="activities.id_form>0" title="È stato compilato un modulo" class="fab fa-wpforms text-info ml-2"></i>
                                                <br/>
                                                <small>{{activities.description}}</small>
                                                <ng-container *ngIf="activities.note">
                                                    <hr>
                                                    <small><i class="fas fa-thumbtack text-info mr-2"></i>{{activities.note}}</small>
                                                </ng-container>
                                                <ng-container *ngIf="activities.documents && activities.documents.length>0">
                                                    <br/>
                                                    <div class="badge border pointer" *ngFor="let d of activities.documents" (click)="openDocument(d);" title="Dettaglio documento">
                                                        <i class="fa fa-file"></i> &nbsp;Documento n. <b>{{d.reference}}{{d.referenceprefix}}</b> del <b>{{d.date}}</b>
                                                    </div>
                                                </ng-container>
                                            </td>
                                            <td class="text-center"><small>{{activities.username}}</small></td>
                                            <td class="text-center">
                                                <i *ngIf="activities.phase==4" title="Doppio click per rischedulare l'attività" (dblclick)="reschedule(activities)" class="text-warning fa fa-lg fa-2x fa-exclamation pointer"></i>
                                                <i *ngIf="activities.phase==3" title="Attività confermata!" class="text-success fa fa-lg fa-2x fa-check-double"></i>
                                                <i *ngIf="activities.phase==2" title="Doppio click per confermare l'attività" (dblclick)="confirmActivities(activities)" class="text-success fa fa-2x fa-check pointer"></i>
                                                <i *ngIf="activities.phase==1" title="Attività in corso..." class="text-warning far fa-lg fa-2x fa-clock"></i>
                                            </td>
                                            <td class="text-right">
                                                {{activities.amount*(1+activities.tax/100) | currency:'EUR':true }}
                                            </td>
                                            <td class="text-right">
                                            <b [class.text-danger]="activities.paid<activities.amount*(1+activities.tax/100)">{{activities.paid | currency:'EUR':true }}</b><br/>
                                                <span *ngIf="activities.method_payment" class="badge border"><b>{{activities.method_payment}}</b></span>
                                                <small>
                                                    <div class="alert alert-info p-1 mt-1 mb-0 text-center" *ngIf="activities.paid_description!=''">
                                                        <i>{{activities.paid_description}}</i>
                                                    </div>
                                                    <div class="alert alert-info p-1 mt-1 mb-0 text-center" *ngIf="activities.paid_type==3">
                                                        <i>Pagamento rischedulato<br>al {{activities.reschedule_payment | date:"d/M/Y"}}</i>
                                                    </div>
                                                </small>
                                            </td>
                                            <td>
                                                <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(activities);">
                                                    <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                                </button>
                                                <button type="button" (click)="openDetail(activities)" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                                    <i class="fas fa-pencil-alt"></i></button>
                                                <button type="button" *ngIf="mode!='modal'" (click)="notify(activities);" title="Notifica" class="btn btn-primary btn-xs float-right m-1">
                                                    <i class="far fa-paper-plane"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                
                                <tfoot *ngIf="list && list.length>0">
                                    <tr>
                                        <td ></td>
                                        <td colspan="5" class="text-left"><b>TOTALE <span *ngIf="modeShow==1" title="Possono esserci alcune attività nascoste">*</span></b></td>
                                        <td colspan="2" *ngIf="mode!='embedded'"></td>
                                        <td class="text-right" [class.text-red]="total_amount < 0">
                                            <b>{{total_amount | currency:'EUR':true }}</b>
                                        </td>
                                        <td class="text-right" [class.text-red]="total_paid < 0">
                                            <b>{{total_paid | currency:'EUR':true }}</b>
                                        </td>
                                        <td ></td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                                <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                                <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                            </div>
                        </form>
                        <div class="card-footer clearfix py-1">
                            <app-pagination [model]="this" ></app-pagination>
                        </div>
                </ng-container>

                <ng-container *ngIf="modeView==2">
                    <div id="map" style="height: 100%;width: 100%;" [style.min-height]="map_height"></div>
                    <div *ngIf="loadingMap" id="loading"  [style.min-height]="map_height">
                        Caricamento in corso...
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</app-windowlist>

       
<ng-template #rescheduleModal>
    <div class="modal-header">
        <b>Rischedula attività</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeReschedule()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Azione</label>
            <select class="form-control form-control-sm" name="rescheduleAction" [(ngModel)]="rescheduleAction">
                <option value="1">Crea nuova voce di agenda</option>
                <option value="2">Modifica data di questa attività</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="confirmReschedule()">Conferma</button>
        <button class="btn btn-secondary" (click)="closeReschedule()">Annulla</button>
    </div>
</ng-template>


<ng-template #modalRescheduleMultiple>
    <div class="modal-header">
        <b>Rischedula più attività</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeRescheduleMultiple()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
                <th>Attività</th>
                <th>Data</th>
                <th>Operatore</th>
            </thead>
            <tbody>
                <tr *ngFor="let activities of rescheduleRecords;let i=index">
                    <td>
                        {{activities.type}}<br/><br/>
                        <b>{{activities.name}}</b><br/>
                        {{activities.address}}<br/>
                        {{activities.city}}

                        <!--<app-reference [reference]="activities.reference" [table]="activities.table"></app-reference>-->

                    </td>
                    <td>
                        <input type="date" class="form-control form-control-sm" name="date_{{i}}" [(ngModel)]="activities.date">
                    </td>
                    <td>
                        <select class="form-control form-control-sm" name="user_{{i}}" [(ngModel)]="activities.id_user">
                            <ng-container *ngFor="let u of users">
                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeRescheduleMultiple()">Annulla</button>
        <button class="btn btn-success" (click)="confirmRescheduleMultiple()">Conferma</button>
    </div>
</ng-template>