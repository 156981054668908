<ng-container *ngIf="showButton">
  <a *ngIf="list.length>0" class="nav-link" data-toggle="dropdown" href="#">
      <i class="far fa-bell"></i>
      <small class="ml-1">{{list.length}}</small>
  </a>
  <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
      
    <ng-container *ngTemplateOutlet="listAlarms"></ng-container>
      <!-- <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> -->
  </div>
</ng-container>
<ng-container *ngIf="!showButton">
  <ng-container *ngTemplateOutlet="listAlarms"></ng-container>
</ng-container>

<ng-template #listAlarms>
  <ng-container *ngFor="let r of list">
        
    <a  class="dropdown-item pointer" style="white-space: normal" (click)="openModalOverview(r)">
        <!-- <i class="fas fa-exclamation-circle mr-2 text-info"></i> -->
        <!-- <i class="fas fa-exclamation-circle mr-2 text-danger"></i> -->
        <i class="fas fa-exclamation-circle mr-2 text-warning"></i>
        <!-- <i class="fas fa-exclamation-circle mr-2 text-success"></i> -->
        <small>{{r.message}}</small>
      </a>
      <div class="dropdown-divider"></div>
</ng-container>
</ng-template>

<ng-template #modalOverview>
  <div class="modal-header bg-warning">
      <b>{{alarmSelected.message}}</b>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModalOverview()" >
          <i class="fas fa-times"></i>
        </button>
    </div>
  </div>
    <div class="modal-body">
      <ul class="list-group">
          <li class="list-group-item" *ngFor="let i of alarmSelected.items;">
          <div class="row">
            <div class="col-9">
              <small>
                <app-reference #refbox [reference]="i" [table]="alarmSelected.table"></app-reference>
              </small>
            </div>
            <div class="col-3 text-right">
              <button class="btn btn-sm btn-secondary" title="Modifica / Vedi" (click)="refbox.openReference(i,i.table_reference)"><i class="fa fa-pencil-alt"></i></button>
            </div>
          </div>
          <hr/>
          </li> 
      </ul>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-secondary" (click)="closeModalOverview()">Chiudi</button>
    </div>
</ng-template>