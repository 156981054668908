import { Component, OnInit, Input } from '@angular/core';
import { Filter, FilterMode } from 'projects/core/src/include/structures'
import { TypesService } from '../../services/types.service';
import { Type } from '../../db/type';
import { Contracts } from '../../db/contracts';
import { ContractsService } from '../../services/contracts.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist'
import { NotificationService } from '../../services/notification.service';
import { Globals } from 'projects/core/src/globals';
import { PreviewnotificationComponent } from '../../modules/previewnotification/previewnotification.component';
import { ContractsDetailComponent } from './contracts-detail/contracts-detail.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.sass']
})
export class ContractsComponent extends ModelList<Contracts> implements OnInit  {
  @Input()
  filter_box=true

  @Input()
  id_address:number=0;

  @Input()
  id_shop:number=0;

  @Input()
  id_product:number=0;

  total=null;
  
  type_contract:Type[];
  status_product:Type[];

  filter_date:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_type:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_id_shop:Filter=new Filter();
  filter_no_installments:Filter=new Filter();
  filter_id_product:Filter=new Filter();
  filter_phase:Filter=new Filter();
  isFilterSet:boolean=false;

  constructor(
    private fb1: UntypedFormBuilder,
    private contractssService: ContractsService,
    private typesService: TypesService,
    private notificationService:NotificationService,
    
  ) {
    super(contractssService,fb1);
    this.detailViewModal=ContractsDetailComponent; 
   }


   ngOnInit(): void {
     this.title='Contratti';
    super.ngOnInit.apply(this, arguments); 

    
    this.afterGetItems=(()=>{
    

        //calcolo i valori totali
      this.contractssService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{
        if(item){
          this.total=item;
        
        }
        
      });
    });

    this.typesService.getTypes("contracts").subscribe((items)=>{
      this.type_contract=items;
    });
  
    /*
      if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
        this.updateList(this.id_address);*/
  }

  setFilters():void{

    this.filter_date.mode=FilterMode.minorequal;
    this.filter_date.fields=[];
    this.filter_date.fields.push("c.end");
    this.filter_date.value="";
    this.filter.push(this.filter_date);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("c.description");
    this.filter_search.fields.push("s.name");
    
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("a.id");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);
    
    this.filter_id_shop.mode=FilterMode.normal;
    this.filter_id_shop.fields=[];
    this.filter_id_shop.fields.push("c.id_shop");
    this.filter_id_shop.value="";
    this.filter.push(this.filter_id_shop);

    this.filter_phase.mode=FilterMode.normal;
    this.filter_phase.fields=[];
    this.filter_phase.fields.push("c.phase");
    this.filter_phase.value="";
    this.filter.push(this.filter_phase);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("c.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("c.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_no_installments.mode=FilterMode.normal;
    this.filter_no_installments.enabled=false;
    this.filter_no_installments.fields=[];
    this.filter_no_installments.fields.push("c.installments");
    this.filter_no_installments.value="0";
    this.filter.push(this.filter_no_installments);

    

    if(this.id_product>0){
      this.filter_id_product.mode=FilterMode.contain;
      this.filter_id_product.fields=[];
      this.filter_id_product.fields.push("c.id");
      this.filter_id_product.value="SELECT id_contract FROM contractsproducts WHERE id_product="+this.id_product.toString();
      this.filter.push(this.filter_id_product);

      this.paging_count=100000000;
    }
    Globals.navigation.getStateFilters(this.constructor.name,this.filter);
  

    this.ordering.field="c.id";

    this.isFilterSet=true;
  }

  ngOnChanges():void{
    
    if(this.id_address>0 || this.id_shop>0 || this.id_product>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
     
    
  }
  
  updateList(id_address:number):void{
    setTimeout(()=>{
      if(this.mode=="embedded"){ //non avviare la ricerca fino a quando non si è impostato l'id_address
        if((this.id_address==0 || this.id_address==undefined))
          return;
      }

      if(!this.isFilterSet)
        this.setFilters();

      if(id_address>0){
        this.filter_id_address.value=id_address.toString();
      }
      if(this.id_shop>0){
        this.filter_id_shop.value=this.id_shop.toString();
      }
      

      this.getItems();
    },100)
  }

  testDate(date){
  
    let d=Date.parse(date);
    let t=this.today.getTime();
    let v:boolean=t>d;
    return v;
  }

  notify(record:Contracts){

    
    Globals.modal.showModal(PreviewnotificationComponent,[{"name":"table","value":"contracts"},{"name":"contacts","value":record['shop']['addressItem']['contacts']},{"name":"record","value":record}],(instance)=>{
      this.notificationService.sendNotify(instance['selectedNotify'],record['shop']['id_address'],(id)=>{

        });
     });
    
  }

  newform(record: any): void {

    this.contractssService.getItem(record.id).subscribe((item)=>{
      super.newform(item);
    });

      
  }


}


