import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InstallationLog } from 'projects/c1-backend/src/app/db/installation';
import { InstallationslogsService } from 'projects/c1-backend/src/app/services/installationslogs.service';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Model } from 'projects/core/src/include/model';
import { User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';

@Component({
  selector: 'app-serialslogs-detail',
  templateUrl: './serialslogs-detail.component.html',
  styleUrls: ['./serialslogs-detail.component.css']
})
export class SerialslogsDetailComponent extends Model<InstallationLog> implements OnInit {

  @Input()
  sn:string="";

  shops=[];
  users:User[];

  constructor(
    private route1: ActivatedRoute,
    private installationsLogsService: InstallationslogsService,
    private shopsService:ShopsService,
    private userService:UserService
  
  ) { 
    super(route1,installationsLogsService); 
  }

  ngOnInit(): void {
    this.title = 'Log Prodotto Dettaglio';
    super.ngOnInit.apply(this, arguments); 

    const default_address=Globals.parameters.get("general").getParam("default_address");

    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
    });

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
      this.getItem();
    });

    this.afterGetItem=(()=>{
      if(this.record.id==0 || this.record.id==undefined){
        this.record=new InstallationLog();
        this.record.date=Helper.convertDateControl();
        this.record.sn=this.sn;
        this.record.id_user=Globals.user.id;
        this.record.created_from=Globals.user['shop']?Globals.user['shop']['id']:(this.shops.length>0?this.shops[0].id:0);

      }
    });
    
  }

}
