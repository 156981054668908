import { NgModule } from '@angular/core';
import { ProductsComponent } from './products.component';
import { ProductsDetailsComponent } from './products-details/products-details.component';
import {CategoriesComponent} from './categories/categories.component';
import {BrandsComponent} from './brands/brands.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { AdvancedsearchModule } from '../../modules/advancedsearch/advancedsearch.module';
import { ContactModule } from '../../modules/contact/contact.module';
import {ProductsRoutingModule} from './products-routing.module';
import { InventoriesModule } from '../inventories/inventories.module';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { TreecategoriesModule } from '../../modules/treecategories/treecategories.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { ProductitemModule } from '../../modules/productitem/productitem.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { SerialsComponent } from './serials/serials.component';
import { SerialsDetailsComponent } from './serials/serials-details/serials-details.component';
import { SerialshopComponent } from './serials/serialshop/serialshop.component';
import { SerialslogsComponent } from './serials/serialslogs/serialslogs.component';
import { SerialslogsDetailComponent } from './serials/serialslogs/serialslogs-detail/serialslogs-detail.component';
import { NoteboxModule } from '../../modules/notebox/notebox.module';
import { ActivitiesModule } from '../activities/activities.module';
import { CashFlowModule } from '../cash-flow/cash-flow.module';
import { DeadlinesModule } from '../deadlines/deadlines.module';
import { NewformModule } from '../../modules/newform/newform.module';

import { FormsValuesModule} from '../forms/forms.module';
import { EditTableHeaderModule } from 'projects/core/src/common/edit-table-header/edit-table-header.module';

import { SerialListComponent } from './serials/list/list.component';
import { SerialActivitiesComponent } from './serials/activities/activities.component';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { AnalyticsComponent } from './serials/analytics/analytics.component';
import { TypeModule } from '../../modules/type/type.module';
import { WindowlistbuttonsModule } from 'projects/core/src/common/windowlistbuttons/windowlistbuttons.module';
import { Globals } from 'projects/core/src/globals';
import { DeadlinesComponent } from './serials/deadlines/deadlines.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        CitiesModule,
        PaginationModule,
        AdvancedsearchModule,
        ProductsRoutingModule,
        ContactModule,
        ModalModule,
        InventoriesModule,
        ToolbarmodelModule,
        TreecategoriesModule,
        ProductitemModule,
        NoteboxModule,
        ActivitiesModule,
        DeadlinesModule,
        CashFlowModule,
        NewformModule,
        FormsValuesModule,
        EditTableHeaderModule,
        FilterdateModule,
        NoteboxModule,
        TypeModule,
        WindowlistbuttonsModule
        
        

    ],
   exports: [ProductsComponent,ProductsDetailsComponent,CategoriesComponent,BrandsComponent,
              SerialsComponent,
              SerialsDetailsComponent,
              SerialshopComponent,
              SerialslogsComponent,
              SerialListComponent,
              SerialActivitiesComponent
            ],
   declarations: [ProductsComponent,ProductsDetailsComponent,CategoriesComponent,BrandsComponent,
              SerialsComponent,
              SerialsDetailsComponent,
              SerialshopComponent,
              SerialslogsComponent,
              SerialslogsDetailComponent, 
              SerialListComponent, 
              SerialActivitiesComponent, AnalyticsComponent, DeadlinesComponent
            ],
   providers: [],
})
export class ProductsModule{ 

    constructor(){
      Globals.parameters.loadModulesEvent.subscribe((result)=>{
        Globals.parameters.createParam(
          "installations",
          "id_category_log",
          "ID categoria per il monitoraggio",
          "number"
        );

        Globals.parameters.createParam(
          "installations",
          "answer_move",
          "Chiedi motivazione spostamento",
          "boolean"
        );

        Globals.parameters.createParam(
          "installations",
          "id_type_default",
          "ID Tipologia di Default",
          "type"
        );


        Globals.parameters.createParam(
          "installations",
          "id_type_client",
          "ID Tipologia Clienti",
          "type"
        );

        Globals.parameters.createParam(
          "installations",
          "id_type_supplier",
          "ID Tipologia Fornitori",
          "type"
        );

        Globals.parameters.createParam(
          "installations",
          "tags",
          "Tag",
          "table",
          [
            {"name":"label","label":"Etichetta"},
            {"name":"tag","label":"Tag"}
            
          ]
        );

        Globals.parameters.createParam(
          "installations",
          "ids_type_deadline_to_reset",
          "IDs Tipologie Scadenze da eliminare dopo una movimentazione di un prodotto",
          "text"
        );

        Globals.parameters.createParam(
          "installations",
          "search_into_inventory",
          "Ricerca  i seriali solo se presenti nel magazzino (solo per il dettaglio beni)",
          "boolean",
          true
        );


        Globals.addSection("products","products");
      });
    }

}