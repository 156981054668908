<div class="row">
    <div class="col-lg-12">
        <div class="p-2">
          <div class="row mb-2">
            <div class="col-3"></div>
              <div class="col-6">
                  <div class="btn-group btn-block" role="group" aria-label="Visualizzazione">
                      <button type="button" class="btn btn-xs px-5 btn-primary" [class.bg-light]="tab==2" [class.active]="tab==1" (click)="tab=1;getMonths()">Mensile</button>
                      <button type="button" class="btn btn-xs px-5 btn-primary" [class.bg-light]="tab==1" (click)="tab=2;getDocuments()">Per tipo documento</button>
                  </div>
              </div>
          </div>
          <hr>

        <ng-container *ngIf="tab==1">
         <div class="card mb-0">
            <div class="row my-1">
                <div class="col-4"></div>
                <div class="col-1 pr-0 text-right">
                    <label>Anno</label>
                </div>
                <div class="col-2 ">
                    <div class="input-group input-group-info input-group-sm">
                        <input class="form-control form-control-sm text-center" type="number" name="year" [(ngModel)]="selectedYear" (keyup.enter)="getMonths()">
                        <div class="input-group-append">
                            <button type="button" title="avvia ricerca" class="btn btn-default" (click)="getMonths()">
                                <i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-4"></div>
            </div>
            <table class="table table-nowrap table-striped table-hover text-sm">
                <thead>
                    <th>Mese</th>
                    <th>Doc. Uscita (Emessi)</th>
                    <th>Doc. Ingresso (Ricevuti)</th>
                    <th>Margine</th>
                    <th>Totale da pagare</th>
                    <th>Incassato</th>
                </thead>
                <tbody>
                    <tr *ngFor="let r of list_month">
                        <td>{{r.date | date:"MMMM"}}</td>
                        
                        <td>{{r.total_out | currency:" €"}}</td>
                        <td>{{r.total_in | currency:" €"}}</td>
                        <td>
                            <span [class.text-danger]="(r.total_out - r.total_in)<0" class="text-success">
                                {{r.total_out - r.total_in | currency:" €"}}
                            </span>
                        
                        </td>
                        <td>{{r.total_pay | currency:" €"}}</td>
                        <td>{{r.total_paid | currency:" €"}}</td>
                        
                    </tr>
                </tbody>
                <tfoot style="border-top: 1px solid #333">
                    <tr>
                        <td><b>TOTALE</b></td>
                        <td><b>{{total.total_out | currency: "EUR"}}</b></td>
                        <td><b>{{total.total_in | currency: "EUR"}}</b></td>
                        <td><b>{{total.total_difference | currency: "EUR"}}</b></td>
                        <td><b>{{total.total_pay | currency: "EUR"}}</b></td>
                        <td><b>{{total.total_paid | currency: "EUR"}}</b></td>
                    </tr>
                </tfoot>
            </table>
         </div>
        </ng-container>

        <ng-container *ngIf="tab==2">
            <div class="row py-1">
                <div class="col-lg-2 pr-md-0" >
                    <small>
                        <app-filterdate [date_type]="98" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getDocuments()"></app-filterdate>
                    </small>
                </div>
                <div class="col-lg-10">
                    <div class="card">
                        <div class="card-header">
                            <b>Documenti in uscita</b>
                        </div>
                        <div class="card-body py-0">
                            <table class="table mb-0 no-border tabel-sm">
                                <thead class="border-bottom">
                                    <th>Sezionale</th>
                                    <th class="text-right">Totale</th>
                                    <th class="text-right">Pagato</th>
                                    <th class="text-right">Differenza</th>
                                    <th></th>
                                </thead>
                                <tbody *ngFor="let r of list['out']">
                                    <tr>
                                        <td>{{r.referenceprefix}}</td>
                                        <td class="text-right"><strong>{{r.total | currency:" &euro;"}}</strong></td>
                                        <td class="text-right"><strong>{{r.paid | currency:" &euro;"}}</strong></td>
                                        <td class="text-right" [class.text-danger]="r.paid-r.total<0" [class.text-success]="r.paid-r.total>=0"><strong>{{r.paid-r.total | currency:" &euro;"}}</strong></td>
                                        <td class="text-right">
                                            <button class="btn btn-xs btn-outline-secondary" *ngIf="!r.show" (click)="r.show=true">Dettagli<i class="fas fa-chevron-down ml-1"></i></button>
                                            <button class="btn btn-xs btn-secondary" *ngIf="r.show" (click)="r.show=false">Nascondi<i class="fas fa-chevron-up ml-1"></i></button>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="r.show">
                                        <tr *ngFor="let d of r.documents" >
                                            <td class="text-right py-1">
                                                <div *ngIf="d.typedocument==1"  title="Fattura" class="badge badge-info bg-olive">FS</div>
                                                <div *ngIf="d.typedocument==2"  title="Fattura Accompagnatoria" class="badge badge-info bg-teal" >FV</div>
                                                <div *ngIf="d.typedocument==12"  title="Fattura Acconto" class="badge badge-info bg-teal" >FA</div>
                                                <div *ngIf="d.typedocument==3"  title="Nota di Credito" class="badge badge-info bg-pink">NC</div>
                                                <div *ngIf="d.typedocument==4"  title="Preventivo" class="badge badge-warning">PR</div>
                                                <div *ngIf="d.typedocument==5"  title="Documento di trasporto" class="badge badge-secondary">DDT</div>
                                                <div *ngIf="d.typedocument==10"  title="Trasferimento" class="badge badge-secondary">TR</div>
                                                <div *ngIf="d.typedocument==6"  title="Scontrino" class="badge badge-secondary">SC</div>
                                                <div *ngIf="d.typedocument==7"  title="Ordine cliente" class="badge badge-secondary">OC</div>
                                                <div *ngIf="d.typedocument==8"  title="Ordine fornitore" class="badge badge-secondary">OF</div>
                                                <div *ngIf="d.typedocument==9"  title="Non fiscale" class="badge badge-secondary">NF</div>
                                                <div *ngIf="d.typedocument==11"  title="Documento amministrativo" class="badge bg-olive">DA</div>
                                            </td>
                                            <td class="text-right py-1">
                                                <small>
                                                    <ng-container *ngIf="d.accounted">
                                                        <small>*</small> {{d.total | currency:" &euro;" }}
                                                    </ng-container>
                                                    <ng-container *ngIf="!d.accounted">
                                                        ( {{d.total | currency:" &euro;" }} )
                                                    </ng-container>
                                                </small>
                                            </td>
                                            <td class="text-right py-1">
                                                <small>
                                                    <ng-container *ngIf="d.accounted">
                                                        {{d.paid | currency:" &euro;" }}
                                                    </ng-container>
                                                </small>
                                            </td>
                                            <td class="text-right py-1" [class.text-danger]="d.paid-d.total<0" [class.text-success]="d.paid-d.total>=0">
                                                <small>
                                                    <ng-container *ngIf="d.accounted">
                                                        {{d.paid-d.total | currency:" &euro;" }}
                                                    </ng-container>
                                                </small>
                                            </td>
                                            <td class="text-right py-1">
                                                <small *ngIf="d.accounted" class="text-gray">*({{d.total/r.total*100 | number:'1.2'}}%)</small>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <b>Documenti in ingresso</b>
                        </div>
                        <div class="card-body">
                            <table class="table tabel-sm mb-0 no-border table-striped ">
                                <thead>
                                    <th>Documento</th>
                                    <th class="text-right">Totale</th>
                                    <th class="text-right">Pagato</th>
                                    <th class="text-right">Differenza</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of list['in']">
                                        <td>
                                            <div *ngIf="r.typedocument==1"  title="Fattura" class="badge badge-info bg-olive">FS</div>
                                            <div *ngIf="r.typedocument==2"  title="Fattura Accompagnatoria" class="badge badge-info bg-teal" >FV</div>
                                            <div *ngIf="r.typedocument==12"  title="Fattura Acconto" class="badge badge-info bg-teal" >FA</div>
                                            <div *ngIf="r.typedocument==3"  title="Nota di Credito" class="badge badge-info bg-pink">NC</div>
                                            <div *ngIf="r.typedocument==4"  title="Preventivo" class="badge badge-warning">PR</div>
                                            <div *ngIf="r.typedocument==5"  title="Documento di trasporto" class="badge badge-secondary">DDT</div>
                                            <div *ngIf="r.typedocument==10"  title="Trasferimento" class="badge badge-secondary">TR</div>
                                            <div *ngIf="r.typedocument==6"  title="Scontrino" class="badge badge-secondary">SC</div>
                                            <div *ngIf="r.typedocument==7"  title="Ordine cliente" class="badge badge-secondary">OC</div>
                                            <div *ngIf="r.typedocument==8"  title="Ordine fornitore" class="badge badge-secondary">OF</div>
                                            <div *ngIf="r.typedocument==9"  title="Non fiscale" class="badge badge-secondary">NF</div>
                                            <div *ngIf="r.typedocument==11"  title="Documento amministrativo" class="badge bg-olive">DA</div>
                                            <div *ngIf="r.typedocument==13"  title="Inventario" class="badge bg-navy">INV</div>
                                        </td>
                                        <td class="text-right"><strong>{{r.total | currency:" &euro;"}}</strong></td>
                                        <td class="text-right"><strong>{{r.paid | currency:" &euro;"}}</strong></td>
                                        <td class="text-right" [class.text-danger]="r.paid-r.total<0" [class.text-success]="r.paid-r.total>=0"><strong>{{r.paid-r.total | currency:" &euro;"}}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div>