import { Component, OnInit,Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModelList } from '../../include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { UserService } from '../../lib/user.service';
import { Filter, FilterMode, User } from '../../include/structures';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../globals';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})

export class UsersComponent extends ModelList<User> implements OnInit {

  @ViewChild("modalUser")
  modalUser:TemplateRef<any>;
  modalUserRef;

  view_enabled=false;

  get currentUser(){
    return Globals.user;
  }

  selectedUser:User={} as User;

  filter_status:Filter=new Filter();
  filter_role:Filter=new Filter();
  filter_search:Filter=new Filter();

  constructor(
    private userService: UserService,
    private fb1: UntypedFormBuilder,
    private modalService:NgbModal
  ) {
    super(userService,fb1);
   }


  ngOnInit(): void {
    this.title='Utenti';
    super.ngOnInit.apply(this, arguments); 
    this.getItems();
   
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("u.username");
    this.filter_search.fields.push("u.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_role.mode=FilterMode.normal;
    this.filter_role.fields=[];
    this.filter_role.fields.push("u.role");
    this.filter_role.value="";
    this.filter.push(this.filter_role);
    
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("u.enabled");
    this.filter_status.value="";
    this.filter.push(this.filter_status);
    
  }

  addUser(){
    let r:User={} as User;
    r.role="guest";
    r.enabled=1;
    

    this.selectedUser=r;
    this.modalUserRef=this.modalService.open(this.modalUser);
  }

  setUser(record:User){
    this.selectedUser=new User();
    Object.assign(this.selectedUser, record);
    this.selectedUser.setParams();
    this.modalUserRef=this.modalService.open(this.modalUser);

  }


  confirm_delete(id){
    confirm("Eliminare l'utente?")?this.delete(id):false;
  }


  confirmUser(){

    this.userService.save(this.selectedUser,(id)=>{
      this.getItems();
      this.modalUserRef.close("success");
    });
  }

  cancelUser(){
    this.modalUserRef.close("success");
  }

  /** Verifica se l'utente corrente può visualizzare il record */
  checkPermission(user:User){

    //se sei un SuperUser puoi vedere tutto
    switch( this.currentUser.role){
      case "superuser":
        return true;

      case "admin":
        return user.role!="superuser"?true:false;
      
      case "manager":
        return (user.role!="superuser" && user.role!="admin" && user.role!="manager")?true:false;
      

      default:
        return (user.role!="superuser" && user.role!="admin" && user.role!="manager" && user.role!="agent")?true:false;

     
        
    }

  }

 
}
