import { Component, OnInit, Input, TemplateRef,ViewChild } from '@angular/core';
import { UntypedFormBuilder,FormGroup, FormArray, FormControl } from '@angular/forms';
import { Type } from '../../../../db/type';
import { Deadlines } from '../../../../db/deadlines';
import { TypesService } from '../../../../services/types.service';
import { DeadlinesService } from '../../../../services/deadlines.service';
import { ActivitiesService } from '../../../../services/activities.service';
import { Activities } from '../../../../db/activities';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelList } from 'projects/core/src/include/modellist';
import { UserService } from 'projects/core/src/lib/user.service';
import { Filter, FilterMode, OrderingMode, ToastMode, User } from 'projects/core/src/include/structures';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { MethodpaymentsService } from '../../../../services/methodpayments.service';
import { CashFlowComponent } from '../../../cash-flow/cash-flow-list/cash-flow-list.component';
import { ReferenceComponent } from '../../../../modules/reference/reference.component';
import { FinancialaccountComponent } from '../../../../modules/financialaccount/financialaccount.component';
import { DeadlineDetailsComponent } from '../../../deadlines/deadline-details/deadline-details.component';
import { CalendarComponent } from '../../../deadlines/calendar/calendar.component';


export interface generateInstallment{
  start:Date;
  end:Date;
  id_address:number;
  id_table:number;
  table:string;
  description:string;
  amount:number;
  tax:number;
  gross:number;
  amount_type:number;
  type:number;
  id_type:number;
  typedetail:number;
  params:any;
  reference:any;

}


@Component({
  selector: 'app-serials-deadlines',
  templateUrl: './deadlines.component.html',
  styleUrls: ['./deadlines.component.css']
})


export class DeadlinesComponent extends ModelList<Deadlines> implements OnInit {

  @ViewChild(CalendarComponent)
  calendar:CalendarComponent;

  @ViewChild("cashflow")
  cashflow:CashFlowComponent;

  @ViewChild('generateInstallmentsModal')
  generateInstallmentsModal:TemplateRef<any>;
  generateInstallmentsModalRef;

  @ViewChild("referenceRecord")
  referenceRecord:ReferenceComponent;


  @ViewChild('convertActivityModal')
  convertActivityModal:TemplateRef<any>;
  convertActivityModalRef;
  convertActivityMode=0; //0== singola, 1=multipla
  convertActivityDeadlines=[];
  convert:Activities={} as Activities;




  @Input()
  viewMode="calendar";

  @Input()
  showFooter=true;

  @Input()
  id_address:number=0;

  @Input()
  table:string="installations";

  @Input()
  id_table:number=0;

  @Input()
  reference:any;

  @Input()
  filter_box:boolean=true;

  @Input()
  toolbar_action:boolean=true;
  
  @Input()
  no_dateend:boolean=false;

  @Input()
  id_installation:number=0;

  @Input()
  id_contract:number=0;

  @Input()
  amount:number=0;

  @Input()
  tax:number=0;

  @Input()
  methodpayment:string="";


  @Input()
  date:string;

  @Input()
  date_from: Date=new Date();

  @Input()
  date_to: Date=new Date();

  

  @Input()
  id_type:string="";
  

  @Input()
  default_filterdate=(this.mode!='embedded')?1:100;


 

  generate:generateInstallment;
  tab=1;
  
  where_join="";
  
  today=new Date;
  type_products:Type[];
  type_deadlines:Type[];
  status_deadlines:Type[];
  users:User[];
  type_activities:Type[];
 

  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_id_type:Filter=new Filter();
  filter_id_type_product:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  filter_status_address:Filter=new Filter();
  filter_installation:Filter=new Filter();
  filter_contract:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_method_payment:Filter=new Filter();
  
  isFilterSet:boolean=false;
  
  
  
  methodpayments=[];

  constructor(
    private fb1: UntypedFormBuilder,
    private deadlinesService: DeadlinesService,
    private typesService: TypesService,
    private userService:UserService,
    private modalService: NgbModal,
    private methodpaymentsService:MethodpaymentsService,
    private activitiesService:ActivitiesService


  ) {
    super(deadlinesService,fb1);
    this.detailViewModal=DeadlineDetailsComponent;
   }

  ngOnInit(): void {

    this.title = 'Agenda';
    super.ngOnInit.apply(this, arguments); 
   
   
    this.methodpaymentsService.getMethods().subscribe((items_m)=>{
      this.methodpayments=items_m;
    });
    
  this.typesService.getTypes("deadlines").subscribe((items)=>{
    this.type_deadlines=items;
  });

  this.typesService.getTypes("installations").subscribe((items)=>{
    this.type_products=items;
  });


  this.typesService.getTypes("activities").subscribe((items)=>{
    this.type_activities=items;
  });

  this.userService.getUsers().subscribe((items)=>{
    this.users=items;
   });

   
   //TODO da controllare
   /*
   DeadlinesModule.savingDeadlineEvent.subscribe(()=>{
    this.updateList();
  });*/
  if(Globals.events['savingDeadlineEvent']){
    Globals.events['savingDeadlineEvent'].subscribe(()=>{
      this.updateList();
    });
  }

  if(this.mode!="embedded" && this.viewMode=="table"){
    this.updateList();
  }

  

}

setFilters():void{
  this.filter_status.mode=FilterMode.normal;
  this.filter_status.fields=[];
  this.filter_status.fields.push("d.status");
  this.filter_status.value="1";
  this.filter.push(this.filter_status);

  this.filter_method_payment.mode=FilterMode.contain;
  this.filter_method_payment.fields=[];
  this.filter_method_payment.fields.push("d.methodpayment");
  this.filter_method_payment.value="";
  this.filter.push(this.filter_method_payment);

  this.filter_date.mode=FilterMode.date;
  this.filter_date.fields=[];
  this.filter_date.fields.push("d.date");
  
  if(!this.filter_box){
    if(!this.no_dateend){

      

      
        this.date_from=this.today;
        this.date_to.setDate(this.today.getDate()+30);

        this.filter_date.value=Helper.convertDateControl(this.date_from);
        this.filter_date.value2=Helper.convertDateControl(this.date_to);
      
    }


  }
  this.filter.push(this.filter_date);


  this.filter_id_type_product.mode=FilterMode.normal;
  this.filter_id_type_product.fields=[];
  this.filter_id_type_product.fields.push("i.id_type");
  this.filter_id_type_product.value="";
  
  this.filter_id_type.mode=FilterMode.normal;
  this.filter_id_type.fields=[];
  this.filter_id_type.fields.push("d.id_type");
  this.filter_id_type.value=this.id_type;
  this.filter.push(this.filter_id_type);
  
  this.filter_table.mode=FilterMode.normal;
  this.filter_table.fields=[];
  this.filter_table.fields.push("d.table");
  this.filter_table.value=this.table;
  this.filter.push(this.filter_table);
  
  this.filter_status_address.mode=FilterMode.normal;
  this.filter_status_address.fields=[];
  this.filter_status_address.fields.push("addr.status");
  this.filter_status_address.value="1";
  this.filter.push(this.filter_status_address);
  
  this.filter_id_address.mode=FilterMode.normal;
  this.filter_id_address.fields=[];
  this.filter_id_address.fields.push("d.id_address");
  this.filter_id_address.value="";
  this.filter.push(this.filter_id_address);
  
  this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("addr.name");
    this.filter_search.fields.push("d.description");
    
    this.filter_search.value="";
    this.filter.push(this.filter_search);
    //this.aux_parameter=this.filter_search.value;

    if(this.id_contract>0){

      this.filter_contract.mode=FilterMode.custom;
      this.filter_contract.fields=[];
      this.filter_contract.value="(d.id_table="+this.id_contract.toString()+" AND d.table='contracts') OR (d.id_table IN (SELECT id FROM contractsinstallments WHERE id_contract="+this.id_contract.toString()+") AND d.table='contractsinstallments')";
      this.filter.push(this.filter_contract);
    }

    if(this.id_installation>0){

      this.filter_installation.mode=FilterMode.custom;
      this.filter_installation.fields=[];
      this.filter_installation.value="(d.id_table="+this.id_installation.toString()+" AND d.table='installations') OR (d.id_table=(SELECT id_contract FROM contractsinstallations WHERE id_installation="+this.id_installation.toString()+") AND d.table='contracts')";
      this.filter.push(this.filter_installation);
    }

  this.ordering.mode=OrderingMode.ascendent;  
  this.ordering.field="d.date";
  if(this.mode!="embedded" && this.mode !="modal")
    Globals.navigation.getStateFilters(this.constructor.name,this.filter);



  this.isFilterSet=true;
}

ngOnChanges():void{
  if(this.id_table>0 || this.id_installation>0 || this.id_contract>0 || this.id_address>0 ) //avvia la ricerca quando il filtro è attivo
    this.updateList();
}


updateList():void{
    if(this.mode=="embedded"){ //non avviare la ricerca fino a quando non si è impostato l'id_table
      if((this.id_table==0 || this.id_table==undefined) && (this.id_address==0 || this.id_address==undefined))
        return;
    }

    if(!this.isFilterSet)
      this.setFilters();

    if(this.id_table>0 && this.table!=""){
      this.filter_table.value=this.table;
      this.filter_id_table.value=this.id_table.toString();
      
    }

    if(this.date){
      this.filter_date.value=this.date;
      this.filter_date.value2=this.date;
    }

    if(this.id_address>0){
      this.filter_id_address.value=this.id_address.toString();
    }

    this.aux_parameter=this.filter_search.value;
    this.getItems();
  
  
}



  /*
  add():void{
  
      this.openDetail();
  }
  */

  
  
  

  openGenerateInstallments(){
    this.generate={} as generateInstallment;
    this.generate.start=Helper.convertDateControl();
    this.generate.end=Helper.convertDateControl();
    this.generate.amount=this.amount;
    this.generate.tax=Globals.parameters.get("general").getParam("default_tax",0);
    this.generate.gross=this.generate.amount*(1+this.generate.tax/100);
    this.generate.amount_type=30;
    this.generate.type=1;
    this.generate.typedetail=30;
    this.generate.reference=this.reference;
    this.generate.table=this.table;
    this.generate.id_table=this.id_table;
    this.generate.id_address=ReferenceComponent.getIdAddress(this.generate.table,this.generate.reference);

    
    this.generateInstallmentsModalRef=this.modalService.open(this.generateInstallmentsModal);
  }

  closeGenerateInstallments(){
    this.generateInstallmentsModalRef.close("success");
  }


  generateInstallments(){
        
    if(this.generate.typedetail==-1){
      if(this.generate.params=="" || this.generate.params==undefined || this.generate.params<-1 || this.generate.params>31){
        alert("Specificare un giorno del mese valido per la generazione delle rate");
        return;
      }
    }

      Globals.setLoading(true);
      this.deadlinesService.generateInstallments(
        Helper.convertDateControl(
          Helper.convertString2Date(this.generate.start.toString())
        ),
        Helper.convertDateControl(
          Helper.convertString2Date(this.generate.end.toString())
        ),
        this.generate.amount,
        this.generate.tax,
        this.generate.amount_type,
        this.generate.type,
        this.generate.typedetail,
        this.generate.id_type,
        this.generate.id_table,
        this.generate.table,
        this.generate.description,
        this.generate.id_address,
        this.generate.params
     ).subscribe((items)=>{
        //this.record.installments=items;
        this.closeGenerateInstallments();
        Globals.setLoading(false);
        this.getItems();

      });


  }

  calculateGrossGenerate(){
    this.generate.gross=this.generate.amount*(1+this.generate.tax/100);
  }

  calculateNetGenerate(){
    this.generate.amount=this.generate.gross/(1+this.generate.tax/100);
  }

  setReferenceToGenerate(r):void{
    this.generate.reference=r;
    this.generate.table=r.table;
    this.generate.id_table=r['id'];

    this.generate.id_address=ReferenceComponent.getIdAddress(r.table,this.generate.reference);
    
   
  }

  convertDeadlinesIntoActivities(){
    this.convert={} as Activities;

    this.convertActivityDeadlines=[];

    for(let id_deadline of this.form.value.id){
      for(let r of this.list){
        if(r['id']==id_deadline){
          this.convertActivityDeadlines.push(r);
        }
      }
    }


    this.convertActivityMode=1;
  
    this.convert.status=1;
    this.convert.id_user=Globals.user.id;
    this.convertActivityModalRef=this.modalService.open(this.convertActivityModal);
  }



  confirmActivity():void{
    if(this.convertActivityMode==0){ //singola conversione
      
      this.activitiesService.save(this.convert,(id)=>{
        
        /*let deadline={} as Deadlines;
        deadline=this.convert['deadline'];
        deadline.id_activity=id;
        this.deadlinesService.save(deadline,(id)=>{*/
          this.closeConvertActivity();
          this.getItems();
        //},false);
        
          
      });
    }else{ //conversione multipla
      let activities=[];
      for(let r of this.convertActivityDeadlines){
        let a={} as Activities;
        a.date=r.date;
        a.description=r.description;
        a.note=this.convert.note;
        a.id_type=this.convert.id_type;
        a.id_table=r.id_table;
        a.table=r.table;
        a.status=1;
        a.id_user=this.convert.id_user;
        a.id_address=r.id_address;
        a.amount=r.amount;
        a.tax=r.tax;
        a['deadline']=r;
        activities.push(a);

      }

      this.activitiesService.saveMultiple(activities,()=>{
        this.closeConvertActivity();
        this.getItems();
      });
    }
  }

  closeConvertActivity(){
    this.convertActivityModalRef.close("success");
  }

  assignFinancialAccount(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
  
    
  
    //apri la scheda dei conti contabili
    Globals.modal.showModal(FinancialaccountComponent,[{"name":"showOnlyTree","value":true}],(instance)=>{
      if(instance!=null){
        let id_financialaccount=instance['nodeSelected'].id;
        //aggiorna i valori
        let item={};
        item['id_financialaccount']=id_financialaccount;
  
        let ids=[];
        for(let id of this.form.value.id){
          ids.push(id);
        }
  
        this.deadlinesService.updateAny(item,ids,(result)=>{
          this.updateList();
          Globals.message.showToaster("Conti contabili assegnati ai record selezionati",ToastMode.INFO);
        });
      }
      
    });
  
  
  }


  setTypeAmountOut(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
  
     //aggiorna i valori
     let item={};
     item['type_amount']=2;

     let ids=[];
     for(let id of this.form.value.id){
       ids.push(id);
     }

     this.deadlinesService.updateAny(item,ids,(result)=>{
       this.updateList();
       Globals.message.showToaster("Tutti i record sono stati impostati come movimento di uscita",ToastMode.INFO);
     });
       
  }

  setTypeAmountIn(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
  
     //aggiorna i valori
     let item={};
     item['type_amount']=1;

     let ids=[];
     for(let id of this.form.value.id){
       ids.push(id);
     }

     this.deadlinesService.updateAny(item,ids,(result)=>{
       this.updateList();
       Globals.message.showToaster("Tutti i record sono stati impostati come movimento di ingresso",ToastMode.INFO);
     });
       
  }


  updateWhereJoin(){
      if(this.filter_id_type_product.value==""){
        this.where_join="";
      }else{
        this.where_join="i.id_type="+this.filter_id_type_product.value;
      }
      this.calendar.where_join=this.where_join;
      if(this.calendar.modeView==1)
        this.calendar.getItems();
      else
        this.calendar.getItemsByMonth();
  }
}
