<div class="modal-header bg-light">
    <b>Verifica dei documenti inviati</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancel()" >
          <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body" style="max-height:80vh; overflow-y:auto">
    <div class="my-5 text-center" *ngIf="loading">
        <i class="fas fa-spinner fa-spin fa-2x mb-2"></i><br>
        <i class="fas fa-cloud-download-alt mr-2"></i>Scaricamento informazioni in corso...<br>La procedura potrebbe impiegare diversi minuti!<br>
        <button type="button" class="btn btn-outline-secondary mt-3" (click)="cancel()" >Annulla</button>
    </div>
    <ng-container *ngIf="!loading">
        <table class="table">
            <thead>
                <th><small>ID documento</small></th>
                <th class="py-1 text-center"><small>Numero</small></th>
                <th class="py-1 text-center"><small>Data</small></th>
                <th class="py-1 text-center"><small>Cliente</small></th>
                <th class="py-1 text-center"><small>Totale</small></th>
            
                <th class="py-1 text-center"><small>Stato nel DB</small></th>
                <th class="py-1 text-center"><small>Stato sullo SdI</small></th>
                <th class="py-1 text-center"><small>Aggiornato</small></th>
                <th class="py-1 text-center"><small>Identificativo SDI</small></th>
                
            </thead>
            <tr *ngFor="let record of list">
                <td class="py-1">
                    <small *ngIf="record.id==0">
                        <div class="badge badge-warning">Non presente</div>
                    </small>    
                    <ng-container *ngIf="record.id>0">
                        {{record.id}}
                    </ng-container>
                </td>
                <td class="py-1 text-center"><b>{{record.number}}</b></td>
                <td class="py-1 text-center"><b>{{record.date | date:"d-M-Y"}}</b></td>
                <td class="py-1 text-center"><b>{{record.name}}</b></td>
                <td class="py-1 text-center"><b>{{record.total | currency:"EUR"}}</b></td>
                
                
                <td class="py-1 text-center">
                    <small *ngIf="record.old_status==1"><i class="text-warning fa fa-clock mr-2"></i>(Inviata)</small>
                    <small *ngIf="record.old_status==2"><i class="text-success fa fa-check mr-2"></i>(Consegnata)</small>
                    <small *ngIf="record.old_status==4"><i class="text-primary fa fa-check mr-2"></i>(Confermata)</small>
                    <small *ngIf="record.old_status==3"><i class="text-danger fa fa-exclamation-triangle mr-2"></i>(Errore)</small>
                </td>
                <td class="py-1 text-center">
                    <small *ngIf="record.new_status==1"><i class="text-warning fa fa-clock mr-2"></i>(Inviata)</small>
                    <small *ngIf="record.new_status==2"><i class="text-success fa fa-check mr-2"></i>(Consegnata)</small>
                    <small *ngIf="record.new_status==4"><i class="text-primary fa fa-check mr-2"></i>(Confermata)</small>
                    <small *ngIf="record.new_status==3"><i class="text-danger fa fa-exclamation-triangle mr-2"></i>(Errore)</small>
                </td>
                <td class="py-1 text-center">
                    <span *ngIf="!record.change && record.new_status==3">
                        <span *ngIf="!record.change && record.new_status==3" class="badge badge-warning">No</span>
                        <i title="Il documento risulta regolarmente consegnato con un successivo invio." class="fas fa-question ml-2"></i>
                    </span>
                    <span *ngIf="record.change" class="badge badge-success">Sì</span>
                </td>
                <td class="py-1 text-center">
                    {{record.sdi_invoice_new}}
                </td>
            </tr>
        </table>
        <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
            <i class="icon fas fa-info-circle mr-2"></i><i>Nessuna fattura da visualizzare</i>
        </div>
    </ng-container>
</div>