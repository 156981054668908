import { NgModule } from '@angular/core';
import { AddressComponent } from './address.component';
import { AddressDetailsComponent } from './address-details/address-details.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { AdvancedsearchModule } from '../../modules/advancedsearch/advancedsearch.module';
import { AddressRoutingModule } from './address-routing.module';
import { ContactModule } from '../../modules/contact/contact.module';
import { CashFlowModule } from '../cash-flow/cash-flow.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { ShopsModule } from '../shops/shops.module';
import { DocumentsModule } from '../documents/documents.module';
import { ContractsModule } from '../contracts/contracts.module';
import { NoteboxModule } from '../../modules/notebox/notebox.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { DeadlinesModule } from '../deadlines/deadlines.module';
import { NationsModule } from 'projects/core/src/common/nations/nations.module';
import { ActivitiesModule } from '../activities/activities.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { TaxCodeModule } from '../../modules/tax-code/tax-code.module';
import { VatNumberModule } from '../../modules/vat-number/vat-number.module';
import { NotificationlogsModule } from '../../modules/notificationlogs/notificationlogs.module';
import { ProductsModule } from '../products/products.module';
import { FilesModule } from '../../modules/files/files.module';
import { Globals } from 'projects/core/src/globals';
import { FinancialaccountModule } from '../../modules/financialaccount/financialaccount.module';
import { WindowlistbuttonsModule } from 'projects/core/src/common/windowlistbuttons/windowlistbuttons.module';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        AddressRoutingModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        ToolbarmodelModule,
        PaginationModule,

        CitiesModule,
        AdvancedsearchModule,
        ContactModule,
        NoteboxModule,
        CashFlowModule,
        ShopsModule,
        ProductsModule,
        DocumentsModule,
        ContractsModule,
        DeadlinesModule,
        ActivitiesModule,
        NationsModule,
        TaxCodeModule,
        VatNumberModule,
        NotificationlogsModule,
        FilesModule,
        FinancialaccountModule,
        WindowlistbuttonsModule
    
    ],
   exports: [AddressComponent,AddressDetailsComponent],
   declarations: [AddressComponent,AddressDetailsComponent],
   providers: [],
})
export class AddressModule{ 

    constructor(){
        Globals.parameters.loadModulesEvent.subscribe((result)=>{
            Globals.parameters.createParam(
                "address",
                "mode_balance",
                "Modalità calcolo bilancio",
                "select",
                [
                    {"value":0,"label":"Nessun calcolo"},
                    {"value":1,"label":"Calcola il bilancio come differenza tra versato ed importo"},
                    {"value":2,"label":"Calcola il bilancio come somma di tutti i movimenti di ingresso e uscita"}
                ]
            );
        });

        Globals.parameters.loadModulesEvent.subscribe((result)=>{
            Globals.parameters.createParam(
                "address",
                "mode_view",
                "Modalità di visualizzazione predefinita",
                "select",
                [
                    {"value":0,"label":"A card"},
                    {"value":1,"label":"A lista"}
                ]
            );
        });
    }
}