<ng-container *ngIf="product">
    <small title="ID prodotto" class="text-primary mr-1">({{product.id}})</small>
    <span title="Tipologia prodotto" class="badge badge-primary mr-1">{{product.type}}</span>
    <span title="Codice interno prodotto" *ngIf="product.codeinternal" class="badge border">{{product.codeinternal}}</span>
    <i class="fas fa-desktop text-info ml-2" title="Scheda web" *ngIf="product.title"></i>
    <br/>
    <span (click)="selected()" class="text-secondary">
        {{product.brand}} <strong>{{product.name}}</strong><div *ngIf="sn" title="Seriale" class="badge badge-secondary ml-2">{{sn}}</div>
    </span>
    <i (click)="showSerials(product)"  title="Visualizza seriali" *ngIf="product.has_serials" class="text-secondary fas fa-barcode ml-2 pointer"></i>
    <i (click)="openProduct(product.id)"  title="Dettagli prodotto" class="text-secondary fas fa-pencil-alt ml-2 pointer"></i>
    <i *ngIf="product.stocks && product.stocks.length>0 && product.stockable" (click)="openInventories()"  title="Stato magazzino" class="text-secondary fas fa-boxes ml-2 pointer"></i>
    <br/>
    <small title="Categoria prodotto" class="text-info">{{product.category}}</small><br/>

    <div *ngIf="showNotes && product && product.state_description && product.state_description!='' && product.state_reference.id!=id_document && product.stockable && isInventoriesEnable" class="badge badge-sm badge-warning">
        <!-- <i class="fas fa-truck"></i> -->
        {{product.state_description}}
        <ng-container *ngIf="product.state_reference && product.state_reference.id>0">
            |<span class="badge">Stato:</span>
            <app-statedocument [state]="product.state_reference.state"></app-statedocument>
            <i (click)="openDocument(product.state_reference);" title="Dettagli sull'ordine" class="fas fa-chevron-right ml-1 pointer"></i>
        </ng-container>
    </div>
</ng-container>


<ng-template #modalDocument let-modal >
    <div class="modal-header bg-light">
        <h5 class="mb-0">Dettagli documento</h5>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body" *ngIf="document">
        <div class="row">
            <div class="col-lg-5"><label>Documento:</label></div>
            <div class="col-lg-7">
                <div *ngIf="document.typedocument==1"  title="Fattura"><span class="badge bg-olive">FS</span> Fattura</div>
                <div *ngIf="document.typedocument==2"  title="Fattura accompagnatoria"  ><span class="badge bg-teal">FA</span> Fattura accompagnatoria</div>
                <div *ngIf="document.typedocument==3"  title="Nota di credito" ><span class="badge bg-pink">NC</span> Nota di <span *ngIf="document.id_type==1">credito</span><span *ngIf="document.id_type==2">debito</span></div>
                <div *ngIf="document.typedocument==4"  title="Preventivo"><span class="badge bg-pink">PR</span> Preventivo</div>
                <div *ngIf="document.typedocument==5"  title="Documento di trasporto" ><span class="badge badge-secondary">DDT</span> Documento di trasporto</div>
                <div *ngIf="document.typedocument==6"  title="Scontrino" ><span class="badge badge-secondary">SC</span> Scontrino</div>
                <div *ngIf="document.typedocument==7"  title="Ordine cliente" ><span class="badge badge-secondary">OC</span> Ordine cliente</div>
                <div *ngIf="document.typedocument==8"  title="Ordine fornitore" ><span class="badge badge-secondary">OF</span> Ordine fornitore</div>
                <div *ngIf="document.typedocument==9"  title="Non fiscale" ><span class="badge badge-secondary">NF</span> Non fiscale</div>
                <div *ngIf="document.typedocument==10"  title="Trasferimento" ><span class="badge bg-secondary">TR</span> Trasferimento</div>
                <div *ngIf="document.typedocument==11"  title="Documento Amministrativo"  ><span class="badge bg-secondary">FV</span> Amministrativo</div>
                <div *ngIf="document.typedocument==12"  title="Fattura di acconto"  ><span class="badge bg-teal">FA</span> Fattura di acconto</div>
                <div *ngIf="document.typedocument==13"  title="Inventario"  ><span class="badge bg-secondary">INV</span> Inventario</div>
            </div>
            <div class="col-lg-5"><label>Numerazione:</label></div>
            <div class="col-lg-7">{{document.reference}}</div>
            <div class="col-lg-5"><label>Data:</label></div>
            <div class="col-lg-7">{{document.date | date:'dd-MM-yyyy'}}</div>
            <div class="col-lg-5"><label>Ultimo aggiornamento:</label></div>
            <div class="col-lg-7"><app-statedocument [state]="document.last_state"></app-statedocument> al {{document.last_state_update | date:'dd-MM-yyyy'}}</div>
        </div>
    </div>
</ng-template>


<ng-template #modalInventories let-modal >
    <div class="modal-header bg-light">
        <h5 class="mb-0">Stato Magazzino</h5>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body" *ngIf="product">
        <table class="table table-sm"> 
            <thead>
                <th>
                    Magazzino
                </th>
                <th>
                    Disponibilità
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let s of product.stocks">
                    <td>{{s.inventoryname}}</td>
                    <td [class.text-success]="s.stock>0" [class.text-danger]="s.stock<0">{{s.stock}} {{product.unit}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>TOTALE</td>
                    <td [class.text-success]="product.stock>0" [class.text-danger]="product.stock<0"><strong>{{product.stock}} {{product.unit}}</strong></td>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>