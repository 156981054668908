import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { CashflowService } from '../../../services/cashflow.service';
import { ModelList } from 'projects/core/src/include/modellist'
import { CashFlow } from '../../../db/cashflow';
import { UntypedFormBuilder} from '@angular/forms';
import { TypesService } from '../../../services/types.service';
import { Type } from '../../../db/type';
import { Filter,FilterMode, OrderingMode, ToastMode, User } from 'projects/core/src/include/structures'
import { Globals } from 'projects/core/src/globals';
import { UserService } from 'projects/core/src/lib/user.service';
import { CashFlowDetailsComponent } from './../cash-flow-details/cash-flow-details.component';
import { ShopsService } from '../../../services/shops.service';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { DocumentsDetailsComponent } from '../../documents/documents-details/documents-details.component';
import { DocumentDetail } from '../../../db/documents';
import { FinancialAccountsService } from '../../../services/financialaccounts.service';
import { FinancialaccountComponent } from '../../../modules/financialaccount/financialaccount.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow-list.component.html',
  styleUrls: ['./cash-flow-list.component.css'],
})

export class CashFlowComponent extends ModelList<CashFlow> implements OnInit {
  
  
  @Input()
  id_address:number=0;
  
  @Input()
  id_table:number=0;

  @Input()
  table:string="";

  @Input()
  filterbox:boolean=true;
  
  @Input()
  deleteInRow:boolean=false;

  @Input()
  reference:any;

  @Output()
  afterLoaded:EventEmitter<void>=new EventEmitter();

  date_from: Date=new Date();
  date_to: Date=new Date();
  today=new Date;
  date_type:number=1;

  @Input()
  modeShow=0; //definisce se mostrare solo le attività dell'anno corrente o meno

  status_cashflow:Type[];

  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_id_type:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_method_payment:Filter=new Filter();  
  filter_id_address:Filter=new Filter();
  filter_id_user:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_created_from:Filter=new Filter();
  filter_source:Filter=new Filter();
  filter_financialaccount:Filter=new Filter();  

  total_amount:number=0;
  total_paid:number=0;
  total_difference:number=0;

  total_amount_in:number=0;
  total_paid_in:number=0;
  total_amount_out:number=0;
  total_paid_out:number=0;

  total_amount_future:number=0;
  total_paid_future:number=0;
  total_difference_future:number=0;
  
  users:User[];
  
  isFilterSet:boolean=false;
  shops=[];
  methodpayments=[];
  sources=[];
  financialaccounts=[];

  
  constructor(
    private fb1: UntypedFormBuilder,
    private cashflowService: CashflowService,
    private typesService: TypesService,
    private userService:UserService,
    private shopsService:ShopsService,
    private methodpaymentsService:MethodpaymentsService,
    private financialAccountsService:FinancialAccountsService


    
  ) {
    super(cashflowService,fb1);
    this.detailViewModal=CashFlowDetailsComponent;
    this.modulename="cash-flow";
   }


  ngOnInit(): void {

    super.ngOnInit.apply(this, arguments); 
    this.title = 'Movimenti';
    
    if(this.mode=="embedded"){
      this.modeShow=parseInt(this.module.getParam("default_modeview",0));
    }


    const default_address=Globals.parameters.get("general").getParam("default_address");
    this.sources=this.module.getParam("sources",[]);
    if(this.filterbox){
      this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
        this.shops=items;
      });

      this.methodpaymentsService.getMethods().subscribe((items_m)=>{
        this.methodpayments=items_m;
      });

      this.financialAccountsService.getAccounts().subscribe((items_c)=>{
        this.financialaccounts=items_c;
      });

      this.userService.getUsers().subscribe((items)=>{
        this.users=items;
      });
    }
   

    this.afterGetItems=(()=>{
     

      //calcolo i valori totali
      if(this.list.length>0){
        this.cashflowService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{
          if(item){
            this.total_amount=item.amount;
            this.total_difference=item.paid-item.amount;
            this.total_paid=item.paid;

            this.total_amount_future=item.amount_future;
            this.total_difference_future=item.paid_future-item.amount_future;
            this.total_paid_future=item.paid_future;

            this.total_amount_in=item.amount_in;
            this.total_paid_in=item.paid_in;
            this.total_amount_out=item.amount_out;
            this.total_paid_out=item.paid_out;
            
          }
          this.afterLoaded.emit();
        });
      }
      /*
      for(let r of this.list){
        if(r.id_type==2){
          r.amount=r.amount*-1;
          r.paid=r.paid*-1;
        }

      
      }*/
    });
    
   
  }

  setFilters():void{
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("a.name");
    this.filter_search.fields.push("c.description");
    this.filter_search.fields.push("c.method_payment");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("c.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_source.mode=FilterMode.normal;
    this.filter_source.fields=[];
    this.filter_source.fields.push("c.source");
    this.filter_source.value="";
    this.filter.push(this.filter_source);

    this.filter_financialaccount.mode=FilterMode.contain;
    this.filter_financialaccount.fields=[];
    this.filter_financialaccount.fields.push("c.id_financialaccount");
    this.filter_financialaccount.value="";
    this.filter.push(this.filter_financialaccount);

    this.filter_created_from.mode=FilterMode.normal;
    this.filter_created_from.fields=[];
    this.filter_created_from.fields.push("c.created_from");
    if(this.user.isAdmin()){
      this.filter_created_from.value="";
    }else{
      this.filter_created_from.value=this.user['shop']?this.user['shop']['id']:"";
    }
   
    this.filter.push(this.filter_created_from);

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("c.date");
    this.filter.push(this.filter_date);
    
    
   

    if(this.id_table>0){
      this.filter_id_table.mode=FilterMode.normal;
      this.filter_id_table.fields=[];
      this.filter_id_table.fields.push("c.id_table");
      this.filter_id_table.value=this.id_table.toString();
      this.filter.push(this.filter_id_table);

      this.filter_table.mode=FilterMode.normal;
      this.filter_table.fields=[];
      this.filter_table.fields.push("c.table");
      this.filter_table.value=this.table.toString();
      this.filter.push(this.filter_table);
    }


    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("c.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_method_payment.mode=FilterMode.normal;
    this.filter_method_payment.fields=[];
    this.filter_method_payment.fields.push("c.method_payment");
    this.filter_method_payment.value="";
    this.filter.push(this.filter_method_payment);
    
    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("c.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);
    
    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("c.id_user");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);

   
    this.ordering.mode=OrderingMode.discendent;
    this.ordering.field="c.date";
    
    if(this.mode=="embedded"){
      this.paging_count=10000000;
    }

   

    this.isFilterSet=true;
  }

  ngOnChanges():void{
    if(this.id_address>0 || this.id_table>0) //avvia la ricerca quando il filtro è attivo
      this.updateList();
  }
  

  updateList():void{
    if(this.mode=="embedded"){ //non avviare la ricerca fino a quando non si è impostato l'id_table
      if((this.id_table==0 || this.id_table==undefined) && (this.id_address==0 || this.id_address==undefined))
        return;
    }


    if(!this.isFilterSet)
      this.setFilters();
      
    

    this.total_amount=0;
    this.total_difference=0;
    this.total_paid=0;
    if(this.id_address>0){
      this.filter_id_address.value=this.id_address.toString();
    }

    if(this.id_table>0){
      this.filter_id_table.value=this.id_table.toString();
      this.filter_table.value=this.table;
    }

    this.getItems();
  }

  
  createDocument(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
    
    let document=this.list.filter(x=>x.id==this.form.value.id[0]);
    let details=[];



    for(let id of this.form.value.id){
      let c=this.list.filter(x=>x.id==id);
      let d:DocumentDetail=new DocumentDetail();
      d.id=0;
      d.description=c[0]['description'];
      d.id_product=this.module.getParam("id_product_document",0);
      d.netprice=c[0]['amount'];
      d.tax=c[0]['tax'];
      d.discount1=0;
      d.discount2=0;
      d.discount3=0;
      d.quantity=1;
      details.push(d);

    }
    
    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_address","value":document[0]['id_address']},
      {"name":"details","value":details}
    ],()=>{

    });


  }

  

  duplicate(record) {

    // record.description = record.description+'_copia';
    const date_now: Date = new Date();  
    record.date = formatDate(date_now,'yyyy-MM-dd',"en-EN");
    super.duplicate.apply(this, arguments);

  }


  assignFinancialAccount(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }    

    //apri la scheda dei conti contabili
    Globals.modal.showModal(FinancialaccountComponent,[{"name":"showOnlyTree","value":true}],(instance)=>{
      if(instance!=null){
        let id_financialaccount=instance['nodeSelected'].id;
        //aggiorna i valori
        let item={};
        item['id_financialaccount']=id_financialaccount;

        let ids=[];
        for(let id of this.form.value.id){
          ids.push(id);
        }

        this.cashflowService.updateAny(item,ids,(result)=>{
          this.updateList();
          Globals.message.showToaster("Conti contabili assegnati ai record selezionati",ToastMode.INFO);
        });
      }
      
    });


  }

  isShowed(cashflow:CashFlow){
    if(this.modeShow==1){ //mostra solo quelle di quest'anno
      if(new Date(cashflow.date).getFullYear()==new Date().getFullYear())
        return true;
      else
        return false;
    }

    if(this.modeShow==2){ //mostra solo quelle di quest'anno
      //calcola 365 in meno ad oggi
      let d365=  new Date();
      d365.setDate(d365.getDate()-365);
      
      if(new Date(cashflow.date)>d365)
        return true;
      else
        return false;
    }


    return true;
  }
}
