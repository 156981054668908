<app-windowlist [model]="this">
    <div class="card">
        <div class="row p-1">
            <div class="col-lg-4">
                <app-selecttables [name]="'filter_type'" [value]="filter_type.value" [show_all]="true" (onchange)="filter_type.value=$event;getItems()"></app-selecttables>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <button class="btn btn-success btn-sm float-right" data-toggle="modal" data-target="#modalRule" (click)="addAction()"><i class="fa fa-plus"></i></button>
            </div>
        </div>
        <div class="card-body p-0">
            <form>
            <table class="table table-sm table-striped text-nowrap m-0" >
                <thead>
                    <th>ID</th>
                    <th>Nome</th>
                    <th></th>
                </thead>
                <tbody>

                    <!-- elemento ripetuto -->
                    <tr *ngFor="let record of list">
                        <td>{{record.id}}</td>
                        <td class="pl-2">
                            <i  title="Abilitato" *ngIf="record.status==1" class="fa fa-check text-success"></i>
                            <i  title="Disabilitato" *ngIf="record.status==0" class="fas fa-times text-danger"></i>
                            {{record.name}}
                        </td>
                        <td class="pr-2">
                            <button  type="button"  title="Elimina" class="btn btn-danger btn-xs float-right ml-1"  (click)="confirm_delete(record.id)">
                                <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button type="button"  title="Duplica" class="btn btn-info btn-xs float-right ml-1" (click)="duplicate(record)">
                                <i class="far fa-clone"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button type="button"  title="Modifica" class="btn btn-secondary btn-xs float-right ml-1"  data-toggle="modal" data-target="#modalRule" (click)="setRule(record)">
                                <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </td>
                    </tr>

                </tbody>
            </table>
            </form>
        </div>
        <div class="card-footer clearfix py-1">
            <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small>
            <!-- <app-pagination [model]="this" ></app-pagination> -->
        </div>
    </div>
</app-windowlist>

    <div class="modal fade" id="modalRule" tabindex="-1" role="dialog" >
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <b>Dettaglio azione</b>
                </div>
                <div class="modal-body" *ngIf="selectedAction">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="form-group form-group-sm">
                                <label>Nome</label>
                                <input class="form-control form-control-sm" type="text" name="name" [(ngModel)]="selectedAction.name"/>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-sm">
                                <label>Stato</label>
                                <select name="status" class="form-control form-control-sm" name="status" [(ngModel)]="selectedAction.status">
                                    <option value="0">Non pubblicato</option>
                                    <option value="1">Pubblicato</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group form-group-sm">
                                <label>Tabella</label>
                                <app-selecttables [name]="'table'" [value]="selectedAction.table" [show_all]="false" (onchange)="selectedAction.table=$event;"></app-selecttables>
                            </div>
                        </div>
                    </div>
                    <h5 class="mt-2 mb-0">Condizioni</h5>
                    <small>Indicare le condizioni che si devono verificare per applicare l'azione</small>
                    <table class="table table-sm table-head-fixed  text-nowrap m-0">
                        <thead>
                            <th>Campo</th>
                            <th></th>
                            <th>Valore</th>
                            <th><button type="button" class="btn btn-xs float-right btn-success" (click)="addCondition()"><i class="fa fa-plus"></i> </button></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of selectedAction.conditionsArray">
                                <td><input type="text" class="form-control form-control-sm" [(ngModel)]="c.field"/></td>
                                <td>
                                    <select class="form-control form-control-sm" [(ngModel)]="c.condition">
                                        <option value="ugual">=</option>
                                        <option value="major">maggiore</option>
                                        <option value="minor">minore</option>
                                    </select>
                                </td>
                                <td><input type="text" class="form-control form-control-sm" [(ngModel)]="c.value"/></td>
                                <td><button type="button" class="btn btn-xs float-right btn-danger" (click)="removeCondition(c)"><i class="fa fa-trash"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                    <h5 class="mt-4">Azione</h5>
                    <div  class="row" *ngIf="selectedAction.actionDetail">
                        <div class="col-lg-6">
                            <div class="form-group form-group-sm">
                                <label>Tipologia di azione</label>
                                    <select class="form-control form-control-sm" [(ngModel)]="selectedAction.actionDetail.action">
                                        <option value="INSERT">Inserimento nuovo record</option>
                                        <option value="UPDATE">Aggiornamento record</option>
                                        <option value="EDIT">Apertura scheda di dettaglio</option>
                                    </select>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group form-group-sm">
                                <label>Tabella su cui effettuare il salvataggio</label>
                                <app-selecttables [name]="'table'" [value]="selectedAction.actionDetail.table" [show_all]="true" (onchange)="selectedAction.actionDetail.table=$event;getItems()"></app-selecttables>
                               
                            </div>
                        </div>
                        <div class="col-lg-12" *ngIf="selectedAction.actionDetail.action=='INSERT' || selectedAction.actionDetail.action=='UPDATE'">
                            <div class="form-group form-group-sm">
                                <label>Campi e valori da salvare</label>
                                <table class="table table-sm table-head-fixed text-nowrap m-0" >
                                    <thead>
                                        <th>Campo</th>
                                        <th>Valore</th>
                                        <th><button type="button" class="btn btn-xs float-right btn-success" (click)="addActionDetail()"><i class="fa fa-plus"></i> </button></th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let c of selectedAction.actionDetail.fields">
                                            <td><input type="text" class="form-control form-control-sm" [(ngModel)]="c.name"/></td>
                                            <td><input type="text" class="form-control form-control-sm" [(ngModel)]="c.value"/></td>
                                            <td><button type="button" class="btn btn-xs float-right btn-danger" (click)="removeActionDetail(c)"><i class="fa fa-trash"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-12" *ngIf="selectedAction.actionDetail.action=='EDIT'">
                            <div class="form-group form-group-sm">
                                <label>ID Funzione personalizzata</label>
                                <input type="text" class="form-control form-control-sm" name="id_function" [(ngModel)]="selectedAction.actionDetail.id_function">

                            </div>

                            <div class="form-group form-group-sm">
                                <label>Argomenti Funzione personalizzata</label>
                                <input type="text" class="form-control form-control-sm" name="params" [(ngModel)]="selectedAction.actionDetail.params">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmRule()"><i class="fas fa-save mr-1"></i>Conferma</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Annulla</button>
                </div>
            </div>
        </div>
    </div>


