import { Component, Input, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { DocumentsService } from '../../../services/documents.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';




@Component({
  selector: 'app-fatturaelettronica',
  templateUrl: './fatturaelettronica.component.html',
  styleUrl: './fatturaelettronica.component.css'
})
export class FatturaelettronicaComponent implements OnInit {
  
  @ViewChild("frame")
  frame;

  @Input()
  id_document:number=0;

  url:string="";
  url_xml:string="";

  message=[];

  constructor(
    private documentsService:DocumentsService,
    public sanitizer:DomSanitizer,
    private httpClient:HttpClient
    ){

  }
  
  ngOnInit(): void {

    this.documentsService.createXSL(this.id_document).subscribe((uri)=>{
      this.url=uri;
    });
    this.createXML();

  }

  close(){
    this['modalWindow'].close();
  }

  checkXML(){
    this.documentsService.checkXMLEInvoice([this.id_document]).subscribe((result)=>{
      if(result){
        try{
        this.message=JSON.parse(result[0]['sdi_message']);
        }
        catch{
          this.message=[];
        }
      }
    });
  }

  createXML(){
    this.documentsService.createXML(this.id_document).subscribe((uri)=>{
      this.url_xml=uri;
      this.checkXML();
    })
  }

  downloadPreview(){
    
    window.open(this.url,"_blank");
   
   
   
  }
  

}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 