import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, OrderingMode } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { CashRegister } from '../../../db/cashregister';
import { CashregisterService } from '../../../services/cashregister.service';
import { ShopsService } from '../../../services/shops.service';
import { CashregisterDetailComponent } from './cashregister-detail/cashregister-detail.component';

@Component({
  selector: 'app-cashregister',
  templateUrl: './cashregister.component.html',
  styleUrls: ['./cashregister.component.css']
})
export class CashregisterComponent extends ModelList<CashRegister> implements OnInit,AfterViewInit {
  
  
  @Input()
  filterbox:boolean=true;


  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_status:Filter=new Filter();
  filter_id_user:Filter=new Filter();
  filter_created_from:Filter=new Filter();

  total_amount:number=0;
  total_paid:number=0;
  isFilterSet:boolean=false;
  shops=[];


  constructor(
    private fb1: UntypedFormBuilder,
    private cashregiserService: CashregisterService,
    private userService:UserService,
    private shopsService:ShopsService,
  ) { super(cashregiserService,fb1);
    this.detailViewModal=CashregisterDetailComponent;
  }

  ngOnInit(): void {
    super.ngOnInit.apply(this, arguments); 
    this.title = 'Stato cassa';

    

   
   
  }

  ngAfterViewInit(): void {
    const default_address=Globals.parameters.get("general").getParam("default_address");

    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
    });
  }


  setFilters():void{
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("c.description");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("c.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_created_from.mode=FilterMode.normal;
    this.filter_created_from.fields=[];
    this.filter_created_from.fields.push("c.created_from");
    if(this.user.isAdmin()){
      this.filter_created_from.value="";
    }else{
      this.filter_created_from.value=this.user['shop']?this.user['shop']['id']:"";
    }
    this.filter.push(this.filter_created_from);

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("c.date");
    this.filter.push(this.filter_date);
    
    
   

    

   
   
    
    
    
    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("c.id_user");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);
    /*
    if(this.mode!="embedded")
      Globals.navigation.getStateFilters(this.constructor.name,this.filter);
    */

    this.ordering.mode=OrderingMode.discendent;
    this.ordering.field="c.date";
    
    if(this.mode=="embedded"){
      this.paging_count=10000000;
    }

   

    this.isFilterSet=true;
  }

  ngOnChanges():void{
      this.updateList();
  }


  updateList():void{
    if(this.mode=="embedded"){ //non avviare la ricerca fino a quando non si è impostato l'id_table
        return;
    }


    if(!this.isFilterSet)
      this.setFilters();
      
    

    this.total_amount=0;
    this.total_paid=0;
    
    this.getItems();
  }

}
