<div class="modal-header">
    Ritiro prodotto seriale
</div>
<div class="modal-body">
    <table class="table">
        <thead>
            <th>Da ritirare</th>
            
            <th></th>
            <th></th>
        </thead>
        <tbody *ngFor="let p of activity.user_action['products']">
            <tr>
                <td>
                    {{p.oldproduct}}
                    <div *ngIf="!p.isexist" class="badge badge-danger">Seriale non presente nel database</div>
                    <ng-container *ngIf="p.isexist && p.product">
                        <br/>
                        <small>
                            {{p.product.category}}<br/>
                            <b>{{p.product.brand}} {{p.product.model}}</b>
                        </small>
                    </ng-container>
                    <!--<input type="text" class="form-control form-control-sm" [(ngModel)]="p.oldproduct">-->
                </td>
                
                <td>
                    <button class="btn btn-sm btn-danger" (click)="removeProduct(p)"><i class="fa fa-trash"></i></button>
                </td>
                <td>
                    <ng-container *ngIf="p.status_action || p.status_action==false">
                        <span class="text-success" *ngIf="p.status_action"><i class="fa fa-check"></i></span>
                        <span class="text-danger" *ngIf="!p.status_action"><i class="fa fa-times"></i></span>
                        
                        {{p.message}}
                    </ng-container>
                </td>
            </tr>
        </tbody>
        
    </table>
    <button class="btn btn-sm btn-block btn-success" (click)="addProduct()"><i class="fa fa-plus"></i>&nbsp;Aggiungi</button>
    <button class="btn btn-sm btn-block btn-success" (click)="addProductQrCode()"><i class="fa fa-qrcode"></i>&nbsp;Scansiona</button>


    

   
    
    <div class="form-group">
        <label>Note</label>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="activity.user_action.note" >
    </div>

    
</div>
<div class="modal-footer" *ngIf="mode=='modal'">
    <button class="btn btn-secondary" (click)="cancel()">Annulla</button>
    <button class="btn btn-success" (click)="confirm()">Conferma</button>
</div>

