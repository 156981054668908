<div class="row my-1">
    <div  class="col-12 col-lg-2 pr-lg-0">
        <small>
            <app-filterdate [date_type]="100" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getAnalytics()"></app-filterdate>
            <app-treecategories [class]="'card p-1 mt-lg-2'" [multiple]="true" (id_selected)="id_category=$event;getAnalytics()" [showAll]="true" [id]="id_category" [showCountProduct]="false" [editButton]="false" #tree [list]="categories"></app-treecategories>
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item pointer" [class.active]="id_type==0" (click)="id_type=0;getAnalytics()">Tutte le tipologie</li>
                <li *ngFor="let t of types" class="list-group-item pointer" [class.active]="id_type==t.id"  (click)="id_type=t.id;getAnalytics()">{{t.name}}</li>
                <div class="icon"><i class="fas fa-list"></i></div>
            </ul>
            <!--
            <ul class="card small-box list-group p-1 my-2">
                <li class="list-group-item pointer" [class.active]="filter_difference==0" (click)="filter_difference=0;getAnalytics()">Tutti</li>
                <li class="list-group-item pointer" [class.active]="filter_difference==1" (click)="filter_difference=1;getAnalytics()">Positivi</li>
                <li class="list-group-item pointer" [class.active]="filter_difference==-1" (click)="filter_difference=-1;getAnalytics()">Negativi</li>
                <div class="icon"><i class="fas fa-ruler-horizontal"></i></div>
            </ul>
            -->
        </small>
    </div>
    <div class="col-lg-10">
        <div class="card card-outline">
            <div class="p-0">
                <div class="row">
                    <div class="col-lg-9">
                    </div>
                    <div class="col-lg-3">
                        <button  (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i></button>
                    </div>
                </div>
            </div>
            <div class="row">
            </div>
            <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
            </div>
            <form *ngIf="list && list.length>0" [formGroup]="form">
                <!--
                <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                    <thead>
                        <th>Prodotto</th>
                        <th class="text-center">Carico</th>
                        <th class="text-center">Scarico</th>
                        <th class="text-center">Carico <small>(manuale)</small></th>
                        <th class="text-center">Scarico <small>(manuale)</small></th>
                        <th class="text-center">Giacenza <small>(teorica)</small></th>
                        <th class="text-center">Giacenza <small>(reale)</small></th>
                        <th class="text-center">Differenze</th>
                        <th class="text-right">Valore</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of list" [class.alert-success]="calculateTeoricalStock(r)-calculateRealStock(r)==0">
                            <td>
                                <small>
                                    <span class="text-primary mr-1" title="ID prodotto">({{r.id_product}})</span>
                                    <span class="badge bg-secondary" title="Codice interno">{{r.codeinternal}}</span>
                                </small><br/>
                                {{r.brand}} <b>{{r.name}}</b>
                                &nbsp;<i class="fa fa-pencil-alt pointer" (click)="openProduct(r.id_product)" title="Dettaglio prodotto"></i>
                                <ng-container >
                                    &nbsp;<i class="fa fa-boxes pointer" (click)="openHistory(r.id_product)" title="Stato magazzino"></i>
                                </ng-container><br>
                                <small class="text-info" title="Categoria di prodotto">{{r.category}}</small>
                            </td>
                            <td class="text-center">{{r.total_load_document}}</td>
                            <td class="text-center">{{r.total_unload_document}}</td>
                            <td class="text-center">{{r.total_load}}</td>
                            <td class="text-center">{{r.total_unload}}</td>
                            <td class="text-center">{{r.theorical_stock}}</td>
                            <td class="text-center">{{r.real_stock}}</td>
                            <td class="text-center">{{r.difference}}</td>
                            <td class="text-right">{{r.difference_grossvalue | currency:"&euro; "}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="8"><b> TOTALE</b></td>
                            <td class="text-right"><b>{{total_difference_grossvalue | currency:"&euro; "}}</b></td>
                        </tr>
                    </tfoot>
                </table>-->

                <table class="table table-nowrap table-sm table-head-fixed table-hover m-0 text-sm">
                    <thead>
                        <th>ID</th>
                        <th>Codice</th>
                        <th>Prodotto</th>
                        <th>Categoria</th>
                        <th class="text-center">Carico</th>
                        <th class="text-center">Scarico</th>
                        <th class="text-center">Giacenza<br>teorica</th>
                        <th class="text-center">Giacenza<br>reale</th>
                        <th class="text-center">Costo</th>
                        <th class="text-center">Vendita</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of list">
                            <td>{{r.id_product}}</td>
                            <td><span class="badge border">{{r.codeinternal}}</span></td>
                            <td>{{r.brand}} <b>{{r.name}}</b><i (click)="openProduct(r.id_product)" title="Dettagli Profotto" class="fa fa-pencil-alt ml-2"></i></td>
                            <td>{{r.category}}</td>
                            <td class="text-center">{{r.total_load_document}}</td>
                            <td class="text-center">{{r.total_unload_document}}</td>
                            <td class="text-center">{{r.total_remain_document}}</td>
                            <td class="text-center">{{r.stock}}</td>
                            <td class="text-center">{{r.total_cost | currency:"EUR"}}</td>
                            <td class="text-center">{{r.total_sell | currency:"EUR"}}</td>
                        </tr>
                    </tbody>
                    
                </table>
            </form>
        </div>
    </div>
</div>