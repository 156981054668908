<ul class="small-box list-group {{class}}">
    <li class="list-group-item border pt-1 mb-1" [class.active]="date_type==99">
        <div *ngIf="title"><b>{{title}}</b></div>
        <div class="input-group input-group-sm ">
            <div class="input-group-prepend input-group-prepend-sm d-none d-xl-flex text-center">
                <span *ngIf="period" style="width: 1.5rem;" (dblclick)="filter_date.value='2000-01-01';date_type=99;update()"><small >Dal</small></span>
            </div>
            <input style="height: 1rem;" class="form-control form-control-sm border-0" title="Selezionare la data di inizio ricerca" type="date" [(ngModel)]="filter_date.value" name="date_from" (focusout)="date_type=99;" (key.enter)="date_type=99;update()" >
            <div class="input-group-append">
                <span [class.text-light]="date_type==99" class="btn p-0 btn-white" title="Cerca solo questo giorno" (click)="filter_date.value2=filter_date.value;date_type=99;update()"><i class="fas fa-search"></i></span>
            </div>
        </div>
        <div class="input-group input-group-sm border-top" *ngIf="period">
            <div class="input-group-prepend input-group-prepend-sm d-none d-xl-flex text-center">
                <span style="width: 1.5rem;" (dblclick)="filter_date.value2='2999-12-31';date_type=99;update()"><small>Al</small></span>
            </div>
            <input style="height: 1rem;" class="form-control form-control-sm border-0" title="Selezionare la data di fine ricerca" type="date" [(ngModel)]="filter_date.value2"  name="date_to" (focusout)="date_type==99;" (key.enter)="date_type=99;update()">
            <div class="input-group-append">
                <span [class.text-light]="date_type==99" class="btn p-0 btn-white" title="Cerca periodo" (click)="date_type=99;update()"><i class="fas fa-search-plus"></i></span>
            </div>
        </div>
    </li>
    <li class="list-group-item pointer" [class.active]="date_type==100" (click)="setDate(100)">Tutto</li>
    <li class="list-group-item pointer" *ngIf="past" [class.active]="date_type==6" (click)="setDate(6)">Fino ad oggi</li>
    <li class="list-group-item pointer" *ngIf="past" [class.active]="date_type==3 || date_type==4 || date_type==5">Ultimi&ensp;
        <span [class.text-warning]="date_type==3" (click)="setDate(3)">7 gg</span>&ensp;/&ensp;
        <span [class.text-warning]="date_type==4" (click)="setDate(4)">30 gg</span>&ensp;/&ensp;
        <span [class.text-warning]="date_type==5" (click)="setDate(5)">90 gg</span>
    </li>
    <!-- <li class="list-group-item pointer" *ngIf="past" [class.active]="date_type==5" (click)="setDate(5)">Ultimi 90 gg</li>
    <li class="list-group-item pointer" *ngIf="past" [class.active]="date_type==4" (click)="setDate(4)">Ultimi 30 gg</li>
    <li class="list-group-item pointer" *ngIf="past" [class.active]="date_type==3" (click)="setDate(3)">Ultimi 7 gg</li> -->
    <li class="list-group-item pointer" [class.active]="date_type==1 || date_type==2 || date_type==7">
        <span *ngIf="past"><span [class.text-warning]="date_type==2" (click)="setDate(2)">Ieri</span>&ensp;/&ensp;</span>
        <span [class.text-warning]="date_type==1" (click)="setDate(1)" >Oggi</span>
        <span *ngIf="future">&ensp;/&ensp;<span [class.text-warning]="date_type==7" (click)="setDate(7)">Domani</span></span>
    </li>
    <!-- <li class="list-group-item pointer" *ngIf="past" [class.active]="date_type==2" (click)="setDate(2)">Ieri</li>  
    <li class="list-group-item pointer" [class.active]="date_type==1" (click)="setDate(1)">Oggi</li>
    <li class="list-group-item pointer" *ngIf="future" [class.active]="date_type==7" (click)="setDate(7)">Domani</li> -->
    <li class="list-group-item pointer" *ngIf="future" [class.active]="date_type==8 || date_type==9 || date_type==10">Prossimi&ensp;
        <span [class.text-warning]="date_type==8" (click)="setDate(8)">7 gg</span>&ensp;/&ensp;
        <span [class.text-warning]="date_type==9" (click)="setDate(9)">30 gg</span>&ensp;/&ensp;
        <span [class.text-warning]="date_type==10" (click)="setDate(10)">90 gg</span>
    </li>
    <!-- <li class="list-group-item pointer" *ngIf="future" [class.active]="date_type==8" (click)="setDate(8)">Fino a 7 gg</li>
    <li class="list-group-item pointer" *ngIf="future" [class.active]="date_type==9" (click)="setDate(9)">Fino a 30 gg</li>
    <li class="list-group-item pointer" *ngIf="future" [class.active]="date_type==10" (click)="setDate(10)">Fino a 90 gg</li> -->
    <li class="list-group-item pointer" *ngIf="future" [class.active]="date_type==11" (click)="setDate(11)">Tutti i prossimi giorni</li>
    <li class="list-group-item pointer" [class.active]="date_type==97" (click)="date_type==97"><span (click)="setDate(97)">Mese<i *ngIf="date_type!=97" class="fas fa-chevron-down float-right mr-1"></i></span>
        <div *ngIf="date_type==97">
            <select class="form-control form-control-sm" [(ngModel)]="month" name="month" (change)="setDate(97);">
                <option [ngValue]="0">gennaio</option>
                <option [ngValue]="1">febbraio</option>
                <option [ngValue]="2">marzo</option>
                <option [ngValue]="3">aprile</option>
                <option [ngValue]="4">maggio</option>
                <option [ngValue]="5">giugno</option>
                <option [ngValue]="6">luglio</option>
                <option [ngValue]="7">agosto</option>
                <option [ngValue]="8">settembre</option>
                <option [ngValue]="9">ottobre</option>
                <option [ngValue]="10">novembre</option>
                <option [ngValue]="11">dicembre</option>
            </select>
        </div>
    </li>
    <li class="list-group-item pointer" [class.active]="date_type==96" (click)="date_type==96"><span (click)="setDate(96)">Trimestre<i *ngIf="date_type!=96" class="fas fa-chevron-down float-right mr-1"></i></span>
        <div *ngIf="date_type==96">
            <select class="form-control form-control-sm" [(ngModel)]="trimestre" name="trimestre" (change)="setDate(96);">
                <option  [ngValue]="1">primo</option>
                <option  [ngValue]="2">secondo</option>
                <option  [ngValue]="3">terzo</option>
                <option  [ngValue]="4">quarto</option>
            </select>
        </div>
    </li>
    <li class="list-group-item pointer" [class.active]="date_type==98" (click)="date_type==98"><span (click)="setDate(98)">Anno<i *ngIf="date_type!=98" class="fas fa-chevron-down float-right mr-1"></i></span>
        <div *ngIf="date_type==98">
            <select class="form-control form-control-sm" [(ngModel)]="year" name="year" (change)="setDate(98);">
                <option *ngFor="let y of years" [ngValue]="y">{{y}}</option>
            </select>
        </div>
    </li>
    <div class="icon"><i class="far fa-calendar-alt"></i></div>
</ul>
