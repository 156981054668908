<app-windowlist [model]="this">
    <div class="row">
        <ng-container *ngIf="viewMode==1">
            <div class="col-lg-12">
                <div class="m-3">
                    <i class="fa-2x fas fa-info text-info mr-3"></i>
                    <b>ATTENZIONE</b><br>
                    <small>
                        <i>La tariffa scelta dal sistema sarà quella che avrà il <b>numero d'ordine inferiore</b>, fra quelle <b>valide</b> al momento della prenotazione, e che <b>verificano le condizioni</b> imposte.</i>
                    </small>
                </div>
                <div class="card card-outline bg-light mb-0">
                    <div class="p-0">
                        <form [formGroup]="form" *ngIf="list!=null">
                            <table class="table table-sm table-striped">
                                <thead>
                                    <th>
                                        <div #checkboxselectall class="float-left icheck-primary d-inline ml-1 mr-2">
                                            <input  type="checkbox" title="Seleziona tutti"
                                            (change)="onChangeAll($event.target.checked)" />
                                        </div>
                                        Nome
                                    </th>
                                    <th>Validità</th>
                                    <th>Condizioni</th>
                                    <th class="text-center">Ordinamento</th>
                                    <th>
                                        <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" type="button"  title="Cestina" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                                        <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" type="button"  title="Elimina"  class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                                        <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" type="button"  title="Ripristina"  class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                                        <button  (click)="export()"  type="button"  title="Esporta" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                                        <!-- <button type="button"  title="Elimina" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash-alt"></i></button> -->
                                        <button type="button"  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button>
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of list;let i=index">
                                        <td>
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" class="m-1" [checked]="checkedAll"
                                            />&nbsp;
                                            <a (click)="openDetail(record)" class="pointer"><b>{{record.name}}</b></a><br>
                                            <!-- <small class="ml-3" *ngIf="record.typeprice==1"><i>Tariffa indipendente dalla durata (€)</i></small>
                                            <small class="ml-3" *ngIf="record.typeprice==2"><i>Tariffa basata sulla durata (€/ora)</i></small> -->
                                        </td>
                                        <td>
                                            <small>dal</small> {{record.published_start | date:'dd/MM/yyyy'}}<br>
                                            <small>al</small> {{record.published_end | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            <span *ngIf="record.type==1">
                                                <small class="badge bg-gray">in base agli orari</small><br>
                                                <small>dalle ore </small>{{record.time_start.substr(0,5)}}<small> alle ore </small>{{record.time_end.substr(0,5)}}
                                            </span>
                                            <small *ngIf="record.type==2">
                                                <small class="badge bg-gray">in base alla durata</small><br>
                                                minimo <b>{{record.duration}} <span *ngIf="record.duration==1">ora</span><span *ngIf="record.duration>1">ore</span></b><br>
                                                minimo <b>{{record.min_partecipants}} pax</b>
                                            </small>
                                        </td>
                                        <td class="text-center">
                                        <span (dblclick)="record['editOrdering']=true" *ngIf="!record['editOrdering']" class="pointer" title="Doppio Click per modifica">{{record.ordering}}</span> 
                                            <div class="input-group input-group" *ngIf="record['editOrdering']">
                                                <input type="number" name="{{i}}_ordering" [(ngModel)]="record.ordering">
                                                <div class="input-group-append">
                                                    <button type="button" class="btn btn-success btn-xs" (click)="updateOrdering(record);" title="Conferma"><i class="fa fa-check"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1"><i class="fas fa-pencil-alt"></i></button>
                                            <button type="button" (click)="duplicate(record)"  title="Duplica" class="btn btn-info btn-xs float-right ml-1"><i class="far fa-clone"></i></button>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </form>
                    </div>

                    <div class="card-footer clearfix py-1">
                        <app-pagination [model]="this" ></app-pagination>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="viewMode==2">
            <div *ngIf="user.isBackendUser()" class="col-12">
                <div class="card alert-warning p-2 mb-0">
                    <small>
                        <i class="fa-2x fas fa-info mx-3"></i>
                        <i><b>RICORDA:</b> La tariffa scelta dal sistema sarà quella che avrà il <b>numero d'ordine inferiore</b>, fra quelle <b>valide</b> al momento della prenotazione, e che <b>verificano le condizioni</b> imposte!</i>
                    </small>
                </div>
            </div>
            <div *ngIf="!user.isBackendUser()" class="m-3">
                <i class="fa-2x fas fa-info text-info mr-3"></i>
                <b>ATTENZIONE</b><br>
                <small>
                    <i>Scegli il <b>luogo di prenotazione</b> e (se vuoi) una <b>data di prenotazione</b>, per avere la lista dei nostri prezzi aggiornata.</i>
                </small>
            </div>
            <div class="col-lg-12">
                <div class="card card-outline bg-light">
                    <div class="p-0">
                        <div class="row p-1">
                            <div class="col-lg-3">
                                <div class="input-group input-group-sm">
                                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="cerca per nome tariffa..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                    <div class="input-group-append ">
                                        <button type="button" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                        <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="input-group input-group-sm" >
                                    <select class="custom-select" [(ngModel)]="filter_id_shop">
                                        <ng-container *ngFor="let s of shops">
                                            <option *ngIf="s.city" [ngValue]="s.id">{{s.name}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="input-group input-group-sm" >
                                    <input type="date" class="form-control form-control-sm" [(ngModel)]="filter_published_start.value" (change)="filter_published_end.value=filter_published_start.value;">
                                </div>
                            </div>
                            <div class="col-lg-3" *ngIf="user.isBackendUser()">
                                <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" type="button"  title="Cestina" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                                <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" type="button"  title="Elimina"  class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                                <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" type="button"  title="Ripristina"  class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                                <button  (click)="export()"  type="button"  title="Esporta" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                                <!-- <button type="button"  title="Elimina" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash-alt"></i></button> -->
                                <button type="button"  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button>
                            </div>
                        </div>

                        <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                            <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                            <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                        </div>
                        <form *ngIf="list && list.length>0" [formGroup]="form">
                            <div style="width:100%;overflow-x:auto">
                            <table class="table table-sm table-striped">
                                <thead>
                                    <th>
                                        <div *ngIf="user.isBackendUser()" #checkboxselectall class="float-left icheck-primary d-inline ml-1 mr-2">
                                            <input  type="checkbox" title="Seleziona tutti"
                                            (change)="onChangeAll($event.target.checked)" />
                                        </div>
                                        Nome
                                    </th>
                                    <th class="text-center border" *ngFor="let t of tags" style="max-height:120px;writing-mode: vertical-rl;vertical-align:middle;"><small><b>{{getTagLabel(t)}}</b></small></th>
                                    <th>Validità</th>
                                    <th *ngIf="user.isBackendUser()" class="text-center">Ordine</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of list">
                                        <td>
                                            <input *ngIf="user.isBackendUser()" type="checkbox" #checkrecord id_record="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" class="m-1" [checked]="checkedAll"
                                            />&nbsp;
                                            <b>{{record.name}}</b>
                                            <span class="text-gray float-right">
                                                <i *ngIf="record.typeprice==1">(€)</i>
                                                <i *ngIf="record.typeprice==2">(€/ora)</i>
                                            </span><br>
                                            <div class="ml-4">
                                                <span *ngIf="record.type==1">
                                                    <!-- <small class="badge bg-gray">in base agli orari</small><br> -->
                                                    <small>dalle ore </small>{{record.time_start.substr(0,5)}}<small> alle ore </small>{{record.time_end.substr(0,5)}}
                                                </span>
                                                <small *ngIf="record.type==2">
                                                    <!-- <small class="badge bg-gray">in base alla durata</small><br> -->
                                                    min <b>{{record.duration}} <span *ngIf="record.duration==1">ora</span><span *ngIf="record.duration>1">ore</span></b>
                                                    <span *ngIf="record.max_duration!=0"> max <b>{{record.max_duration}} ore</b></span><br>
                                                    min <b>{{record.min_partecipants}} pax</b>
                                                </small>
                                            </div>
                                        </td>
                                        <td *ngFor="let t of tags" class="text-center pt-4 border">
                                            <div *ngIf="getTagPrice(record,filter_id_shop,t)!='nd'" class="bg-primary text-white text-center m-auto"
                                                style="height:35px; width:35px; padding:7px 0; border-radius:50%;">
                                                {{getTagPrice(record,filter_id_shop,t)}}</div>
                                            <span *ngIf="getTagPrice(record,filter_id_shop,t)=='nd'"><i>nd</i></span>
                                        </td>
                                        <td>
                                            <small>dal</small> {{record.published_start | date:'dd/MM/yyyy'}}<br>
                                            <small>al</small> {{record.published_end | date:'dd/MM/yyyy'}}<br>
                                            <div class="badge border text-success" *ngIf="(record.published_start | date: 'yMMdd') < (currentDate | date: 'yMMdd') && (record.published_end | date: 'yMMdd') > (currentDate | date: 'yMMdd')">IN CORSO</div>
                                        </td>
                                        <td *ngIf="user.isBackendUser()" class="text-center">
                                            <span (dblclick)="enableOrderingEditMode(record)" *ngIf="!record['editOrdering']" class="pointer" title="Doppio Click per modifica">{{record.ordering}}</span> 
                                                <div class="input-group input-group" *ngIf="record['editOrdering']">
                                                    <input type="number" class="form-control form-control-sm" name="{{i}}_ordering" [(ngModel)]="record.ordering">
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-secondary btn-sm" (click)="updateOrdering(record);" title="Ordina"><i class="fas fa-sort-numeric-down"></i></button>
                                                    </div>
                                                </div>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="user.isBackendUser()">
                                                <button type="button" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1"><i class="fas fa-pencil-alt"></i></button>
                                                <button type="button" (click)="duplicate(record)"  title="Duplica" class="btn btn-info btn-xs float-right ml-1"><i class="far fa-clone"></i></button>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer clearfix py-1">
                        <app-pagination [model]="this" ></app-pagination>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</app-windowlist>
