import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { UntypedFormBuilder} from '@angular/forms';
import { TypesService } from '../../../../services//types.service';
import { ProductcategoriesService } from '../../../../services/productcategories.service';
import { Type } from '../../../../db/type';
import { Filter,FilterMode } from 'projects/core/src/include/structures'

import { InstallationsService } from '../../../../services/installations.service';
import { Installation } from '../../../../db/installation';
import { ProductCategories } from '../../../../db/productcategories';
import { NotificationService } from '../../../../services/notification.service';
import { Globals } from 'projects/core/src/globals';
import { UploadFileComponent } from 'projects/core/src/common/upload-file/upload-file.component';
import { ToastMode } from 'projects/core/src/include/structures';
import { SerialsDetailsComponent } from './../serials-details/serials-details.component';
import { ShopsComponent } from '../../../shops/shops.component';
import { DocumentsDetailsComponent } from '../../../documents/documents-details/documents-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomfieldsService } from 'projects/core/src/lib/customfields.service';
import { ContractsDetailComponent } from '../../../contracts/contracts-detail/contracts-detail.component';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { AddressDetailsComponent } from '../../../address/address-details/address-details.component';
import { ShopsDetailsComponent } from '../../../shops/shops-details/shops-details.component';

@Component({
  selector: 'app-serials-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class SerialListComponent extends ModelList<Installation> implements OnInit {

  @ViewChild("modalImportValue")
  modalImportValue:TemplateRef<any>;
  modalImportValueRef;
  modalImportValueStep=1;

  @Input()
  id_product:number=0;

  @Input()
  id_address:number=0;

  @Input()
  id_shop:number=0;

  modulename="installations";
  
  shops=[];

  type_product:Type[];
  type_shops:Type[];
  type_deadlines:Type[];
  categories_product:ProductCategories[];
  status_product:Type[];
  filter_search:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_type:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_id_shop:Filter=new Filter();
  filter_category:Filter=new Filter();
  filter_shop_id_type:Filter=new Filter();
  filter_id_reseller:Filter=new Filter();
  filter_id_product:Filter<number>=new Filter<number>();
  filter_qrcode:Filter=new Filter();
  filter_contracts:Filter=new Filter();
  filter_contracts_type=0; //0= non applicare il filtro; 1= applica il filtro trovando tutti i seriali con contratto; 2= applica il filtro trovando tutti i seriali senza contratto
  filter_deadline:Filter=new Filter();
  filter_deadline_type=0; //0= non applicare il filtro; 1= applica il filtro trovando tutti i seriali con agenda; 2= applica il filtro trovando tutti i seriali senza agenda
  filter_deadline_id_type_deadline=0; //tiopologia di agenda
  isFilterSet=false;
  id_category_log=0;


  id_customfield_to_update=0;
  customfields=[];
  value_customfield_to_update="";
  value_customfield_updated=[];

  instock=0;

  get table_value_customfield(){
    let s=this.value_customfield_to_update.split("\n");
    let t=[];
    for(let o of s){
      let f=o.split(";");
      if(f.length>1)
        t.push({"sn":f[0],"value":f[1]});
    }
    
    return t;
  }

  constructor(
    private fb1: UntypedFormBuilder,
    private installationsService: InstallationsService,
    private typesService: TypesService,
    private productcategoriesService: ProductcategoriesService,
    private notificationService:NotificationService,
    private customfieldService:CustomfieldsService,
    private shopsService:ShopsService,
    private modalService:NgbModal
    
  ) {
    super(installationsService,fb1);
    this.detailViewModal=SerialsDetailsComponent;
   }


  ngOnInit(): void {

    //this.tableColumns.push(new TableColumn("Password richieste","customfields['Password richieste']"));

    this.title='Prodotti con seriali';
    super.ngOnInit.apply(this, arguments); 

    this.id_category_log=this.module.getParam("id_category_log",0);


      this.typesService.getTypes("installations").subscribe((items)=>{
        this.type_product=items;
      });

      this.typesService.getTypes("shops").subscribe((items)=>{
        this.type_shops=items;
      });

      this.typesService.getTypes("deadlines").subscribe((items)=>{
        this.type_deadlines=items;
      });

      

      this.productcategoriesService.getCategories().subscribe((items)=>{
        this.categories_product=items;
      });

      this.customfieldService.getCustomFields("installations").subscribe((items)=>{
        this.customfields=items;
      });

      const default_address=Globals.parameters.get("general").getParam("default_address");

      this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
        this.shops=items;
      })

    

      if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
        this.updateList(this.id_address);


      this.afterGetItems=()=>{
        
        this.instock=0;
        for(let i of this.list){
          this.instock=this.instock+this.getStock(i);
        }
      }

      this.beforeGetItems=()=>{
        this.other_params=["true",this.tableColumns.length>0?"true":"false"];
        this.onChangeFilterContracts();
        this.onChangeFilterDeadlines();
      }
  }


  setFilters():void{
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("pb.name");
    this.filter_search.fields.push("pr.name");
    this.filter_search.fields.push("p.sn");
    this.filter_search.fields.push("p.matricola");
    this.filter_search.fields.push("p.code");
    this.filter_search.fields.push("s.name");
    this.filter_search.fields.push("s.city");
    this.filter_search.fields.push("p.id_product");
    
    
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("a.id");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);

    this.filter_shop_id_type.mode=FilterMode.normal;
    this.filter_shop_id_type.fields=[];
    this.filter_shop_id_type.fields.push("s.id_type");
    this.filter_shop_id_type.value="";
    this.filter.push(this.filter_shop_id_type);
    
    this.filter_id_shop.mode=FilterMode.normal;
    this.filter_id_shop.fields=[];
    this.filter_id_shop.fields.push("p.id_shop");
    this.filter_id_shop.value="";
    this.filter.push(this.filter_id_shop);

    this.filter_id_product.mode=FilterMode.normal;
    this.filter_id_product.fields=[];
    this.filter_id_product.fields.push("p.id_product");
    this.filter_id_product.value=0;
    this.filter.push(this.filter_id_product);
    

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("p.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_id_type.mode=FilterMode.contain;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("p.id_type");
    this.filter_id_type.value=this.module.getParam("default_type","");
    this.filter.push(this.filter_id_type);

    this.filter_category.mode=FilterMode.normal;
    this.filter_category.fields=[];
    this.filter_category.fields.push("pr.id_category");
    this.filter_category.value="";
    this.filter.push(this.filter_category);

    this.filter_id_reseller.mode=FilterMode.major;
    this.filter_id_reseller.fields=[];
    this.filter_id_reseller.fields.push("p.id_reseller");
    this.filter_id_reseller.value="";
    this.filter.push(this.filter_id_reseller);

    this.filter_qrcode.mode=FilterMode.normal;
    this.filter_qrcode.fields=[];
    this.filter_qrcode.fields.push("p.code");
    this.filter_qrcode.value="";
    this.filter_qrcode.nullvalue="null";
    this.filter_qrcode.enabled=false;
    this.filter.push(this.filter_qrcode);


    this.filter_contracts.mode=FilterMode.custom;
    this.filter_contracts.fields=[];
    this.filter_contracts.nullvalue="null";
    this.filter_contracts.enabled=false;
    this.filter.push(this.filter_contracts);


    this.filter_deadline.mode=FilterMode.custom;
    this.filter_deadline.fields=[];
    this.filter_deadline.nullvalue="null";
    this.filter_deadline.enabled=false;
    this.filter.push(this.filter_deadline);

    this.advancedFilter=Globals.navigation.getStateFilters(this.constructor.name,this.filter);

    this.ordering.field="p.id";

    this.isFilterSet=true;
  }

  onChangeFilterContracts(){
    if(this.filter_contracts_type==0){
      this.filter_contracts.enabled=false;
    }

    if(this.filter_contracts_type==1){
      this.filter_contracts.enabled=true;
      this.filter_contracts.value="(SELECT COUNT(c.id) FROM contractsinstallations as cp LEFT JOIN contracts as c ON c.id=cp.id_contract WHERE id_installation=p.id AND c.status=1)>0" ;
    
    }

    if(this.filter_contracts_type==2){
      this.filter_contracts.enabled=true;
      this.filter_contracts.value="(SELECT COUNT(c.id) FROM contractsinstallations as cp LEFT JOIN contracts as c ON c.id=cp.id_contract WHERE id_installation=p.id AND c.status=1)=0" ;  
    }
    
  }

  onChangeFilterDeadlines(){
    if(this.filter_deadline_type==0){
      this.filter_deadline.enabled=false;
    }

    
    if(this.filter_deadline_type==1){
      this.filter_deadline.enabled=true;
      this.filter_deadline.value=" (SELECT COUNT(d.id) FROM deadlines as d WHERE d.id_table=p.id AND d.table='installations' AND d.status=1 AND d.date>NOW() AND d.id_type="+this.filter_deadline_id_type_deadline+")>0";
   
    }

    if(this.filter_deadline_type==2){
      this.filter_deadline.enabled=true;
      this.filter_deadline.value=" (SELECT COUNT(d.id) FROM deadlines as d WHERE d.id_table=p.id AND d.table='installations' AND d.status=1 AND d.date>NOW() AND d.id_type="+this.filter_deadline_id_type_deadline+")=0";
    }
    
  }

  showUploadFile(){
    Globals.modal.showModal(UploadFileComponent,[{"name":"subdirectory","value":"qrcode"},{"name":"extensions","value":"zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"},{"name":"description","value":"Carica un file ZIP contenente le immagini QR-CODE dei prodotti associati. Ogni file contenuto nel pacchetto ZIP deve essere nel formato '<matricola> <anagrafica>.jpg'"}],(instance)=>{
      
      if(instance['filename']!=""){
        let filename=instance['filename'];
        Globals.setLoading(true);
        Globals.message.showToaster("Avvio importazione in corso. Il processo potrebbe richiedere molto tempo...",ToastMode.WARNING);
        this.installationsService.linkQRCodeZip(filename).subscribe((result)=>{
          Globals.setLoading(false);
          Globals.message.showToaster("Importati "+result['count'].toString()+" codici. Dispositivi aggiornati:"+result['devices'].join(", "),ToastMode.SUCCESS);
        });
      }
     
      
    });
  }


  ngOnChanges():void{
    if(this.id_address>0 || this.id_shop>0 || this.id_product>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
  }
  
  updateList(id_address:number):void{

    if(!this.isFilterSet)
      this.setFilters();
      
    if(id_address>0){
      this.filter_id_address.value=id_address.toString();
    }
    if(this.id_shop>0){
      this.filter_id_shop.value=this.id_shop.toString();
    }

    if(this.id_product>0){
      this.filter_id_product.value=this.id_product;
    }
    


    

    this.getItems();
  }
 


  notify(record:Installation){

    this.notificationService.showPreviewNotification(record.shop.addressItem.contacts,record,"installations",()=>{

    });
    
    
  }

  

  getStock(record){
    let stock=0;
    let find=false;
    if(!record.serials)
      return 0;
    if(record.serials){
      for(let s of record.serials){
        if(s.sn==record.sn){
          stock=stock+parseFloat(s.avaible.toString());
          if(s.id_inventory==record.shopid_inventory){
            
            find=true;
            
          }
          if(s.id_inventory!=record.shopid_inventory && s.avaible!=0){
            return -1;
          }

        }

        if(find){
          return stock;
        }
      }
    }
    return 0;
  }
  

  moveInstallation(record){
    Globals.modal.showModal(ShopsComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona la nuova ubicazione del prodotto"}],(instance)=>{
      let r=instance['recordSelected'];
      record.shop=r;
      const old_id=record.id_shop;
      record.id_shop=r.id;
      
      this.installationsService.moveInstallation(record.id,old_id,r.id,status,"Spostamento manuale").subscribe(result=>{
        this.save(record);
      });

      

    });
  }


  createDDT(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
    let ids_installations=this.form.value.id;
    
    this.installationsService.getItemsByIDS(ids_installations).subscribe((items)=>{
      let details=[];

      for(let i of items){
        let d={};
        d['id']=0;
        d['id_product']=i.id_product;
        d['product']=i.product;
        d['description']=i.product.brand+" "+i.product.name;
        d['quantity']=1;
        d['unit']=i.product.unit;
        d['sn']=i.sn;

        if(i.last_log && i.product.id_category==this.id_category_log)
          d['note']="Ultimo chilometraggio registrato: "+i.last_log.km+ "Km";
        details.push(d);
        
      }

      Globals.modal.showModal(DocumentsDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"type_document","value":5},
        {"name":"details","value":details}
        
      ],()=>{
  
        });

    });


   

  }


  openModalImportValue(){
    this.modalImportValueStep=1;
    this.value_customfield_to_update="";
    if(this.customfields.length>0)
      this.id_customfield_to_update=this.customfields[0].id;

    this.modalImportValueRef=this.modalService.open(this.modalImportValue);
  }

  closeModalImportValue(){
    this.modalImportValueRef.close("success");
  }

  importCustomField(){
    this.closeModalImportValue();
  }

  prepareImportvalue(){
    Globals.setLoading(true);
    this.installationsService.prepareImportvalue(this.value_customfield_to_update,this.id_customfield_to_update,(items)=>{
      

      for(let v of items){
        if(v.value>v.current){
          v.update=true;
        }else{
          v.update=false;
        }
      }

      this.value_customfield_updated=items;

      this.modalImportValueStep=2;
      Globals.setLoading(false);
    });
  }


  updateImportvalue(){
    Globals.setLoading(true);
    this.installationsService.updateImportvalue(this.value_customfield_updated,this.id_customfield_to_update,(items)=>{
      this.closeModalImportValue();
      Globals.message.showToaster("Aggiornamento completato correttamente!",ToastMode.SUCCESS);
      Globals.setLoading(false);
    });

  }

  openContract(contract){
    Globals.modal.showModal(ContractsDetailComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":contract.id}
    ],()=>{

    });
  }

  openAddress(address){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":address.id}
    ],()=>{

    });
  }

  openShop(shop){
    Globals.modal.showModal(ShopsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":shop.id}
    ],()=>{

    });
  }
}
