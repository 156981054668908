<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-12 col-lg-9">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item ">
                            <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tabBooking1" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabBooking2" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="fas fa-users"></i><span class="d-none d-sm-inline-block ml-2">Partecipanti <span class="badge border" *ngIf="record.passengers && record.passengers.length>0">{{record.passengers.length}}</span></span></a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabBooking3" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="far fa-id-badge"></i><span class="d-none d-sm-inline-block ml-2">Documenti</span></a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabBooking4" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="fas fa-clock"></i><span class="d-none d-sm-inline-block ml-2">Cronologia</span></a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabBooking5" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="far fa-hdd"></i><span class="d-none d-sm-inline-block ml-2">Files <span class="badge border" *ngIf="record.files && record.files.length>0">{{record.files.length}}</span></span></a>
                        </li>
                    </ul>
        
                    <div class="pt-2" >
                        <div class="tab-content" id="myTabContent">
                            <!-- GENERALE -->
                            <div class="tab-pane fade show active" id="tabBooking1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Cliente</label></div>
                                    <div class="col-lg-10">
                                        <div class="small-box bg-light mb-0">
                                            <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"
                                                [showViewButton]="true"
                                                [showSearchButton]="true"
                                                [recordSelected]="record.addressItem"
                                                [service]="addressesService"
                                                [fieldRender]="'name'"
                                                [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                            <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                                                <small>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <small>
                                                                <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                                    <b>INDIRIZZO:</b><br>
                                                                    {{record.addressItem.address}}<br>
                                                                    {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                                </ng-container>
                                                                <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                                                <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                                            </small>
                                                        </div>
                                                        <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                                            <small>
                                                                <b>CONTATTI:</b><br>
                                                                <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </small>
                                                <div class="icon"><i class="fa fa-user"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label class="help" title="Indicare se presente un intermediario della prenotazione">Intermediario<small>*</small></label></div>
                                    <div class="col-lg-10">
                                        <div class="small-box mb-0 alert-warning">
                                            <app-selectfieldcustom (OnExplore)="selectSupplier()" (OnRecordSelected)="OnSelectSupplier($event)" (OnRecordView)="OnViewSupplier($event)" (OnUnlink)="record.id_supplier=0;record.supplierItem=null;"
                                                [showViewButton]="true"
                                                [showUnlinkButton]="true"
                                                [showSearchButton]="true"
                                                [recordSelected]="record.supplierItem"
                                                [service]="addressesService"
                                                [fieldRender]="'name'"
                                                [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Tipologia</label></div>
                                    <div class="col-lg-3">
                                        <select class="form-control form-control-sm" name="type" [(ngModel)]="record.type">
                                            <option value="1">Noleggio</option>
                                            <option value="2">Tour</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pl-lg-0 text-lg-right"><label><i class="fa fa-male mr-1"></i>Adulti</label></div>
                                    <div class="col-lg-2  ">
                                        <input type="number" min="1" step="1" class="form-control form-control-sm" name="adults" [(ngModel)]="record.adults" />
                                    </div>
                                    <div class="col-lg-1 pl-lg-0 text-lg-right"><label><i class="fa fa-child mr-1"></i>Bambini</label></div>
                                    <div class="col-lg-2 ">
                                        <input type="number" min="0"  step="1" class="form-control form-control-sm" name="children" [(ngModel)]="record.children" />
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="record.type==2">
                                    <div class="col-lg-2"><label>Tour</label></div>
                                    <div class="col-lg-10">
                                        <div class="input-group input-group-sm">
                                            <p class="form-control form-control-sm bg-light text-truncate">
                                                <span *ngIf="record.tour">
                                                    <b>{{record.tour.code}} {{record.tour.type_tour}}</b> - {{record.tour.title}} - {{record.tour.subtitle}}
                                                </span>
                                            </p>
                                            <div class="input-group-append ">
                                                <a class="btn btn-default" (click)="selectTour()">
                                                    <span class="d-none d-sm-inline-block mr-2">Seleziona</span><i class="fas fa-shop"></i>
                                                </a>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Partenza</label></div>
                                    <div class="col-lg-3">
                                        <input type="date"  class="form-control form-control-sm" name="date_start" [(ngModel)]="record.date_start" />
                                        <input type="time"  class="form-control form-control-sm" name="time_start" [(ngModel)]="record.time_start" />
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="input-group input-group-sm">
                                            <input type="text" placeholder="luogo di partenza..." class="form-control form-control-sm" name="location_start" [(ngModel)]="record.location_start" />
                                            <div class="input-group-append">
                                                <span class="input-group-text"><i class="fas fa-map-marker-alt"></i></span>                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Arrivo</label></div>
                                    <div class="col-lg-3">
                                        <input type="date" class="form-control form-control-sm" name="date_end" [(ngModel)]="record.date_end" />
                                        <input type="time"  class="form-control form-control-sm" name="time_end" [(ngModel)]="record.time_end" />
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="input-group input-group-sm">
                                            <input type="text" placeholder="inserisci luogo di arrivo..." class="form-control form-control-sm" name="location_start" [(ngModel)]="record.location_end" />
                                            <div class="input-group-append">
                                                <span class="input-group-text"><i class="fas fa-map-marker-alt"></i></span>                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Note sulla prenotazione</label></div>
                                    <div class="col-lg-10">
                                        <textarea class="form-control form-control-sm" name="note2" [(ngModel)]="record.note2"></textarea>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Magazzino di prelievo</label></div>
                                    <div class="col-lg-10">                                        
                                        <div class="input-group input-group-sm">
                                            <select   class="form-control form-control-sm" name="id_shop_start" [(ngModel)]="record.id_shop_start">
                                                <ng-container *ngFor="let s of shops">
                                                    <option [ngValue]="s.id">{{s.name}}</option>
                                                </ng-container>
                                            </select>
                                            <div class="input-group-append">
                                                <span class="input-group-text"><i class="fa fa-boxes"></i></span>                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Magazzino di restituzione</label></div>
                                    <div class="col-lg-10">
                                        <div class="input-group input-group-sm">
                                            <select   class="form-control form-control-sm" name="id_shop_end" [(ngModel)]="record.id_shop_end">
                                                <ng-container *ngFor="let s of shops">
                                                    <option [ngValue]="s.id">{{s.name}}</option>
                                                </ng-container>
                                            </select>
                                            <div class="input-group-append">
                                                <span class="input-group-text"><i class="fa fa-boxes"></i></span>                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="alert alert-warning text-center" *ngIf="record.date_end==record.date_start && record.time_end==record.time_start">
                                    Attenzione: la durata della prenotazione è nulla. Controllare i dati di prelievo e restituzione !
                                </div> -->

                                <div class="form-group" *ngIf="record.id_shop_start>0">
                                    <div class="card">
                                        <div class="card-header">
                                            <app-searchproduct  [showPrice]="false" [canEdit]="true" [autoSelect]="false"  (onEntrySelected)="add($event)"></app-searchproduct>
                                        </div>
                                        <div class="card-body p-0">
                                            <ul class="list-group text-sm">
                                                <li class="list-group-item border-bottom" *ngFor="let d of record.details;let i=index">
                                                    <div class="row">
                                                        <div class="col-2 text-center px-0">
                                                            <img src="{{d.product.coverImage().imagePath}}" alt="" style="height:60px;max-width: 99%;"/>
                                                        </div>
                                                        <div class="col-10">
                                                            <div class="row">
                                                                <div class="w-100 text-truncate">
                                                                    <b>
                                                                        <ng-container *ngIf="d.description!=''">
                                                                            {{d.description}}
                                                                        </ng-container>
                                                                        <ng-container *ngIf="d.description==''">
                                                                            <ng-container *ngIf="d.product.title==''">
                                                                                <span *ngIf="d.product.brand">{{d.product.brand}} </span>{{d.product.name}}
                                                                            </ng-container>
                                                                            <ng-container *ngIf="d.product.title!=''">
                                                                                {{d.product.title}}
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </b>
                                                                    <!-- <small *ngIf="record.duration>1"> ({{record.duration}} giorni)</small> -->
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-2 px-0 ">
                                                                    <small>Quantità</small>
                                                                    <input type="number" class="form-control form-control-sm" name="quantity_{{i}}" style="height:1.5rem;" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="calculateTotal()" />
                                                                </div>
                                                                <div class="col-2 px-0">
                                                                    <small>Prezzo unitario</small>
                                                                    <input class="form-control form-control-sm" name="price_{{i}}" style="height:1.5rem;" [(ngModel)]="d.price" (keyup)="calculateTotal()" (change)="calculateTotal()" />
                                                                    
                                                                </div>
                                                                <div class="col-2 px-0">
                                                                    <small>Sconto %</small>
                                                                    <input class="form-control form-control-sm" name="discount_{{i}}" style="height:1.5rem;" [(ngModel)]="d.discount" (keyup)="calculateTotal()" (change)="calculateTotal()" />
                                                                </div>
                                                                <div class="col-2 px-0">
                                                                    <small>IVA %</small>
                                                                    <input  class="form-control form-control-sm" name="tax_{{i}}" style="height:1.5rem;" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()" />
                                                                </div>
                                                                <div class="col-3 px-0 text-right">
                                                                    <small><b class="d-none d-md-inline">TOTALE </b>(IVA inclusa)</small>
                                                                    <div class="input-group input-group-sm">
                                                                        <div class="input-group-prepend" *ngIf="d.editGross">
                                                                            <div (click)="d.editGross=false" class="input-group-text bg-success pointer"><i class="fa fa-check"></i></div>
                                                                        </div>
                                                                        <span *ngIf="!d.editGross" class="form-control form-control-sm font-weight-bold text-right py-0" (click)="d.editGross=true" style="height:1.5rem;">{{d.price*d.quantity*(1+d.tax/100)*(1-d.discount/100) | currency:'&euro;'}}</span>
                                                                        <input *ngIf="d.editGross" class="form-control form-control-sm font-weight-bold text-right" min="0" style="height:1.5rem;" value="{{d.price*d.quantity*(1+d.tax/100)*(1-d.discount/100)}}" (change)="calculateNet($event,d)" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-1 px-1 text-right">
                                                                    <!-- <span *ngIf="false" class="badge badge-success mt-3 float-left">150 %</span> -->
                                                                    <a class="btn btn-danger mt-2 btn-sm float-right" type="button"  (click)="remove(d)" title="Elimina" ><i class="fas fa-trash"></i></a>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <!--
                                                        <div [class.col-2]="!d.editGross"  [class.col-4]="d.editGross" class="text-right">



                                                            <h5 (click)="d.editGross=true" *ngIf="!d.editGross">{{d.price*d.quantity*(1+d.tax/100)*(1-d.discount/100)*record.duration | currency:'&euro;'}}</h5>
                                                            <div class="input-group input-group-sm" *ngIf="d.editGross">
                                                                <input type="number" class="form-control form-control-sm" value="{{d.price*d.quantity*(1+d.tax/100)*(1-d.discount/100)}}" (change)="calculateNet($event,d)">
                                                                <div class="input-group-append">
                                                                    <button (click)="d.editGross=false" class="btn btn-success"><i class="fa fa-check"></i></button>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            <br/>
                                                            <button class="btn btn-danger btn-sm" (click)="remove(d)"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                        -->
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>

                            <!-- PARTECIPANTI -->
                            <div class="tab-pane fade" id="tabBooking2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div class="row mb-1">
                                    <div class="col-12">
                                        <button class="btn btn-sm btn-success"  title="Aggiungi" (click)="addPartecipant()"><i class="fa fa-plus mr-2"></i>Aggiungi partecipante</button>
                                    </div>
                                </div>
                                <div class="row border-top py-1" *ngFor="let p of record.passengers;let j=index">
                                    <!--<div class="col-12">
                                        <table class="table">
                                            <thead>
                                                <th>Nome e Cognome</th>
                                                <th>Parametri</th>
                                                <th>Documento</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let p of record.passengers;let j=index">
                                                    <td>
                                                       <input type="text" name="passengersname_{{j}}" class="form-control form-control-sm" [(ngModel)]="p.value">
                                                        
                                                    </td>
                                                    <td>
                                                        {{p.requestparams | json}}
                                                    </td>
                                                    <td>
                                                        <ng-container *ngFor="let l of p.images;let i=index">
                                                            <img class="img-thumbnail" src="{{getImagePath(l)}}"  title="Visualizza" style="max-height:128px" (click)="openImage(l)" />
                                                            <button class="btn btn-xs btn-secondary mr-1" (click)="openImage(l)" ><i class="fas fa-image mr-1"></i>{{i+1}}</button>
                                                        </ng-container>

                                                        <small>
                                                            <app-uploadfile [class]="'small'" [directory]="'personalid'" (fileuploaded)="addImagePartecipant(p,$event)"></app-uploadfile>
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-xs text-danger float-right" (click)="removePartecipant(p)"><i class="fa fa-trash-alt"  title="Elimina"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>-->                                   
                                    <div class="col-8">
                                        <b>{{j+1}})</b><br>
                                        <br>
                                        <small>Nome e cognome:</small><br>
                                        <input type="text" name="passengersname_{{j}}" class="form-control form-control-sm" [(ngModel)]="p.value">
                                        <small>Parametri:</small><br>
                                        {{p.requestparams | json}}<br>
                                        <small>Foto:</small><br>
                                        <ng-container *ngFor="let l of p.images;let i=index">
                                            <!-- <img class="img-thumbnail" src="{{getImagePath(l)}}"  title="Visualizza" style="max-height:128px" (click)="openImage(l)" /> -->
                                            <button class="btn btn-xs btn-secondary mr-1" (click)="openImage(l)" ><i class="fas fa-image mr-1"></i>FOTO {{i+1}}</button>
                                        </ng-container>
                                        <button class="btn btn-xs btn-outline-danger float-right" title="Elimina" (click)="removePartecipant(p)"><i class="fa fa-trash-alt mr-2"></i> Elimina</button>       
                                    </div>
                                    <div class="col-4">
                                        <app-uploadfile [class]="'small'" [directory]="'personalid'" (fileuploaded)="addImagePartecipant(p,$event)"></app-uploadfile>
                                    </div>
                                </div>

                                <div class="row border-top pt-1">
                                    <div class="col-8">
                                        <b>Firma</b>
                                    </div>
                                    <div class="col-4">
                                        <img src="{{record.signature}}" class="img-thumbnail d-block m-auto" style="width:90%">
                                    </div>
                                    <div class="col-12 mt-5" *ngIf="record.type_address==1">
                                        <h5>Partner / Agente esterno</h5>
                                        <div class="form-group row">
                                            <div class="col-4">Nominativo / Riferimento</div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" name="clientname" [(ngModel)]="record.clientname">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-4">Email Cliente</div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" name="clientemail" [(ngModel)]="record.clientemail">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-4">Tel. cellulare Cliente</div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" name="clientphone" [(ngModel)]="record.clientphone">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-4">Stanza / Altro</div>
                                            <div class="col-8">
                                                <input type="text" class="form-control form-control-sm" name="clientnote" [(ngModel)]="record.clientnote">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- DOCUMENTI -->
                            <div class="tab-pane fade" id="tabBooking3" role="tabpanel" aria-labelledby="tab3-tab">
                                <button type="button" class="btn btn-sm btn-success" (click)="addDocument();"><i class="fa fa-plus mr-2"></i>Aggiungi documento</button>
                                <button type="button" class="btn btn-sm btn-primary ml-2" (click)="linkDocument();"><i class="fa fa-link mr-2"></i>Collega documento</button>
                                <app-documentlist #documentlist [filterbox]="false" [type]="1" [mode]="'embedded'" [id_table]="record.id" [table]="'booking'"></app-documentlist>
                            </div>

                            <!-- CRONOLOGIA -->
                            <div class="tab-pane fade" id="tabBooking4" role="tabpanel" aria-labelledby="tab4-tab">
                                <table class="table table-sm">
                                    <thead>
                                        <th>Data</th>
                                        <th>Operazione</th>
                                        <th>Utente</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{record.created | date:'d/M/y h:m:s a'}}</td>
                                            <td>Prenotazione creata</td>
                                            <td>{{record.username}}</td>
                                        </tr>

                                        <tr *ngIf="record.checkin_iduser">
                                            <td>{{record.checkin | date:'d/M/y h:m:s a'}}</td>
                                            <td>Checkin</td>
                                            <td>{{record.checkin_username}}</td>
                                        </tr>

                                        <tr *ngIf="record.checkout_iduser">
                                            <td>{{record.checkout | date:'d/M/y h:m:s a'}}</td>
                                            <td>Checkout</td>
                                            <td>{{record.checkout_username}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h5>Magazzino</h5>
                                <app-loginventory [filterbox]="false" [mode]="'embedded'" [searchDate]="false" [id_table]="record.id" [table]="'booking'"></app-loginventory>
                            </div>

                            <!--FILES-->
                            <div class="tab-pane fade" id="tabBooking5" role="tabpanel" aria-labelledby="tab5-tab">
                                
                                <app-files [files]="record.files" [directory]="'booking/'+id"></app-files>
                            </div>
                        </div>
                        
                    </div>
               
                
            </div>
       
        
            <div class="col-12 col-lg-3">
                <div class="card">
                    <div class="card-body" >
                        <div class="form-group row">
                            <div class="col-12"><b>Riepilogo</b></div>
                            <ng-container *ngIf="record.id_rentalprice>0">
                                <div class="col-4 pr-0"><small>Tariffa</small></div>
                                <div class="col-8 pl-0 text-right"><small><b>{{record.rentalprice}}</b></small></div>
                            </ng-container>
                            <div class="col-4 pr-0"><small>Importo</small></div>
                            <div class="col-8 pl-0 text-right">{{record.amount | currency:"&euro;"}}</div>
                            <div class="col-4 pr-0"><small>Sconto</small></div>
                            <div class="col-8 pl-0 text-right" [class.text-danger]="record.discountValue > 0">
                                -{{record.discountValue | currency:"&euro;"}}
                                <!--<input *ngIf="record.discount" type="number" class="form-control form-control-sm" name="discount" [(ngModel)]="record.discount">-->
                            </div>
                            <div class="col-4 pr-0"><small>Imposta</small></div>
                            <div class="col-8 pl-0 text-right">+{{record.tax | currency:"&euro;"}}</div>
                            <div class="col-4 pr-0 border-top"><small><b>Totale</b></small></div>
                            <div class="col-8 pl-0 border-top text-right">
                                <b>{{total | currency:"&euro;"}}</b>
                            </div>
                            <div class="col-4 pr-0 border-top"><small>Pagato</small></div>
                            <div class="col-8 pl-0 border-top text-right" [class.text-info]="record.paid > 0">
                                {{record.paid | currency:"&euro;"}}
                            </div>
                            <ng-container *ngIf="total-record.paid > 0">
                                <div class="col-4 pr-0 border-top"><small>Da Pagare</small></div>
                                <div class="col-8 pl-0 border-top text-right text-danger">
                                    {{total-record.paid | currency:"&euro;"}}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group row">
                            <div class="col-12" >
                                <b>Tipo di prenotazione</b>
                                <select class="form-control form-control-sm" name="type_address" [(ngModel)]="record.type_address" (change)="changeState()">
                                    <option value="0">Cliente (B2C)</option>
                                    <option value="1">Partner (B2B)</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12" >
                                <b>Stato</b>
                                <select *ngIf="user.isAdmin()" class="form-control form-control-sm" name="state" [(ngModel)]="record.state">
                                    <option value="1">In attesa del pagamento</option>
                                    <option value="2">Confermato</option>
                                    <option value="6">Confermato (fornitore esterno)</option>
                                    <option value="3">Annullato</option>
                                    <option value="4">Checkin</option>
                                    <option value="5">Checkout (fine procedura)</option>
                                </select>
                                <div *ngIf="!user.isAdmin()">
                                    <span class="badge badge-warning" *ngIf="record.state==1">In attesa del pagamento</span>
                                    <span class="badge badge-success" *ngIf="record.state==2">Confermato</span>
                                    <span class="badge badge-danger"  *ngIf="record.state==3">Annullato</span>
                                    <span class="badge badge-info"  *ngIf="record.state==4">Checkin</span>
                                    <span class="badge badge-success"  *ngIf="record.state==5">Checkout</span>
                                    <span class="badge bg-teal"  *ngIf="record.state==6">Confermato (fornitore esterno)</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <b>Metodo di pagamento</b>
                                <select class="form-control form-control-sm" name="methodpayment" placeholder="..." #methodpayment="ngModel" [(ngModel)]="record.methodpayment">
                                    <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                                </select>
                                <small *ngIf="record.tracking"><br/>{{record.tracking}}</small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <b>Note interne</b>
                                <textarea class="form-control form-control-sm" rows="6" name="note" [(ngModel)]="record.note"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-block btn-danger" *ngIf="record.state==1 || record.state==6"  (click)="cancelBooking()">Annulla prenotazione</button>
                <button class="btn btn-block btn-success" *ngIf="record.state==1 || ((total-record.paid>0) && record.type_address==0)" (click)="goToPayment()">Effettua pagamento</button>
                <button class="btn btn-block btn-success" *ngIf="record.state==4" (click)="checkout()">Effettua check-out</button>
                <button class="btn btn-block btn-success" *ngIf="(record.state==2 || record.state==6) && ((total-record.paid<=0) || record.type_address==1)" (click)="checkin()">Effettua check-in</button>
            </div>
        </div>
    </form>
</app-window>

<app-toolbarmodel [model]="this"></app-toolbarmodel>
