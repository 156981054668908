<div class="d-none d-lg-block">    
    <ng-container *ngTemplateOutlet="list"></ng-container>
</div>
<div class="d-lg-none d-xs-block">
    <button class="btn btn-block btn-outline-primary btn-xs mb-2" (click)="openFilter()">Filtri...</button>
</div>
<ng-template #list>
    <div class="p-2 p-lg-0">
        <small><ng-content #content></ng-content></small>
    </div>
    <div class="p-2 d-lg-none d-xs-block">
        <button class="btn btn-sm btn-block btn-secondary" (click)="closeFilter()">Chiudi</button>
    </div>    
</ng-template>