import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Type } from '../../../db/type';
import { Filter,FilterMode, OrderingMode, User } from 'projects/core/src/include/structures'

import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { Inventories,InventoriesDetails, InventoriesTotal,InventoryMode,SNAvaible } from '../../../db/inventories';
import {ProductsService} from '../../../services/products.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../db/product';
import { ListinventoriesComponent } from './../listinventories/listinventories.component';
import { ModelList } from 'projects/core/src/include/modellist';
import { Globals } from 'projects/core/src/globals';
import { InventoryoutComponent } from '../inventoryout/inventoryout.component';
import { InventoriesModule } from '../inventories.module';
import { InventoriesDetailsComponent } from '../inventories-details/inventories-details.component';
import { LoadComponent } from '../load/load.component';
import { SalesComponent } from '../sales/sales.component';
import { ProductCategories } from '../../../db/productcategories';
import { ProductcategoriesService } from '../../../services/productcategories.service';
import { UserService } from 'projects/core/src/lib/user.service';
import { Helper } from 'projects/core/src/helper';
import { ProductsDetailsComponent } from '../../products/products-details/products-details.component';
import { TypesService } from '../../../services/types.service';
import { DocumentsDetailsComponent } from '../../documents/documents-details/documents-details.component';
import { InventoriesService } from '../../../services/inventories.service';



@Component({
  selector: 'app-loginventory',
  templateUrl: './loginventory.component.html',
  styleUrls: ['./loginventory.component.sass']
})
export class LoginventoryComponent  extends ModelList<InventoriesDetails> implements OnInit,AfterViewInit {

  

  
  @Input()
  date_from:Date;

  @Input()
  date_to:Date;
  
  @Input()
  id_product:number=0;

  @Input()
  sn:string="";

  @Input()
  id_table:number=0;

  @Input()
  table:string=" ";

  @Input()
  searchDate:boolean=true; 
  
  @Input()
  filterbox:boolean=true;


  get TypeDataSearch(){
    if(this.mode=="modal"){
      return 98
    }else
      return 1
  }

  inventories:Inventories[];
  status_inventories:Type[];
  categories:ProductCategories[]=[];
 

  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_type:Filter=new Filter();
  filter_id_inventory:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_product:Filter=new Filter();
  filter_id_category:Filter=new Filter();
  filter_sn:Filter=new Filter();  
  filter_id_user:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_product_id_type:Filter=new Filter();
  filter_typedocument:Filter=new Filter();

  total_amount:number=0;

  total:InventoriesTotal;
  
  users:User[];

  isFilterSet:boolean=false;
  type_products=[];
  /* FAST LOAD/ULOAD */

  fastLUquantity:number=1;
  fastLUinventory:number=Globals.user.getParam("id_inventory",0);
  @Input()
  afterSave:Function;
  

  constructor( private fb1: UntypedFormBuilder,
    private InventoriesDetailsService: InventoriesDetailsService,
    private InventoriesService: InventoriesService,

    private productsService: ProductsService,
    private productcategoriesService:ProductcategoriesService,
    private userService:UserService,
    private modalService: NgbModal,
    private typesService: TypesService

    
  ) {
    super(InventoriesDetailsService,fb1);
    
    this.detailViewModal=InventoriesDetailsComponent;
   }

  ngOnInit(): void {
    this.title = 'Magazzino';
    super.ngOnInit.apply(this, arguments); 
    this.paging_count=24;
    
 
     

  }

  ngAfterViewInit(): void {


    this.afterGetItems=()=>{

      this.InventoriesDetailsService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{
       
        this.total=item;
      });
      
      if(this.id_product>0){
        //calcola le giacenze progressive
        let stock=0;
        for(let i=this.list.length-1;i>-1;i--){
            stock=stock+(this.list[i].type==1?1:-1)*this.list[i].quantity;
            this.list[i]['stock']=stock;
        }
      }
    }

    this.InventoriesService.getInventories().subscribe((items)=>{
      this.inventories=items;
    });
    
    

    //if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      //this.updateList();

    InventoriesModule.savingInventoriesFastEvent.subscribe(()=>{
      this.getItems();
    });

    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
    });
    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    });

    this.typesService.getTypes("products").subscribe((items)=>{
      this.type_products=items;
      
    });

    if(!this.filterbox) //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList();
    else
      this.setFilters();
    
  }

  setFilters():void{
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("i.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    
    this.filter_id_category.mode=FilterMode.contain;
    this.filter_id_category.fields=[];
    this.filter_id_category.fields.push("p.id_category");
    this.filter_id_category.value="";
    this.filter.push(this.filter_id_category);

    this.filter_product_id_type.mode=FilterMode.normal;
    this.filter_product_id_type.fields=[];
    this.filter_product_id_type.fields.push("p.id_type");
    this.filter_product_id_type.value="";
    this.filter.push(this.filter_product_id_type);

    if(this.searchDate){
      this.filter_date.mode=FilterMode.date;
      this.filter_date.fields=[];
      this.filter_date.fields.push("i.date");

      if(this.date_from)
        this.filter_date.value=Helper.convertDateControl(this.date_from);

      if(this.date_to)
        this.filter_date.value2=Helper.convertDateControl(this.date_to);
      
      this.filter.push(this.filter_date);
    }

    

    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("i.type");
    this.filter_type.value="";
    this.filter.push(this.filter_type);

    this.filter_id_inventory.mode=FilterMode.normal;
    this.filter_id_inventory.fields=[];
    this.filter_id_inventory.fields.push("i.id_inventory");
    this.filter_id_inventory.value="";
    this.filter.push(this.filter_id_inventory);

    if(this.id_product>0){
      this.filter_id_product.mode=FilterMode.normal;
      this.filter_id_product.fields=[];
      this.filter_id_product.fields.push("i.id_product");
      this.filter_id_product.value=this.id_product.toString();
      this.filter.push(this.filter_id_product);
    }
    
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("a.name");
    this.filter_search.fields.push("i.description");
    this.filter_search.fields.push("i.sn");
    this.filter_search.fields.push("p.codeinternal");
    this.filter_search.fields.push("p.codeexternal");
    this.filter_search.fields.push("p.barcode");
    this.filter_search.fields.push("i.note");
    this.filter_search.fields.push("i.id_product");
    
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    if(this.id_table>0){
      this.filter_id_table.mode=FilterMode.normal;
      this.filter_id_table.fields=[];
      this.filter_id_table.fields.push("i.id_table");
      this.filter_id_table.value=this.id_table.toString();
      this.filter.push(this.filter_id_table);

      
    }

    this.filter_table.mode=FilterMode.normal;
      this.filter_table.fields=[];
      this.filter_table.fields.push("i.table");
      this.filter_table.value=this.table;
      this.filter_table.nullvalue=" ";
      this.filter.push(this.filter_table);

      this.filter_typedocument.mode=FilterMode.contain;
      this.filter_typedocument.enabled=false;
      this.filter_typedocument.fields=[];
      this.filter_typedocument.fields.push("d.typedocument");
      this.filter_typedocument.value="";
      this.filter.push(this.filter_typedocument);
      

    if(this.sn!=""){
      this.filter_sn.mode=FilterMode.normal;
      this.filter_sn.fields=[];
      this.filter_sn.fields.push("i.sn");
      this.filter_sn.value=this.sn;
      this.filter.push(this.filter_sn);
    }

    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("i.id_user");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);


    if(this.mode!="modal" && this.mode!="embedded")
      Globals.navigation.getStateFilters(this.constructor.name,this.filter);

    this.ordering.field="i.id";
    this.ordering.mode=OrderingMode.discendent;
    
    

    
    /*
    this.afterGetItems=(()=>{
      
    
    })*/
    this.isFilterSet=true;
    
  }

ngOnChanges():void{
  if(this.id_product>0 || this.id_table>0 || this.sn!="") //avvia la ricerca quando il filtro è attivo
    this.updateList();
}

updateList():void{
  if(!this.isFilterSet)
      this.setFilters();

  this.total_amount=0;
  
  if(this.id_product>0){
    this.filter_id_product.value=this.id_product.toString();
  }

  this.getItems();
}

  fastout(){
    Globals.modal.showModal(InventoryoutComponent,[],()=>{
      this.getItems();
    });
  }


  //Effettua il carico rapido all'interno di una scheda prodotto
  fastload(type=1){

    //prendi il record del prodotto
    this.productsService.getItem(this.id_product).subscribe((item)=>{

      let product:Product=new Product();
      Object.assign(product,item);

      let recordToLoad=new InventoriesDetails();
      recordToLoad.description=product.brand+" "+product.name;
      recordToLoad.id_product=this.id_product;
      recordToLoad.type=type;
      recordToLoad.quantity=this.fastLUquantity;
      recordToLoad.netprice=product.getPrice(recordToLoad.quantity,recordToLoad.type);
      if(recordToLoad.type==InventoryMode.load){
        //recordToLoad.discount=product.getEquivalentDiscountCost();
        recordToLoad.discount1=product.discountcost1;
        recordToLoad.discount2=product.discountcost2;
        recordToLoad.discount3=product.discountcost3;
        
      }
        
      recordToLoad.tax=item.tax;
      recordToLoad.id_inventory=this.fastLUinventory;

      this.InventoriesDetailsService.save(recordToLoad,()=>{
        this.getItems();
        if(this.afterSave)
          this.afterSave();
      });
     
    });

    

  }

  openInventoryManager(){
    Globals.modal.showModal(ListinventoriesComponent,[],()=>{
      this.InventoriesService.getInventories().subscribe((items)=>{
        this.inventories=items;
      })
    });
  }

  move(record){
    this.InventoriesDetailsService.move(record.id_product,()=>{
      this.getItems();
    })
  }

  load(){
    Globals.modal.showModal(LoadComponent,[],()=>{
      
    },'xl');
  }

  unload(){
    Globals.modal.showModal(SalesComponent,[],()=>{

    },'xl');
  }

  calculateTotalRow(inventory){
    return inventory.netprice*inventory.quantity*(1+inventory.tax/100)*(1-Helper.getEquivalentDiscount(inventory.discount1,inventory.discount2,inventory.discount3)/100);
  }

  openProduct(id_product){
    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id_product},
      
    ],()=>{

    });
  }

  openHistory(id_product){
    Globals.modal.showModal(LoginventoryComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_product","value":id_product},
    ],()=>{
      
    })

  }

  createInventoryDocument(){
    let details=[];
    let ids=[];
    if(this.form.value.id.length>0){
      ids=this.form.value.id;
      
      //trova le voci da inserire
      for(let i of this.list){
        if(ids.indexOf(i['id'])>-1){
          let d={};
          Object.assign(d,i);
         
          if(d['type']==2)
            d['id_inventory_detail_unload']=d['id'];
          else
            d['id_inventory_detail_load']=d['id'];
          d['id']=0;
          details.push(d);
        }
      }

    }

    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"type_document","value":13},
      {"name":"details","value":details},

    ],(instance)=>{
      if(instance!=null){
        let id=instance['record']['id'];
        this.InventoriesDetailsService.assignReference(ids.join(","),id,"documents").subscribe((result)=>{
          this.getItems();

        });
       
      }
        
    });
  }

  insertToDocument(){
    
  }

}
