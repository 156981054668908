<app-windowlist [model]="this">
    <div class="card card-outline">

        <div class="p-0">
            <div class="row">
                <div class="col-5">
                    <div class="input-group input-group-sm m-1" >
                        <input type="text" name="table_search" class="form-control float-right" placeholder="Cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                        <div class="input-group-append ">
                            <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="Annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                            <!-- <button class="btn btn-default"   title="Regole" data-toggle="collapse" data-target="#advancedSearchPanel"  aria-expanded="false" aria-controls="advancedSearchPanel" ><span id="searchclear" class="fas fa-sliders-h text-info"></span></button> -->
                        
                        </div>
                    </div>
                </div>
                
                <div class="col-2">
                    <select class="form-control form-control-sm m-1" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()">
                        <option value="">Tutte le tipologie</option>
                        <option *ngFor="let t of type_ads" [ngValue]="t.id">{{t.name}}</option>
                    </select>
                </div>

                <div class="col-3" *ngIf="mode!='modal'">
                    <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    <button  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                </div>


            </div>


            <div *ngIf="list==null" class="m-4">
                <i class="fa-2x fas fa-info text-info mr-3"></i>
                <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
            </div>

            <form [formGroup]="form" *ngIf="list!=null">
                <div class=" m-4" *ngIf="list.length==0">
                    <i class="fa-2x fas fa-info text-info mr-3"></i>
                    <i>Nessun annuncio trovato!</i>
                </div>

                <table class="table table-sm table-head-fixed table-hover text-nowrap m-0" *ngIf="list.length>0">
                    <thead>
                        <tr>
                            
                            <th>
                                <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                    <input type="checkbox" title="Seleziona tutti"
                                    (change)="onChangeAll($event.target.checked)" />
                                </div>
                                ID
                            </th>
                            <th>Codice</th>
                            <th>
                                Tipologia
                                <button class="btn btn-tool" (click)="switchOrdering('c.id_type')">
                                    <i class="fas fa-sort {{classOrdering('c.id_type')}}"  ></i>
                                </button>
                            </th>
                            <th>
                                Titolo
                            </th>
                            <th>Stato</th>
                            <th>Utente</th>
                            <th></th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    
                        <!-- elemento ripetuto -->
                        <tr *ngFor="let record of list">
                            <td>
                                <div class="icheck-primary d-inline mr-3">
                                    <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                    (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                    />
                                </div>
                                {{record.id}}
                            </td>
                            <td>{{record.code}}</td>
                            <td>
                                {{record.type}}
                            </td>
                            <td>
                                {{record.title}}
                            </td>
                            <td>
                                <span *ngIf="record.state==1" class="badge badge-warning">In attesa di ricezione offerte</span>
                                <span *ngIf="record.state==2" class="badge badge-info">Offerta selezionata</span>
                                <span *ngIf="record.state==3" class="badge badge-success">Offerta conclusa</span>
                                
                            </td>
                            <td>
                                {{record.username}}
                            </td>
                            <td>
                                <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                    <i class="fas fa-pencil-alt"></i></button>
                                <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right m-1" (click)="selectRecord(record)">
                                    <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                                <button type="button" *ngIf="mode!='modal'"  (click)="notify(record)" title="Notifica" class="btn btn-primary btn-xs float-right m-1">
                                    <i class="far fa-paper-plane"></i></button>
                                 
                            </td>
                        </tr>

                    </tbody>
                    
                </table>
            </form>


        </div>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>

</app-windowlist>
