<section class="fixed-bottom p-0" style="background-color: rgba(26, 29, 32, 0.8);">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
        
            <button class="btn btn-secondary float-right m-1" (click)="onBack()"   title="Annulla" ><i class="fas fa-times"></i><span class="d-none d-sm-inline-block ml-2">Annulla<span class="KeyFunction ml-2">(ESC)</span></span></button>
            <ng-container *ngIf="!readonly">
              <button *ngIf="showSave"  title="Salva" class="btn btn-success float-right m-1" [disabled]="disable" (click)="save();" ><i class="fas fa-save"></i><span class="d-none d-sm-inline-block ml-2">Salva<span class="KeyFunction ml-2">(F8)</span></span></button>
              <button *ngIf="showSave && showSaveAndNew"   title="Salva e Nuovo" class="btn btn-success float-right m-1 d-none d-sm-inline-block" [disabled]="disable" (click)="saveAndNew();"><i class="fas fa-plus-circle"></i><span class="d-none d-sm-inline-block ml-2">Salva & Nuovo</span></button>
              <button *ngIf="showSave && showSaveAndClose"  title="Salva e Chiudi" class="btn btn-success float-right m-1 d-none d-sm-inline-block" [disabled]="disable"  (click)="saveAndClose();"><i class="far fa-window-close"></i><span class="d-none d-sm-inline-block ml-2">Salva & Chiudi</span></button>
              <button *ngIf="showSave && ShowSaveAndPrint"  title="Salva e stampa" class="btn btn-success float-right m-1" [disabled]="disable" (click)="saveAndPrint();" ><i class="fas fa-print"></i><span class="d-none d-sm-inline-block ml-2">Salva e Stampa</span></button>
         
            </ng-container>
            
            <span class="m-2 p-1 text-gray float-left d-none d-sm-inline-block">ID: <b>{{id}}</b>
              <ng-container *ngIf="model.record">
                <small *ngIf="!is_lastupdate && is_created">
                  <i class="ml-3 mr-1 fa fa-regular fa-user-tie" title="{{info_created}}"></i>
                  {{info_created}}
                </small>
                <small *ngIf="is_lastupdate">
                  <i class="ml-3 mr-1 fa fa-regular fa-user-tie" title="{{info_lastupdate}}"></i>
                  {{info_lastupdate}}
                </small>
              </ng-container>
            </span>
            <span *ngIf="readonly" class="m-2 p-1 float-left d-none d-sm-inline-block text-danger">(Solo lettura dati)</span>
        </div>
      </div>
    </div>
</section>