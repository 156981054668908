<section class="content">
    <ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="tailormade">
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                <i class="fas fa-box"></i><span class="d-none d-sm-inline-block ml-2">Pacchetti</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                <i class="fas fa-hiking"></i><span class="d-none d-sm-inline-block ml-2">Tailor Made</span></a>
        </li>
    </ul>

    <div class="tab-content" id="myTabContent" >
        <div class="tab-pane show active" *ngIf="tab==1">
            <app-tours-list [tailormade]="false" [autoopen]="autoopen" [filterbox]="filterbox"></app-tours-list>
        </div>
        <div class="tab-pane show active" *ngIf="tab==2">
            <app-tourstailormade></app-tourstailormade>
        </div>
    </div>
</section>

