import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CitiesService } from "../../services/cities.service";
import { City, CountryEntry, CityEntry, ZipEntry} from "../../db/city";
import { Filter, FilterMode, Ordering, OrderingMode} from 'projects/core/src/include/structures';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.sass']
})


export class CitiesComponent implements OnInit {
  @Input() readonly:boolean=false;
  @Input() entries: Array<any>;
  @Input() entryProperty: string;
  @Input() inputPlaceholder: string = "Search..."
  @Input() inputId: string | number = 0;
  @Output() onEntrySelected = new EventEmitter();

  @Input() disabled: boolean=false;
  showResults: boolean = false;
  filteredEntities: City[];
  filter: City={} as City;

  @Input() enable_geocoding:boolean=false;

  @Input() address:string="";
  @Input() city:string="";
  @Input() zip:string="";
  @Input() country:string="";
  @Input() latitude:number;
  @Input() longitude:number;
  @Input() foreign:boolean=false;
  
  countries:CountryEntry[]=[];
  cities:CityEntry[]=[];
  zips:ZipEntry[]=[];
  
  constructor(private citiesService:CitiesService,private http: HttpClient) {
  }


  ngOnInit() {
    this.filteredEntities = this.entries;

    // this.country = 'RC';
    // this.zip = "00000";
    
  }

  ngOnChanges():void{

    //verifica se sono state caricate tutte le province
    /*
    if(this.countries.length==0)
      this.getCountries();

    if(this.country!=undefined){
      this.getCities(this.country,this.city);
    }
    */
  }


  filterEntries(val: string) {

    let filterCity:Filter=new Filter();
    filterCity.mode=FilterMode.like;
    filterCity.fields=[];
    filterCity.fields.push("city");
    filterCity.value=val;

    let ordering:Ordering={} as Ordering;
    ordering.mode=OrderingMode.ascendent;
    ordering.field="city";

    this.citiesService.getItems([filterCity],[ordering],0,10).subscribe((items)=>{
      this.filteredEntities=items.value;
    });

  }


  inputFieldFocused() {
    let inputId = 'inputField' + this.inputId;
    let menuId = '#menu' + this.inputId + ' ' + 'a';
    document.getElementById(inputId).addEventListener('keydown', function (e) {
      if (e.key == "ArrowDown") {
        (document.querySelectorAll(menuId)[0] as any).focus();
      }
    });
  }


  selectEntry(entry) {
    if (typeof this.entryProperty != 'undefined') {
      this.filter = entry[this.entryProperty];
    } else {
      this.filter = entry;
    }

    this.city=this.filter.city;
    this.zip=this.filter.zip;
    this.country=this.filter.country;

    this.showResults = false;
    //this.onEntrySelected.emit(entry);
    this.update();
  }


  update():void{
    let c:City={} as City;
    c.city=this.city;
    c.zip=this.zip;
    c.country=this.country;
    c.address=this.address;
    this.onEntrySelected.emit(c);
  }

  geocoding():void{
    let url="https://nominatim.openstreetmap.org/search?q="+this.address+","+this.city+"&format=json";
    this.http.get<string>(url).toPromise<string>().then((value)=>{
        this.latitude=value['boundingbox']['lat'];
        this.longitude=value['boundingbox']['lon'];
    });
    console.log(url);

  }


  getCountries():void{
    this.citiesService.Countries().subscribe((items)=>{
      this.countries=items;
    });
  }


  getCities(country:string,selected_city=""):void{
    this.citiesService.Cities(country).subscribe((items)=>{
      this.cities=items;
      if(selected_city)
        this.city=selected_city;
      
    });
  }


  search():void{
    let url="https://nominatim.openstreetmap.org/search?q="+this.address.replace(","," ")+","+this.city+"&addressdetails=1&format=json";
    this.http.get<string>(url).toPromise<string>().then((value)=>{
        
        if(value){
          const firstRes=value[0];
          if(firstRes['address'] && firstRes['address']['postcode'])
            this.zip=firstRes['address']['postcode'];
          if(firstRes['address'] && firstRes['address']['ISO3166-2-lvl6'])
            this.country=firstRes['address']['ISO3166-2-lvl6'].split("-")[1];
            
        }  
      
        this.update();
    });
    /*
    this.citiesService.Zips(city).subscribe((items)=>{
      this.zips=items;
      if(this.zips.length>0){
        this.zip=items[0].zip;
        this.update();
      }
    });*/
  }


}
