<div *ngIf="list.length==0" class="m-5 text-center text-secondary">
    <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i><br>
    <!-- <a type="button" (click)="ngOnInit()" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
</div>
<form *ngIf="list.length>0" [formGroup]="form">
    <table class="table table-striped table-nowrap table-sm table-head-fixed table-hover m-0 text-sm" *ngIf="viewMode=='table'">
        <thead>
            <th>
                <div #checkboxselectall class="float-left m-1 icheck-primary d-inline mr-3">
                    <input  type="checkbox" title="Seleziona tutti"
                    (change)="onChangeAll($event.target.checked)" />
                </div>
            </th>
            <th>Data
                <!--
                <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('d.date')">
                    <i class="fas fa-sort {{classOrdering('d.date')}}"  ></i>
                </button>
                -->
            </th>
            <th>Descrizione</th>
            <th class="d-none d-sm-table-cell" *ngIf="mode!='embedded'">Anagrafica</th>
            <th class="d-none d-sm-table-cell text-center">
                Importo
                <!--
                <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('d.amount')">
                    <i class="fas fa-sort {{classOrdering('d.amount')}}"  ></i>
                </button>
                -->
            </th>
            <th class="d-none d-sm-table-cell text-center">Azioni</th>
            <th class="text-right"></th>
        </thead>

        <tbody>
            <tr *ngFor="let r of list" 
                [class.bg-yellowline]="verifyDate(r.date) && r.activity && r.activity.phase==4"
                [class.bg-greenline]="verifyDate(r.date) && r.activity && r.activity.phase==3"
                [class.bg-greenline]="verifyDate(r.date) && r.activity && r.activity.phase==2"
                [class.bg-yellowline]="verifyDate(r.date) && r.activity && r.activity.phase==1" >
                <td>
                    <input type="checkbox" #checkrecord id_record="{{r.id}}" title="{{r.id}}"
                            (change)="onChange(r.id, $event.target.checked)" class="m-1 float-left" [checked]="checkedAll"/><br>
                    <span class="d-inline-block d-sm-none">
                        <span><i class="text-warning fa fa-lg fa-2x fa-exclamation"></i></span>
                        <ng-container *ngIf="r.activity">
                            <span *ngIf="r.activity.phase==4"><i class="text-warning fa fa-lg fa-2x fa-exclamation"></i></span>
                            <span *ngIf="r.activity.phase==3"><i class="text-success fa fa-lg fa-2x fa-check-double"></i></span>
                            <span *ngIf="r.activity.phase==2"><i class="text-success fa fa-2x fa-check"></i></span>
                            <span *ngIf="r.activity.phase==1"><i class="text-warning far fa-lg fa-2x fa-clock"></i></span>
                        </ng-container>
                        &nbsp;
                        <a *ngIf="r.id_activity>0" (click)="openActivity(r.id_activity)"  class="btn btn-primary btn-xs" ><i class="fas fa-eye"></i><span class="d-none d-sm-inline-block ml-2">Apri attività</span></a>
                    </span>
                </td>
                <td>
                    <span>{{r.date | date:'dd/M/yyyy'}}<br><small class="text-gray">{{r.date | date:'EEEE'}}</small></span><br>
                    <span class="d-inline-block d-sm-none">
                        <a class="btn btn-outline-success btn-xs m-1" (click)="addPayment(r)" *ngIf="isModuleEnabled('cash-flow')"><i class="fas fa-check"></i>  </a>
                        <a type="button" *ngIf="r.id_activity==0 && isModuleEnabled('activities')" class="btn btn-outline-primary btn-xs m-1" (click)="convertDeadlineIntoActivity(r);"><i class="fas fa-thumbtack"></i></a>
                    </span>
                </td>
                <td>
                    <small><b>{{r.type}}</b></small><br>
                    <i class="d-none d-sm-inline-block text-gray" *ngIf="r.description">{{r.description}}</i>
                    <div class="d-inline-block d-sm-none">
                        {{r.name}}
                        <!--<app-reference *ngIf="mode!='embedded'" [reference]="r.reference" [table]="r.table"></app-reference>-->
                    </div>
                </td>
                <td class="d-none d-sm-table-cell" *ngIf="mode!='embedded'">
                    {{r.name}}
                    <!--<app-reference *ngIf="mode!='embedded'" [reference]="r.reference" [table]="r.table"></app-reference>-->
                </td>
                <td class="d-none d-sm-table-cell text-center">
                    <span *ngIf="r.type_amount==1">
                        {{r.amount*(1+r.tax/100) | currency:"&euro; "}}
                    </span>
                    <span class="text-danger" *ngIf="r.type_amount==2">
                        {{r.amount*(1+r.tax/100)*-1 | currency:"&euro; "}}
                    </span>
                    <br/>
                    <div *ngIf="r.methodpayment" class="badge border"><b>{{r.methodpayment}}</b></div>
                </td>
                <td class="d-none d-sm-table-cell text-center">
                   <a class="btn btn-block btn-outline-success btn-xs" (click)="addPayment(r)" *ngIf="isModuleEnabled('cash-flow')">
                       <i class="fas fa-check"></i><span class="d-none d-lg-inline ml-2">Registra pagamento</span>
                   </a>
                    <a type="button" *ngIf="r.id_activity==0 && isModuleEnabled('activities')" class="btn btn-block btn-outline-primary btn-xs my-1" (click)="convertDeadlineIntoActivity(r);">
                        <i class="fas fa-thumbtack"></i><span class="d-none d-lg-inline ml-2">Crea attività</span>
                    </a>
                    <a *ngIf="r.id_activity>0" (click)="openActivity(r.id_activity)" class="btn btn-block btn-outline-primary btn-xs my-1" >
                        <i class="fas fa-eye"></i><span class="d-none d-sm-inline-block ml-2">Apri attività</span>
                    </a>
                    <div class="pt-1" *ngIf="r.activity">
                        <i *ngIf="r.activity.phase==4"><i class="text-warning fa fa-lg fa-2x fa-exclamation mr-2"></i>Ripianificata per {{r.activity.reschedule | date:"dd/MM/yyyy"}}</i>
                        <i *ngIf="r.activity.phase==3"><i class="text-success fa fa-lg fa-2x fa-check-double mr-2"></i>Conclusa e confermata il {{r.activity.date | date:"dd/MM/yyyy"}}</i>
                        <i *ngIf="r.activity.phase==2"><i class="text-success fa fa-2x fa-check mr-2"></i>Conclusa il {{r.activity.date | date:"dd/MM/yyyy"}}</i>
                        <i *ngIf="r.activity.phase==1"><i class="text-warning far fa-lg fa-2x fa-clock mr-2"></i>Pianificata per {{r.activity.date | date:"dd/MM/yyyy"}}</i>
                    </div>
                </td>
                <td class="text-right">
                    <button type="button" class="btn btn-secondary btn-xs float-right ml-1 mt-1" (click)="openDetail(r)"><i class="fa fa-pencil-alt"></i></button>
                    <button type="button" class="btn btn-info btn-xs float-right ml-1 mt-1" (click)="duplicate(r)"><i class="far fa-clone"></i></button>
                </td>
            </tr>
        </tbody>
        <tfoot class="bg-white">
            <td></td>
            <td><b>TOTALE</b></td>
            <td></td>
            <td *ngIf="mode!='embedded'"></td>
            <td class="text-center"><strong>{{total | currency:" &euro;"}}</strong></td>
            <td></td>
            <td></td>
        </tfoot>
    </table>    
    <ul *ngIf="viewMode=='list'"  class="list-group list-group-flush" >
        <li class="list-group-item list-group-item-action" *ngFor="let deadlines of list">
            <div class="row">                
                <div class="col-1">
                    <img (click)="openDetail(deadlines)" src="assets/calendar.png" class="img-thumbnail">
                </div>
                <div class="col-8" *ngIf="deadlines.reference">
                    <div class="row">
                        <div class="col-12">
                            <a (click)="openDetail(deadlines)" [class.trashed]="deadlines.status == 2"  title="{{deadlines.date | date:'EEEE'}}" class="pointer">
                                <h6 [class.text-warning]="verifyDate(deadlines.date)" [class.text-info]="!verifyDate(deadlines.date)">{{deadlines.date | date:'EEEE dd MMM yyyy'}} </h6>
                                <ng-container *ngIf="deadlines.id_activity>0">
                                    <small ><i  class="far  fa-star"></i></small><br>
                                </ng-container>
                               
                                <small>{{deadlines.type}}</small><br>
                                <i class="d-none d-sm-inline-block text-secondary" *ngIf="deadlines.description">{{deadlines.description}}</i>
                                
                                <div class="widget-heading">
                                    <div class="badge"><i class="fa fa-user"></i></div>
                                {{deadlines.name}} <span *ngIf="deadlines.balance <0 " class="text-red" ><small>(saldo {{deadlines.balance}} &euro;)</small></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-8">
                            <app-reference *ngIf="mode!='embedded'" [reference]="deadlines.reference" [table]="deadlines.table"></app-reference>
                        </div>
                        <div class="col-2">
                            <ng-container *ngIf="deadlines.amount>0">
                                <div class="badge badge-danger text-right">
                                    {{deadlines.amount | currency:"&euro; "}}
                                    {{deadlines.methodpayment}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-right">                   
                    <button type="button" *ngIf="deadlines.id_activity==0" class="btn btn-success btn-sm" (click)="convertDeadlineIntoActivity(deadlines);"><i class="fas fa-thumbtack"></i><span class="d-none d-sm-inline-block ml-2">Crea attività</span></button>
                    <a *ngIf="deadlines.id_activity>0" (click)="openActivity(deadlines.id_activity)"  class="btn btn-primary btn-sm" ><i class="fas fa-eye"></i><span class="d-none d-sm-inline-block ml-2">Apri attività</span></a>
                    <div class="icheck-primary d-inline mr-3" *ngIf="filter_box">
                        <input type="checkbox" #checkrecord id_record="{{deadlines.id}}"
                        (change)="onChange(deadlines.id, $event.target.checked)" [checked]="checkedAll"
                        />
                    </div>
                    <button type="button" *ngIf="!filter_box" class="btn btn-danger btn-sm" (click)="delete(deadlines.id,true);"><i class="fa fa-trash"></i></button>                    
                </div>
            </div>            
        </li>   
    </ul>
    <table class="table table-sm" *ngIf="viewMode=='list'">
        <thead class="bg-info">
            <tr>
                <td>Totale</td>
                <td class="text-right">{{total | currency:"&euro; "}}</td>
            </tr>
        </thead>
    </table>


</form>

<ng-template #convertActivityModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Converti scadenze <span *ngIf="convert['deadline']">di <strong>{{convert['deadline']['type']}}</strong></span> in attività</h5>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeConvertActivity()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
            <div class="row">
                <div class="col-sm-4" *ngIf="convertActivityMode==0">
                    <div class="form-group">
                        <label>Data</label>
                        <div class="input-group" >
                            <input class="form-control" type="date"  name="date"  [(ngModel)]="convert.date"  />
                        </div>                        
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Tipologia</label>
                        <select required class="custom-select" [(ngModel)]="convert.id_type" name="id_type" #id_type="ngModel" required>
                            <option *ngFor="let t of type_activities" [ngValue]="t.id">{{t.name}}</option>
                        </select>
                        <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="alert alert-danger">
                            <div *ngIf="id_type.errors.required">Campo obbligatorio.</div>
                        </div>                        
                    </div>
                </div>                
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Operatore</label>
                        <select class="custom-select" [(ngModel)]="convert.id_user" name="id_user" >
                            <ng-container *ngFor="let u of users">
                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                            </ng-container>
                        </select>
                        
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="convertActivityMode==0">
                    <div class="form-group">
                        <label>Descrizione</label>
                        <textarea class="form-control" rows="2" onblur="this.rows=2;" onfocus="this.rows=4;"  name="description" [(ngModel)]="convert.description"></textarea>
                    </div>
                </div>
                <div class="col-sm-4" *ngIf="convertActivityMode==0">
                    <div class="form-group">
                        <label>Importo</label>
                        <div class="input-group">
                            <div class="input-group-prepend ">
                                <span class="input-group-text">&euro;</span>
                            </div>
                            <input type="number" class="form-control"  min="0" step="0.01" name="amount" #amount="ngModel" placeholder="..." [(ngModel)]="convert.amount" >
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" *ngIf="convertActivityMode==0">
                    <div class="form-group">
                        <label>IVA</label>
                        <div class="input-group">
                            <div class="input-group-prepend ">
                                <span class="input-group-text">%</span>
                            </div>
                            <input type="number" class="form-control"  min="0" step="1" name="tax" placeholder="..." [(ngModel)]="convert.tax" >
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" *ngIf="convertActivityMode==0">
                    <div class="form-group">
                        <label>Totale</label>
                        <div class="input-group">
                            <div class="input-group-prepend ">
                                <span class="input-group-text">&euro;</span>
                            </div>
                            <input type="number" class="form-control"  min="0" step="0.01" name="gross" placeholder="..." [(ngModel)]="convert.gross" >
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Note</label>
                        <textarea class="form-control" rows="2" onblur="this.rows=2;" onfocus="this.rows=4;"  name="note" [(ngModel)]="convert.note"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-success"  [disabled]="convert.id_type == null || convert.date == ''" (click)="confirmActivity()"><i class="fas fa-save"></i> Conferma</button>
        <button type="button" class="btn btn-secondary" (click)="closeConvertActivity()">Annulla</button>
        </div>
</ng-template>