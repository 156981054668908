import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Component, HostListener, Input, OnInit, Output,EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toast } from 'ngx-toastr';
import { Image } from 'projects/c1-backend/src/app/db/image';
import { InventoriesDetails, InventoryMode } from 'projects/c1-backend/src/app/db/inventories';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { QrcodereaderComponent } from 'projects/core/src/common/qrcodereader/qrcodereader.component';
import { StripeComponent } from 'projects/core/src/common/stripe/stripe.component';
import { TakephotoComponent } from 'projects/core/src/common/takephoto/takephoto.component';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ToastMode } from 'projects/core/src/include/structures';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Booking, BookingDetail } from '../../../db/booking';
import { Installation } from '../../../db/installation';
import { BookingService } from '../../../services/booking.service';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-checkinbooking',
  templateUrl: './checkinbooking.component.html',
  styleUrls: ['./checkinbooking.component.css']
})
export class CheckinComponent implements OnInit {
  @ViewChild(StripeComponent)
  stripeComponent:StripeComponent;
  payment_method_id;

  @ViewChild("sigPad")
  sigPad:SignaturePadComponent;

  confirmPaymentModal=false;
  payModal=false;
  passengerSelected:any;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': window.innerWidth,
    'canvasHeight': window.innerHeight-200
  };


  signature;

  @ViewChild("modalProduct")
  modalProduct:TemplateRef<any>;
  modalProductRef;
  modalProductTab="";

  @ViewChild("modalSignature")
  modalSignature:TemplateRef<any>;
  modalSignatureRef;

  @ViewChild("modalRequestParams")
  modalRequestParams:TemplateRef<any>;
  modalRequestParamsRef;

  

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {

    if(event.keyCode==113){ //F2
      this.goPrevious();
    }

    if(event.keyCode==115){ //F4
      this.goNext();
    }

    
  }

  fileToUpload: File = null;

  @Input()
  booking:Booking;

  @Input()
  showClose:boolean=true;

  @Input()
  showSteps:boolean=true;

  @Output()
  onSuccess:EventEmitter<void>=new EventEmitter();

  @Input()
  canExploreCatalog:boolean=true;

  isAddProduct=false;

  bookingdetailSelected;
  requestSelected;
  
  get NextTitleBtn(){
    return this.step<(this.steps.length-1)?"Avanti":"Conferma";
  }

  get showPrevious(){
    return this.step==0?false:true;
  }

  get showNext(){
    return this.step<this.steps.length?true:false;
  }

  steps=['Acquisizione dei documenti di riconoscimento','Consegna dei prodotti','Firma Contratto','Conferma']
  step=0;
  textTosearch:string
  listChecked:any[]=[];
  list:InventoriesDetails[]=[];
  passengers=[];
  inventoryItems=[];
  
  inventoryItemsGroup=[];
  

  inventoryItemsBike=[];


  inventoryItemsExtra=[];
  inventoryItemsSuggest=[];
  id_category_bike=0;
  id_category_extra=0;
  requestparams=[];
  total_gross=0;
  searchProduct="";
  searchIDCategory=0;

  get steptitle(){
    return this.steps[this.step];
  }

  constructor(
    //private inventoriesDetailsService:InventoriesDetailsService,
    private bookinService:BookingService,
    private uploadService:UploadService,
    private productsService:ProductsService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
   this.loadBooking();

  }

  loadBooking(){
    this.passengers=[];

    try{
      this.booking.confirmDetail=JSON.parse( this.booking.confirmDetail);
    }catch{
      this.booking.confirmDetail={};
    }

    //verifica se i partecipanti sono stati già definiti
    if(this.booking.confirmDetail.passengers && this.booking.confirmDetail.passengers.length>0){
      this.passengers=this.booking.confirmDetail.passengers;




    }else{//crea l'elenco dei partecipanti


     
      for(let i=0;i<(this.booking.adults+this.booking.children);i++){
        let p:any={};
        p.label="partecipante "+(i+1).toString()+"...";
        p.value="";
        p.id=i;
        p.images=[];

        if(i==0){ //il primo passeggero sarà molto probabilmente chi ha fatto l'ordine
          p.value=this.booking.addressItem.name;
        }
        
        this.passengers.push(p);
      }
    }

    for(let d of this.booking.details){
      let p:Product=new Product();
      Object.assign(p,d.product);
      d.product=p;
    }
    
    this.requestparams=Globals.parameters.get("booking").getParam("requestparams");
    this.id_category_bike=Globals.parameters.get("booking").getParam("id_category_bike");
    this.id_category_extra=Globals.parameters.get("booking").getParam("id_category_extra");
    
    this.getProductInventories();
    
    if(this.booking.state==1 && this.booking.methodpayment!="omaggio"){ //se è in attesa del pagamento
      this.payModal=true;
    }
    this.calculateTotal();
  }


  getProductInventories(){
    
    
    this.bookinService.getProductInventory2(this.booking.shop_start.id_inventory).subscribe((items)=>{
    
   // this.bookinService.getProductInventory(Globals.user['shop']['id_inventory'],[this.id_category_bike,this.id_category_extra]).subscribe((items)=>{
      
      for(let i of items){
        i['selected']=false;
        i['quantity']=1;

         //parserizza il prodotto
         let p:Product=new Product();
         Object.assign(p,i['product']);
         i['product']=p;

        
        this.addProductToInventoryGroup(i);

       

      }
      
      
    });
  }

  addProductToInventoryGroup(installation){
    
    
    
    
    //verifica se il prodotto ha dei parametri da richiedere
    
    let requestParams=[];
    for(let r of this.requestparams){
      let req={};
      req['id_category']=r['id_category'];
      req['params']=[];
      for(let p of r['params']){
        let param={};
        param['label']=p['label'];
        param['name']=p['name'];
        req['params'].push(param);
      }
      requestParams.push(req);
    }



    for(let rp of requestParams){
      if(installation['product']['id_category']==rp.id_category){
        let params=[];
        Object.assign(params,rp.params);
        installation['request']=[];
        Object.assign(installation['request'],params);
        //installation['request']=params;
      }
    }
    
    
    
    
    //verifica che il gruppo (categoria) sia esistente
    for(let c of this.inventoryItemsGroup){
      if(c['id_category']==installation['product']['id_category']){
        c=this.addToInventoryGroup(c,installation);
        return;
      }
    }


    //se invece non c'è, bisogna creare il gruppo
    let c={};
    c['name']=installation['product']['category'];
    c['id_category']=installation['product']['id_category'];
    c['items']=[];
    c['items'].push(installation);
    this.inventoryItemsGroup.push(c);

  }

  addToInventoryGroup(inventoryGroup,product){
    if('items' in inventoryGroup){

    }else{
      inventoryGroup['items']=[];
    }
    inventoryGroup['items'].push(product);
    return inventoryGroup;
  }


  selectInventory(i){




    if(!i['selected']){
      //i['product']=[];
      //i['product']['id']=i.id_product;
      //this.list.push(i);

      if(this.isAddProduct){

        let b:BookingDetail=new BookingDetail();
        b.id_product=i.id_product;
        b.product=i.product;
        b.quantity=1;
        
        this.booking.details.push(b);
        b['isAdded']=true;
        this.bookingdetailSelected=b;

      }

      if(!this.bookingdetailSelected['selected']){
        this.bookingdetailSelected['selected']=[];
      }

      i['selected']=true;

      let p={};
      Object.assign(p,i);

      this.bookingdetailSelected['selected'].push(p);
     
      

    }else{
      /*for(let r=1;r<this.list.length;r++)
        if(this.list[r].id==i.id){
          this.list.splice(r,1);
          continue;
        }*/

      i['selected']=false;
    }
    
    //this.checkProducts();
    this.modalProductRef.close("success");

  }
  

  //verifica se sono state assegnati tutti i prodotti
  checkProducts(){
    for(let d of this.booking.details){
      //if((!d['selected'] || d['selected'].length==0) && d.product.stockable==true){
      if((!d['selected'] || d['selected'].length<d.quantity) && (d.product.rentable==true || d.product.stockable==true)){
        Globals.message.showToaster("Alcuni prodotti non sono stati assegnati correttamente. Ricontrollare",ToastMode.WARNING);

        return false;
      }
    }

    //verfica se sono stati impostati tutti i parametri
    for(let d of this.booking.details){
      if(d['selected'] && d['selected'].length>0){
        for(let s of d['selected']){
          if(s.request){
            for(let r of s.request){
              if(r.value==undefined || !r.value){
                Globals.message.showToaster("Per alcuni prodotti non è stato assegnato un parametro corretto. Ricontrollare",ToastMode.WARNING);

                return false;
              }
            }
          }
        }
      }
    }


    return true;


  }


  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"personalid",(result)=>{

      let i:Image=new Image();
      i.file=result;
      this.passengerSelected.images.push(i);
      Globals.setLoading(false);
     
    },true);
}


  

  confirm(){

    this.list=[];

    for(let bd of this.booking.details){
      if(bd['selected'] && bd['selected'].length>0){
        for(let s of bd['selected']){
          let i:InventoriesDetails=new InventoriesDetails();
          i.id=0;
          i.type=InventoryMode.unload;
          //i.id_inventory=Globals.user['shop']['id_inventory'];
          i.id_inventory=this.booking.shop_start.id_inventory;
          i.id_table=this.booking.id;
          i.quantity=1;
          i.table="booking";
          i.date=Helper.convertDateControl();
          i.id_product=s.id_product;
          i.sn=s.sn;
          if(s.request){
            let note=[];
            for(let r of s.request){
              note.push(r.label+":"+r.value)
            }
            i.note=note.join("\n");
          }
            
          this.list.push(i);
        }
      }
      
    }


    this.booking.state=4;
    this.booking.checkin=Helper.convertDateControl(null,true);
    this.booking.checkin_iduser=Globals.user.id;
    
    this.booking['confirmDetail']={};
    this.booking['confirmDetail']['passengers']=this.passengers;
    this.booking['confirmDetail']['signature']=this.signature;
    this.booking['confirmDetail']=JSON.stringify(this.booking['confirmDetail']);
    this.booking['listToInventory']=this.list;
    this.bookinService.save(this.booking,()=>{
      this.bookinService.emailConfirmCheckin(this.booking.id).subscribe(()=>{
        this.onSuccess.emit();
        this.closeWindow();
      })
      
    })


   /* this.inventoriesDetailsService.saveMultiple(this.list,()=>{
      this.booking.state=4;
      this.booking.checkin=Helper.convertDateControl(null,true);
      //this.booking.checkin_iduser=Globals.user.id;
      
      this.booking['confirmDetail']={};
      this.booking['confirmDetail']['passengers']=this.passengers;
      this.booking['confirmDetail']['signature']=this.signature;
      this.booking['confirmDetail']=JSON.stringify(this.booking['confirmDetail']);
      this.bookinService.save(this.booking,()=>{
        
        this.onSuccess.emit();
        this.closeWindow();
      })
    });*/

    
  }

  closeWindow(){
    if(this['modalWindow'])
      this['modalWindow'].close('success');
  }

  takephoto(passenger:any){
    Globals.modal.showModal(TakephotoComponent,[],(instance)=>{
      if(instance!=null){
        
        this.uploadService.uploadImage64(instance.imageCaptured,"personalid",(result)=>{
          let i:Image=new Image();
          i.file=result;
          
          passenger.images.push(i);
        },true);
      }
    }); 
  }

  
  

  removeImage(passenger:any,image:Image){
    for(let i of passenger.images){
      if(i.file==image.file){
        passenger.images.splice(i,1);
        return;
      }

    }
  }


 

  addProduct(){
    this.isAddProduct=true;
    this.modalProductTab=this.inventoryItemsGroup[0]['name'];
    this.modalProductRef=this.modalService.open(this.modalProduct,{size:"lg"});
  }

  
  linkProduct(bookingdetail){

    if(this.inventoryItemsGroup.length==0){
      Globals.modal.showConfirm("","ATTENZIONE: Il magazzino di "+this.booking.shop_start.name+" risulta vuoto da prodotti da noleggio.",()=>{
      },"","Ok");
      // alert("Il magazzino di "+this.booking.shop_start.name+" risulta vuoto da prodotti da noleggio");
      return;

    }


    this.isAddProduct=false;
    this.modalProductTab=this.inventoryItemsGroup[0]['name'];;
    this.inventoryItemsSuggest=[];
    if(bookingdetail.product.id_category==this.id_category_bike){
      for(let i of this.inventoryItemsBike){
        if(i.id_product==bookingdetail.product.id){
          this.inventoryItemsSuggest.push(i);
        }
      }
    }

    if(bookingdetail.product.id_category==this.id_category_extra){
      for(let i of this.inventoryItemsExtra){
        if(i.id_product==bookingdetail.product.id){
          this.inventoryItemsSuggest.push(i);
        }
      }
    }
    this.bookingdetailSelected=bookingdetail;
    this.modalProductRef=this.modalService.open(this.modalProduct,{size:"lg"});
  }

  setRequestParams(sn,request){
    this.requestSelected=[];
    Object.assign(this.requestSelected,request);
    this.modalRequestParamsRef=this.modalService.open(this.modalRequestParams);
  }

  confirmParams(){
    this.modalRequestParamsRef.close();
  }
  
  deleteProduct(bdetail,s){
    if(bdetail['isAdded']){
      for(let i=0;i< this.booking.details.length;i++){
        if(this.booking.details[i]==bdetail){
          this.booking.details.splice(i,1);
          return;
        }
      }
    }else{

      for(let i=0;i< bdetail.selected.length;i++){
        if(bdetail.selected[i]==s){
          bdetail.selected.splice(i,1);
          return;
        }
      }
    }
  }

  

  goTo(step){
    this.step=step;
    if(step==(this.steps.length)){
      this.confirm();
    }
  }

  openSignature(){
    this.modalSignatureRef=this.modalService.open(this.modalSignature,{size:"xl"});

  }

  confirmSignature(sigPad){
    let signature=sigPad.toDataURL().replace("data:image/png;base64,","");
    this.uploadService.uploadImage64(signature,"signatures",(result)=>{
      this.signature=result;
    },true);

    this.modalSignatureRef.close();
    this.step=3;

  }

  getSignatureImage(){
    return Globals.config.serverUrl+this.signature;
  }

  validateStep(step,isNext=true){
    switch(step){
      
      case 0:
       
        let count_images=0;
        
        for(let p of this.passengers){

           //verifica che sono stati segnati tutti i partecipanti
          if(p.value==""){
            Globals.message.showToaster("Inserire il nome di tutti partecipanti",ToastMode.WARNING);
            return false;
          }

           //verifica che sono stati caricati le foto di tutti i passeggeri
          if(p.images.length>0)
            count_images++;

        } 

        if(count_images<this.passengers.length){

          Globals.modal.showConfirm("","ATTENZIONE: Non sono state caricati i documenti di tutti i partecipanti.",()=>{
          },"","Sì, ho capito.");

          // return confirm("Non sono state caricati i documenti di tutti i partecipanti. Continuare lo stesso?");
        }

        return true;

      case 1:

        if(!isNext)
          return true;
       
        if(this.checkProducts()){
          if(!this.signature)
            this.openSignature();
          return true;
        }else{
          return  false;
        }
        
        
        break;
      
      case 2:

      if(!this.signature){
        Globals.message.showToaster("Inserire una firma per il contratto",ToastMode.WARNING);
        return false;
      }
        return true;
        break;
     case 3:
      

      break;
        
    }

    return true;
  }
  
  goNext(){
    if(this.validateStep(this.step))
      this.goTo(this.step+1);
  }

  goPrevious(){
    if(this.validateStep(this.step,false))
      this.goTo(this.step-1);
    
  }

  /**** STRIPE */

  pay(){

    


    //verifica il metodo di pagamento

      //this.payModal=false; //chiudi la modal del pagamento
      if(this.booking.methodpayment!=""){
        this.confirmPaymentModal=true; //apri la modal per la conferma della ricezione del pagamento (contanti, carta, etc)
      }else{
        this.beforeBookNow();
      }
  }


  beforeBookNow(){
    if(this.booking.methodpayment=="stripe"){
      Globals.setLoading(true);
      this.stripeComponent.pay();
    }else{
      this.confirmPaymentBooking();
    }
  }

  confirmPaymentBooking(){
    Globals.setLoading(true);

    this.bookinService.confirmPayment(this.booking,this.payment_method_id,(result)=>{
      if(result['error']){
          Globals.message.showToaster(result['message'],ToastMode.DANGER);
      }else{
        this.booking=result;
        this.loadBooking();
        this.payModal=false
        this.confirmPaymentModal=false;
      }
      
      Globals.setLoading(false);
    });
  }

  confirmPayment(transaction:any){
    console.log(transaction);
  }

  errorCard(error:any){
    Globals.modal.showConfirm("Errore",error,()=>{
    },"","Chiudi");
    // alert(error);
    Globals.setLoading(false);
  }

  invalidPayment(invalid:any){
    Globals.message.showToaster("Dati invalidi per il pagamento. Ricontrollare",ToastMode.WARNING);
  }


  createPaymentMethod(payment_method_id){
    this.payment_method_id=payment_method_id;
    this.confirmPaymentBooking();
  }


  calculateTotal(){

    

      this.total_gross=parseFloat((parseFloat(this.booking.amount.toString())+parseFloat(this.booking.tax.toString())).toFixed(2));

      
      
      
   
  }

  getImagePath(i){
    let image=new Image();
    Object.assign(image,i);
    return image.imagePath;

    
  }



  searchFromProducts(event,force=false){
    
    if(force || event.key=="Enter" ){
      this.productsService.getProduct(this.searchProduct).subscribe((item)=>{
        if(item){
          let i:Installation=new Installation();
          i.id_product=item.id;
          i.product=item;
          i.sn=item.sn;
          
          this.selectInventory(i);
          this.searchProduct="";
        }
      });
    }
  }

  openQrCodeReader(){
    Globals.modal.showModal(QrcodereaderComponent,[],(instance)=>{
      if(instance!=null){
        this.searchProduct=instance['code'];
        this.searchFromProducts(null,true);
      }
    })
  }

}
