import { Address } from "../db/address";
import {Product} from "../db/product";

import {Globals} from "projects/core/src/globals";
import { Helper } from "projects/core/src/helper";

export const InventoryMode={
    load:1,
    unload:2
}



export class InventoriesTotal{
    quantity:number;
    amount:number;
    
   
}

export interface SNAvaible{
    sn:string;
    avaible:number;
    selected:boolean;
    id_inventory:number;
    inventoryname:string;
  }


export interface Inventories{
    id:number;
    name:string;
    status:number;
    description:string;
    color:string;
    textcolor:string;
    code:string;
    location:string;
    id_parent:number;
}


export  class InventoriesDetails{

    


    id:number;
    date:Date;
    time:string;
    id_product:number;
    id_inventory:number;
    description:string;
    code:string;
    note:string;
    quantity:number;
    unit:string;
    netprice:number;
    gross:number;
    tax:number;
    discount1:number;
    discount2:number;
    discount3:number;
    id_address:number;
    addressItem:Address;
    sn:string;
    type:number;
    id_document:number;
    inventoryname:string;
    id_user:number;
    username:string;
    product:Product;
    created:Date;
    state:number;
    id_table:number;
    table:string;
    reference:any;
    id_inventory_detail_unload:number;
    id_inventory_detail_load:number;
   
    

    constructor(){
        this.id=0;
        this.date=Helper.convertDateControl();
        let d=new Date();
        this.time=d.toTimeString().split(" ")[0]; //d.getHours().toFixed(2).toString()+":"+d.getMinutes().toString()+":"+d.getSeconds().toFixed(2).toString();
        this.id_product=0;
        this.id_inventory=Globals.parameters.get("inventories").getParam("default_inventory");
        this.description="";
        this.note="";
        this.quantity=1;
        this.unit="pezzi";
        this.netprice=0;
        this.gross=0;
        this.tax=Globals.parameters.get("general").getParam("default_tax");
        this.discount1=0;
        this.discount2=0;
        this.discount3=0;
        this.id_address=0;
        this.addressItem={} as Address;
        this.sn="";
        this.type=2;
        this.id_document=0;
        this.inventoryname="";
        this.id_user=Globals.user.id;
        this.username=Globals.user.username;
        this.product=new Product();
        this.id_table=0;
        this.table="";
    }

   

    

}