import { Component,ViewChild, OnInit ,Input,AfterViewInit, TemplateRef} from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { Address } from '../../../db/address';
import { Type } from '../../../db/type';
import { AddressesService } from '../../../services/addresses.service';
import { TypesService } from '../../../services/types.service';
import { Globals } from 'projects/core/src/globals';
import { SearchgpsComponent } from '../../../modules/searchgps/searchgps.component';
import { CustomfieldsComponent } from 'projects/core/src/common/customfields/customfields.component';
import { UsersComponent } from 'projects/core/src/settings/users/users.component';
import { ToastMode, User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from '../../../services/registration.service';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { MethodpaymentsService } from '../../../services/methodpayments.service';


@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.sass']
})

export class AddressDetailsComponent extends Model<Address> implements OnInit,AfterViewInit {

  @ViewChild("modalVies")
  modalVies:TemplateRef<any>;
  modalViesRef;
  viesResult=null;

  @ViewChild("modalIntroNewRecord")
  modalIntroNewRecord:TemplateRef<any>;
  modalIntroNewRecordRef;
  

  modulename="address";
  fileToUpload: File = null;
  type_address:Type[];
  detailForm;
  newuser:User;
  field_required=['name'];

  @Input() db_cities: Array<any>

  backLink="/address";
  //tax_code_error:string="";
  
  first_id_shop:number=0;

  ids_documents=[];
  listDescription=[];
  methodpayments=[];
  sources=[];

  @ViewChild(CustomfieldsComponent) customfieldsView:CustomfieldsComponent;
  @ViewChild("modalAddUser")
  modalAddUser:TemplateRef<any>;
  modalAddUserRef;
  
  get currentUser(){
    return Globals.user;
  }

  tab=1;

  constructor(
    private route1: ActivatedRoute,
    private addressesService: AddressesService,
    private typesService: TypesService=null,
    private userService:UserService,
    private uploadService:UploadService,
    private modalService:NgbModal,
    private registrationService:RegistrationService,
    private methodpaymentsService:MethodpaymentsService
    ) {
      super(route1,addressesService); 
      
    
  }

  foreign=false;
  isNew=false;
  ngOnInit (): void {

    this.title = 'Anagrafica Dettaglio';
    this.backLink="/address";
    this.record=new Address();
    this.record.status=1;

    super.ngOnInit.apply(this, arguments); 
    
    if(this.mode!="modal")
      this.route1.params.subscribe((params) => this.id = params.id);

    
  }

  ngAfterViewInit(): void {
    this.afterGetItem=(()=>{
      if(this.record.id==0 || this.record.id==undefined){
        this.record.id_type=this.type_address[0].id;
        this.record.role="";
        this.record.customfields=[];
        this.record.contacts=[];
        this.isNew=true;
        this.openModalIntro();
      }else{
        this.title=this.record.name;
      }

      try{
        this.record.params=JSON.parse(this.record.params);
      }catch{
        
      }finally{
        if(!this.record.params){
          this.record.params={
            "params_document_causal":""
          };
        }
      }

    })

  this.typesService.getTypes("addresses").subscribe((items)=>{
    this.type_address=items;
    this.getItem();
 });

 this.sources=Globals.parameters.get("cash-flow").getParam("sources",[]);



  

  

  this.record.sdi = "0000000";
  this.afterSave=()=>{
    
    if(this.isNew){
      if(this.module.getParam("auto_add_user",false)){
        this.newuser=new User();
        this.newuser.id_address=this.record.id;
        this.openModalAddUser();
        return false;
      }

    }
    return true;
  }

  this.listDescription=Globals.parameters.get("documents").getParam('causals',[]);
  this.methodpaymentsService.getMethods().subscribe((result)=>{
    this.methodpayments=result;
  })

  }
  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"addresses",(result)=>{
      
      this.record.logo=Globals.config.serverUrl+"/"+result;
      Globals.setLoading(false);
     
    },true);
}
 
  /*
  verifyTaxCode():boolean{
    let v:boolean=false;
    this.tax_code_error="";
    if(this.record.tax_code!=undefined){
      v=this.verifyFiscalCode.validate(this.record.tax_code);
      if(!v)
        this.tax_code_error=this.verifyFiscalCode.error_message;
    }
    return v;
  }*/

  

  /*
  onKeyTaxCode(){
    for(let c of this.record.customfields){
      if(c.option!=""){
        try{
          let params=JSON.parse(c.option);
          if(params){
            c.value=this.record[params['from']];
          }
        }catch{
            continue;
        }
      }
    }
  }*/

  onChangeNation(nation){
    this.record.nation=nation;
    if(nation!='IT'){
      this.foreign=true;
      this.record.country="EE";
      this.record.zip="00000";
      this.record.sdi="XXXXXXX";
      this.record.vat_number="XXXXXXXXXXX";
      this.record.tax_code="XXXXXXXXXXX";
    }else{
      this.foreign=false;
      this.record.country="";
      this.record.zip="";
      this.record.sdi="0000000";
      this.record.vat_number="";
      this.record.tax_code="";
    }
  }

  onChangeTypeVatNumber(type){
    switch (type){
      case '1': // italiana
        this.record.vat_number="";
        this.record.tax_code="";
        break;
      case '2':  // UE
        this.record.vat_number=this.record.nation+this.record.vat_number;
        this.record.tax_code="";
        this.record.sdi="XXXXXXX";
        break;
      case '3':  // Extra UE
        this.record.vat_number="OO99999999999";
        this.record.tax_code="";
        this.record.sdi="XXXXXXX";
        break;
      case '4':  // Privato estero
        this.record.vat_number="XXXXXXXXXXX";
        this.record.tax_code="XXXXXXXXXXX";
        this.record.sdi="XXXXXXX";
        break;
    }
  }


  save(reloadItem:boolean=true,oncomplete:Function=null):void{
    if(this.record.vat_number!=undefined && this.record.tax_code!="" && this.record.tax_code!="XXXXXXXXXXX" && this.record.vat_number!="XXXXXXXXXXX"){
      this.addressesService.verifyTax(this.record.vat_number.toString(),this.record.tax_code,this.id).subscribe((items)=>{
        if(items.length>0){
          if(confirm("Un altro record presenta la stessa P.IVA e/o Codice Fiscale. Procedere comunque al salvataggio?"))
            super.save(reloadItem,oncomplete);
        }else{
          super.save(reloadItem,oncomplete);
        }
        
      });
    }else{
      super.save(reloadItem,oncomplete);
    }
  }

  
  openSearchGPS(address=""){

    if(address==""){
      address=this.record.address+","+this.record.city;
    }

    Globals.modal.showModal(SearchgpsComponent,[{"name":"search","value":address}],(instance)=>{
      if(instance!=null){
        this.record.address=instance['address'];
        this.record.city=instance['city'];
        this.record.zip=instance['zip'];
        this.getCountryFromZip();
      }
    });
  }
  
  searchUser(){
    Globals.modal.showModal(UsersComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance){
        
        let user:User=instance['recordSelected'];
        
        //verifica che l'utente è già stato assegnato
        if(user.id_address>0 && user.id_address!=this.record.id){
          if(confirm("L'utente scelto è già assegnato ad un altra anagrafica. Riassegnarla a questa corrente?")){
            this.linkUser(user);
          }
        }else{
          this.linkUser(user);
        }
        
        

      }
    });
  }

  linkUser(user:User){
    

    user.id_address=this.record.id;
    this.userService.save(user,()=>{
      this.record.id_user=user.id;
      this.record.username=user.name;
    },false,false);


  } 

  unlinkUser(){
    Globals.modal.showConfirm("","Confermi di voler scollegare l'utente dall'anagrafica?",()=>{  
      let user:any={};
      user['id']=this.record.id_user;
      user['id_address']=0;
      this.userService.save(user,()=>{
        this.record.id_user=0;
        this.record.username="";
      },false,false);
    },"Sì. Scollega","No. Annulla","danger");
  }


  openModalAddUser(){    
    //suggerisci la username
    let id_type_contact=Globals.parameters.get("registration").getParam("id_type_email",0);
    for(let c of this.record.contacts){
      if(c.id_type==id_type_contact){
        this.newuser.username=c.value;
      }
    }
    this.newuser.name=this.record.name;
    this.modalAddUserRef=this.modalService.open(this.modalAddUser);
  }
  closeModalAddUser(){
    this.modalAddUserRef.close("success");
    this['modalWindow'].close("success");
  }


  createUser(){

    //verifica che i parametri siano corretti
    if(this.newuser.username=="" || this.newuser.username==undefined || this.newuser.username==null){
      alert("Specificare la username");
      return;
    }

    //verifica che i parametri siano corretti
    if(this.newuser.password=="" || this.newuser.password==undefined || this.newuser.password==null){
      alert("Specificare la password");
      return;
    }

    //verifica che i parametri siano corretti
    if(this.newuser.name=="" || this.newuser.name==undefined || this.newuser.name==null){
      alert("Specificare il nome");
      return;
    }

    this.registrationService.register(this.newuser,(result)=>{
      
      if(result && result['registered']){
        this.record.id_user=result['id_user'];
        this.record.username=this.newuser.username;
        this.closeModalAddUser();
      }else{
        Globals.message.showToaster(result['errormessage'],ToastMode.DANGER);
      }
    });
  }


 

  searchByVies(){
    Globals.loading=true;
    this.addressesService.searchByVies(this.record.vat_number).subscribe((result)=>{
      if(result!=null){
        this.viesResult=result;
        this.openModalViews();
      }else{
        Globals.message.showToaster("Nessun valore restituito dal VIES",ToastMode.DANGER);
      }
      Globals.loading=false;
      this.closeModalIntro();
    });
  }


  openModalViews(){
    this.modalViesRef=this.modalService.open(this.modalVies);
  }
  closeModalVies(){
    this.modalViesRef.close("success");
  }


  useVies(gps=false){
    this.record.name=this.viesResult.name;    
    if(gps) {
      this.openSearchGPS(this.viesResult.address);
    } else {
      var addressSplit = this.viesResult.address.split(' ');
      this.record.country = addressSplit[addressSplit.length-1].slice(0,-1);
      console.log("|"+this.record.country+"|");
      addressSplit.pop();
      this.record.city = addressSplit[addressSplit.length-1];
      addressSplit.pop();
      this.record.zip = addressSplit[addressSplit.length-1];
      addressSplit.pop();
      this.record.address = addressSplit.join(' ');
    }

    this.closeModalVies();
  }


  openModalIntro(){
    this.modalIntroNewRecordRef=this.modalService.open(this.modalIntroNewRecord);
  }
  closeModalIntro(){
    this.modalIntroNewRecordRef.close("success");
  }


  getCountryFromZip(){
    this.addressesService.getCountryFromZip(this.record.zip).subscribe((item)=>{
      if(item){
        this.record.country=item;
      }
    })
  }


}
